import React, {useEffect} from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { toUpper  } from 'lodash';
import { URLS } from '../_config';
import Select from 'react-select';
import { defaultSelectStyles  } from 'src/_components/Forms/common';
import { CaretUpIcon } from 'src/_images/icons/CaretUpIcon';
import { CaretDownIcon } from 'src/_images/icons/CaretDownIcon';

// eslint-disable-next-line no-unused-vars
import moment from 'moment';

import { Flex, Box, VStack, Text, HStack, Button, Spinner, IconButton, Select as ChakraSelect,
      TableContainer, Table, Thead, Tbody, Tr, Th, Td, Center, Heading,
      Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure  } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { MdOutlineExpandMore } from 'react-icons/md';


import { localeFormatDate } from '../_helpers';

// redux state
import { actions } from './_redux/actions';
// selectors
import {
  getFilterBy,
  getLoading,
  getisLoadMore,
  getIsLoadingMore,
  getQueryParamsOffset
} from './_redux/selectors';
import { getIntlLocale, getIntlMessages, getProfileIsStaff} from '../App/_redux/selectors';

// styles
import {
  StyledPage,
} from './styles';
import { useSearchParamsObject } from 'src/_hooks/useSearchParams';
import { useRequestList } from './api/getRequests';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelectOptions } from 'src/App/api/getSettings';
import { useHistory } from 'react-router';
import { useAppConfig } from 'src/App/state/appConfig';
 import styled from 'styled-components';
import PaginationNext from 'src/_images/icons/PaginationNext';
import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import { RequestForm } from './RequestForm';
import { useAuth } from 'src/_libs/auth';

import { AdminSelectClinicModal } from '../AdminPage/components/AdminClinicRequiredModal';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';
import { PlusIcon } from 'src/_images/icons/PlusIcon';
import { NoReportsFoundIcon } from 'src/_images/icons/NoReportsFound';

const filterSelectStyles = {
  ...defaultSelectStyles,
  control: (provided, state) => ({
    ...defaultSelectStyles.control(provided, state),
    height: 20,
    minHeight: 'unset',
    borderRadius: 5,
    padding: '0 3px ',
    paddingRight: 0,
    fontSize: 8
  }),
  container: (provided) => ({
    ...provided,
    height: 20,
    width: 80,
    padding: '0 0',
    paddingRight: 0,
    fontSize: 8 
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0',
    height: 20,
    fontSize: 8 
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 10
  }),
  indicatorsContainer: (provided, state) => {
    return { 
      ...defaultSelectStyles.indicatorsContainer(provided, state),
      padding: '0 0' }
  },
  option: (provided) => {
    return { 
      ...provided,
      fontSize: 9,
    }
  },
  dropdownIndicator: (provided) => {
    return { 
      ...provided,
      padding: '0 2px',
      "svg": {
        height: 12,
        width: 15
      }
    }
  }
}

class SelectInput extends React.Component{
	render () {
		return (
				<Select
					  {...this.props}
            ref={this.props.inputRef }
            autoload={true}
					  backspaceRemovesValue={true}
				/>
		);
	}
};

const StatusFilterComponent = ({searchParams, pagination, setPagination}) => {
  // eslint-disable-next-line no-unused-vars
  const { statusParam } = useSearchParamsObject();
  const history = useHistory();
  const { data, isLoading, isFetching } = useSelectOptions({option: 'request_statuses'});
  const { formatMessage } = useIntl();

  const handleChange = (v) => {
    let newParams = searchParams;
    newParams.status = v.value;
    //if (v.value){
    //  newParams.status = v.value;
    //} else {
    //  delete newParams.status;
    //}
    const params = new URLSearchParams(newParams);
    setPagination({...pagination, pageIndex: 0})
    history.push({search: params.toString()})
  }
  return (
      <Flex ml={'8px'}>
        <SelectInput
          onChange={(v) => handleChange(v)}
          value={data?.options?.find( opt => opt.value == searchParams.status)}
          cache={false}
          isLoading={isLoading || isFetching}
          options={data?.options}
          placeholder={formatMessage({id: 'requestListPage.requestList.header.status', defaultMessage: 'Status'})}
          isDisabled={false}
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          menuPortalTarget={document.body}
          styles={ filterSelectStyles }
          onBlurResetsInput={false}
          onSelectResetsInput={false}
        />
      </Flex>
  )
}

const TypeFilterComponent = ({searchParams, pagination, setPagination}) => {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const { data, isLoading, isFetching } = useSelectOptions({option: 'request_types'});
  const { formatMessage } = useIntl();

  const handleChange = (v) => {
    let newParams = searchParams;
    if (v.value){
      newParams.type = v.value;
    } else {
      delete newParams.type;
    }
    const params = new URLSearchParams(newParams);
    setPagination({...pagination, pageIndex: 0})
    history.push({search: params.toString()})
  }

  return (
      <Flex ml={'8px'}>
        <SelectInput
          onChange={(v) => handleChange(v)}
          value={data?.options?.find( opt => opt.value == searchParams.type)}
          cache={false}
          isLoading={isLoading || isFetching}
          options={data?.options ? [ {key: 'ALL', label: formatMessage({id: 'filter.label.all', defaultMessage: 'ALL'})}, ...data.options] : []}
          placeholder={formatMessage({id: 'requestListPage.requestList.header.type', defaultMessage: 'Type'})}
          isDisabled={false}
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          menuPortalTarget={document.body}
          styles={ filterSelectStyles }
          onBlurResetsInput={false}
          onSelectResetsInput={false}
        />
      </Flex>
  )
}

export const LoadMore = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const isLoading = useSelector(getLoading);
  const isLoadingMore = useSelector(getIsLoadingMore);
  const queryParamsOffset = useSelector(getQueryParamsOffset);
  const filterBy = useSelector(getFilterBy);
  const isLoadMore = useSelector(getisLoadMore);

  useEffect(()=>{
    return () => {};
  }, [])

  const fetchMore = () => {
    dispatch(actions.getRequestsMore(filterBy, queryParamsOffset+1))
  }

  if (isLoadingMore){
    return (
      <Flex
        justify={'center'} 
        align={'center'} 
        width={'100%'}
      >
        <Spinner 
          size='xl'
          thickness='4px'
          speed='0.65s' 
          color='#44C5A6' 
        />
      </Flex>
    )
  } else if (isLoadMore && !isLoading){
    return (
        <Flex 
          justify={'center'} 
          align={'center'} 
          width={'100%'}
        >
          <Button 
            leftIcon={<MdOutlineExpandMore size={24} />} 
            mt={4} 
            fontSize={14} 
            variant="solid" 
            colorScheme={'bdBlue'}
            onClick={() => fetchMore()}
          >
            {toUpper(intlMessages['requestListPage.requestList.loadmore'])}
          </Button>
        </Flex>
    )
  } else {
    return (
      <></>
    )  
  }

}

/************
 *
 * Page
 *
 /************/


 const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, onScrollToTop}) => {
  const handlePreviousPageClick = () => {
      previousPage();
      onScrollToTop();
  }

  const handleNextPageClick = () => {
      nextPage();
      onScrollToTop();
  }

  const handlePageChange = (e) => {
      const page = e.target.value ? Number(e.target.value) : 0
      setPageIndex(page)
      onScrollToTop();
  }
  
  if (total == 0){
    return (
      <></>
    )
  }

  return (
      <HStack spacing={3} >
          <IconButton 
              icon={<PaginationPrevious  />} 
              isDisabled={!canPreviousPage}
              onClick={handlePreviousPageClick}
              borderRadius={'5px'}
          />
          <ChakraSelect 
              onChange={handlePageChange}
              isDisabled={total < 2}
          >
              {Array.from({length: total}, (_, i) => (
                  <option key={i} value={i} selected={pageIndex == i}>
                      {i + 1}
                  </option>
              ))}
          </ChakraSelect>
          <IconButton 
              icon={<PaginationNext />} 
              isDisabled={!canNextPage}
              onClick={handleNextPageClick}
              borderRadius={'5px'}
          />
      </HStack>
  )
}


const StyledStatus = styled.div`
  .status{    
      &:after {content:'';display:inline-block;width:8px;height:8px;border-radius:10px;vertical-align:middle;margin-left:7px;margin-right:12px;}
      &.PENDING::after{background-color:#FFA05C}
      &.COMPLETE::after{background-color:#35BE9D}
      &.COMPLETED::after{background-color:#35BE9D}
  }
`;

const statusAccessorFn = ({row}) => {
  const request = row.original;
  const { formatMessage } = useIntl();
  const isStaff = useSelector(getProfileIsStaff);  
  let requestStatus = request?.report?.status;
  if (request?.report?.sent_at != null || request?.status == "COMPLETE"){
    requestStatus = 3;
  } else if (request?.report === null){
    requestStatus = 0;
  }
  return (
    <StyledStatus>
      <div className={`${toUpper(request?.status)} status`}>{formatMessage({id: `requestListPage.requestList.status.${
          isStaff ? 'admin' : 'dent'
        }.${
          requestStatus
        }`})}
      </div>
    </StyledStatus>
  )
}


const PageTable = ({data, error, isError, searchParams, isLoading, pagination, setPagination, onScrollToTop}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const intlLocale = useSelector(getIntlLocale);

  // sortDirection
  // sortBy

  const StatusHeader = () => {
    return (
      <Flex align={'center'}>
        <FormattedMessage 
            id={'requestListPage.requestList.header.status'}
            defaultMessage='Status'
          />
        <StatusFilterComponent 
          searchParams={searchParams}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Flex>
    )
  }

  const TypeHeader = () => {
    return (
      <Flex align={'center'}>
        <FormattedMessage 
            id={'requestListPage.requestList.header.type'}
            defaultMessage='Type'
          />
        <TypeFilterComponent 
          searchParams={searchParams}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Flex>
    )
  }


  const handleSort = (key) => {
    let params = searchParams;
    if (params.sort_by === key && params.sort_direction === 'desc') {
      // update API query parameters here for descending sort
      //setSortConfig({ key, direction: 'descending' });
      if (params.sort_direction === 'asc'){
        params.sort_direction = 'desc'; 
      } else {
        params.sort_direction = 'asc'; 
      }
      const newParams = new URLSearchParams(params)
      history.replace({search: newParams.toString()})
    } else {
      //setSortConfig({ key, direction: 'ascending' });
      params.sort_by = key;
      params.sort_direction = 'desc';
      const newParams = new URLSearchParams(params)
      history.replace({search: newParams.toString()})
    }
  };

  const getSortIcon = (key) => {
    let isAsc = false;
    let isDesc = false;
    if (searchParams.sort_by == key){
      isAsc = searchParams.sort_direction === 'asc';
      isDesc = searchParams.sort_direction === 'desc';
    };
    // #A0B1BA
    // #EFEFEF
    return (
      <VStack spacing={'3px'} ml={'8px'}>
        <CaretUpIcon color={isAsc ? '#405159' : '#C7D3D9' } width={14} />
        <CaretDownIcon color={isDesc ? '#405159' : '#C7D3D9' }  width={14}/>
      </VStack>
    )
  };

  const columns = React.useMemo(
      () => [
          {
              //header: formatMessage({id: 'requestListPage.requestList.header.type', defaultMessage: 'Type'}),
              header: <TypeHeader />,
              id: 'type',
              accessorKey: 'type',
              sortable: true,
          },
          {
              header: formatMessage({id: 'requestListPage.requestList.header.patient', defaultMessage: 'Patient Name'}),
              id: 'name',
              accessorFn: row => formatMessage(
                {id: "format.fullName", defaultMessage: "{givenName} {familyName}" },
                {givenName: row.patient?.first_name, familyName: row.patient?.last_name}
              ),
              sortable: true,
          },
          {
            header: formatMessage({id: 'requestListPage.requestList.header.sent_at', defaultMessage: 'Received Date'}),
              id: 'created_at',
              accessorFn: row => localeFormatDate(row.created_at, intlLocale),
              sortable: true,
          },
          {
            header: formatMessage({id: 'requestListPage.requestList.header.remark', defaultMessage: 'Comment'}),
              id: 'comment',
              accessorKey: 'remark',
              sortable: false,
          },
          {
              header: <StatusHeader />,
              //header: formatMessage({id: 'requestListPage.requestList.header.status', defaultMessage: 'Status'}),
              id: 'status',
              cell: statusAccessorFn,
              sortable: true,
          }
      ],
      []
  )

  const defaultData = React.useMemo(() => [], [])

  const table = useReactTable({
      data: data?.request_list || defaultData,
      columns,
      state: {
          pagination
      },
      onPaginationChange: setPagination,
      rowCount: data?.request_count,
      getCoreRowModel: getCoreRowModel(),
      manualPagination: true,
      debugTable: true
  })

  const handleRowClick = (row) => {
      if (row?.status == "COMPLETE" || row?.status == "COMPLETED"){
        history.push(URLS.patientPage.url.replace('{patientId}',row?.patient?.unique_id), {'previousLocation': {search: searchParams}})
      } else {
        history.push(URLS.reportRequestPage.url.replace('{requestId}',row?.unique_id), {'previousLocation': {search: searchParams}})
      }
  }

  if (isError){
    return (
      <Box ml={['90px']} pt={['54px']} height={'70%'}>
          <ErrorNotFound error={error} />
      </Box>
    )
  }

  return (
      <>
        <TableContainer>
          {isLoading && (
              <Flex pos={'absolute'} w={'full'} h={'full'} py={'50px'} align={'flex-start'} justify={'center'} bg={'rgba(255, 255, 255, 0.6)'}>
                <Spinner 
                  size='xl'
                  thickness='4px'
                  speed='0.65s' 
                  color='#44C5A6' 
                />
              </Flex>
            )} 
              <Table>
                  <Thead>
                      {(table.getHeaderGroups() || []).map(headerGroup => (
                          <Tr key={headerGroup.id}>
                              {headerGroup.headers.map(header => (
                                  <Th 
                                    key={header.id} 
                                    colSpan={header.colSpan} 
                                    fontSize={'14px'}
                                  >
                                      <Flex display={'flex'} align={'center'}>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        <Box
                                          _hover={{cursor: header.column.columnDef.sortable ? 'pointer' : 'default'}}
                                          onClick={() => header.column.columnDef.sortable && handleSort(header.id)}
                                        >
                                          {header.column.columnDef.sortable && getSortIcon(header.id)}
                                        </Box>
                                      </Flex>
                                  </Th>
                              ))}
                          </Tr>
                      ))}
                  </Thead>
                    {
                      table.getRowModel().rows.length == 0 ? (
                          <Box pr={'20px'} pos={'absolute'} w={'full'}>
                            <Flex w={'full'} pt={'150px'} flexGrow={1} direction={'column'} align={'center'} justify={'center'}>
                              <NoReportsFoundIcon />
                              <Text mt={'10px'} fontsize={'18px'} color={'none.500'}>
                                <FormattedMessage 
                                  id={'patientDetailsPage.details.completedrequests.noTasks.description'}
                                  defaultMessage={'No Reports Found'}
                                />
                              </Text>
                            </Flex>
                          </Box>
                      ) : (
                        <Tbody>
                          {table.getRowModel().rows.map(row => {
                            return (
                              <Tr 
                                key={row.id} 
                                onClick={() => handleRowClick(row.original)} 
                                _hover={{cursor: 'pointer', bg: '#F7F7F7'}}
                              >
                                  {row.getVisibleCells().map(cell => {
                                  return (
                                      <Td 
                                        key={cell.id}
                                        py={'30px'}
                                      >
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext()
                                          )}
                                      </Td>
                                  )
                                  })}
                              </Tr>
                              )
                          })}
                        </Tbody>
                      )
                    }
              </Table>
            

        </TableContainer>
        <Flex w="full" mt={'20px'} justify={'flex-end'}>
            <Pagination 
                onScrollToTop={onScrollToTop}
                isLoading={isLoading}
                canPreviousPage={table.getCanPreviousPage()}
                canNextPage={table.getCanNextPage()}
                pageIndex={table.getState().pagination.pageIndex}
                total={table.getPageCount()}
                nextPage={table.nextPage}
                previousPage={table.previousPage}
                setPageIndex={table.setPageIndex}
            />
        </Flex>
      </>
  )
}

const NewRequestModal = ({
  isOpen,
  onClose,
  onSuccess
}) => {

  const handleOnSuccess = () => {
      onSuccess();
      onClose();
  }
  return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
        <ModalOverlay bg="blackAlpha.300" />
        <ModalContent minW="700px" bg="#f7f9fa">
          <ModalHeader>
            <Center>
                  <FormattedMessage 
                      id={'adminPage.createPatientRequestFormModal.title'}
                      defaultMessage={'Add New Patient Request'}
                  />
            </Center>
          </ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
              <Heading as={'h2'}>
                  <FormattedMessage 
                      id={'adminPage.createPatientRequestForm.title'}
                      defaultMessage={'Patient Request'}
                  />
              </Heading>
            <Box>
              <RequestForm
                onSuccess={handleOnSuccess}
                onClose={onClose}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
  )
}

 export const RequestListPage = () => {
  const { user } = useAuth();
  const searchParams = useSearchParamsObject();
  const { isOpen: isNewRequestOpen, onOpen: onNewRequestOpen, onClose: onNewRequestClose } = useDisclosure();
  const { isOpen: isNeedClinicOpen, onOpen: onNeedClinicOpen, onClose: onNeedClinicClose } = useDisclosure();
  const clinic = useAppConfig(state => state.clinic?.id); // current selected clinic from app config
  const PAGE_SIZE = 20;
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  })
  const scrollRef = React.useRef(null);
  const { data, isLoading, isFetching, isError, error, refetch } = useRequestList({
    ...searchParams, clinic, offset: pagination.pageIndex, pg_sz: pagination.pageSize 
  });

  useEffect(()=>{
    return () => {};
  }, [])

  // TODO add a OpenRequests Page for Admins. so that they can assign
  // eslint-disable-next-line no-unused-vars
  const handleUpdateChildSize = () => {
    if (scrollRef.current){
      scrollRef.current.updateScroll();
    }
  }

  const handleScrollToTop = () => {
    if (scrollRef.current){
      scrollRef.current.scrollTop = 0;
    }
  }

  const handlePageChange = (val) => {
    setPagination(val)
    handleScrollToTop();
  }

  const handleNewRequestClick = () => {
    if (user?.is_staff){
      if (clinic){
        onNewRequestOpen();
      } else {
        onNeedClinicOpen()
      }
    } else {
      onNewRequestOpen();
    }
  }

  return (
    <StyledPage ref={scrollRef}>
      {/*<StyledPageTitle>{startCase(intlMessages['requestListPage.upcoming.title'])}</StyledPageTitle>
      <UpcomingSection />*/}
      <Flex pb={['20px']} justify={'space-between'}>
        <Text 
          fontSize={['30px']}
          color={'#405159'}
        >
          <FormattedMessage 
            id={'requestListPage.header.title.request'}
            defaultMessage='Requests'
          />
        </Text>
        <Flex align={'center'}>
          <NewRequestModal 
            isOpen={isNewRequestOpen}
            onClose={onNewRequestClose}
            onSuccess={refetch}
          />
          <AdminSelectClinicModal
            isOpen={isNeedClinicOpen}
            onClose={onNeedClinicClose}
          />
          <Button 
            variant="outline" 
            onClick={handleNewRequestClick}
            leftIcon={<PlusIcon color={'#405159'} />}
          >
            <FormattedMessage 
              id={'requestListPage.header.button.newRequest'}
              defaultMessage='New Request'
            />
          </Button>
        </Flex>
      </Flex>
      {/*renderList()*/}
      <PageTable data={data} error={error} isError={isError} searchParams={searchParams} isLoading={isLoading || isFetching} pagination={pagination} setPagination={handlePageChange} onScrollToTop={handleScrollToTop} />
    </StyledPage>
  )
}
