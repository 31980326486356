/* eslint-disable no-unused-vars */
import React from 'react';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { FormControl } from '@chakra-ui/react';
import { background, border } from 'styled-system';
import SearchIcon from 'src/_images/icons/SearchIcon';
import { FormattedMessage } from 'react-intl';



const DropdownIndicator = ({ children, ...props}) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  )
}

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <FormattedMessage id="format.search.noOptions" defaultMessage="No results found" />
    </components.NoOptionsMessage>
  );
};


const FormSearchInput = ({...props}) => {
  if (props.canCreateNewValue){
    return (
        <AsyncCreatableSelect
          {...props}
          isMulti={false}
          backspaceRemovesValue={true}
          debounceInterval={500}
          isOptionDisabled={(option) => option?.data?.is_active === false}
          components={{DropdownIndicator, NoOptionsMessage}}
        />
    );
  } else {
    return (
        <AsyncSelect
          {...props}
          isMulti={false}
          backspaceRemovesValue={true}
          debounceInterval={500}
          isOptionDisabled={(option) => option?.data?.is_active === false}
          components={{DropdownIndicator, NoOptionsMessage}}
        />
    );
  }
}

/*
class FormSearchInput extends React.Component{
	render () {
    if (this.props.isCreatable){
      return (
          <CreatableAsyncSelect
            {...this.props}
            isMulti={false}
            backspaceRemovesValue={true}
            debounceInterval={500}
            isOptionDisabled={(option) => option?.data?.is_active === false}
            components={{DropdownIndicator}}
          />
      );
    } else {
      return (
          <AsyncSelect
            {...this.props}
            isMulti={false}
            backspaceRemovesValue={true}
            debounceInterval={500}
            isOptionDisabled={(option) => option?.data?.is_active === false}
            components={{DropdownIndicator}}
          />
      );
    }
	}
};*/

const defaultSelectStyles = {
  dropdownIndicator: (provided, state) => {
    const display = state.hasValue ? 'none': 'block';
    return { ...provided, display };
  },
  indicatorsContainer: (provided, state) => {
    return { ...provided, padding: '0 20px' }
  },
  indicatorSeparator:  (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  },
  placeholder: (provided, state) => {
    return { ...provided, color: '#7A8F99' }
  },
  valueContainer: (provided, state) => {
    return { ...provided, padding: '0 20px' }
  },
  control: (provided, state) => {
    return { ...provided, 
      boxShadow: state.isFocused ? '0 0 0 1px #405159' : 'none' , 
      borderColor: state.isFocused? '#405159' :'#C7D3D9', 
      height: 45,
      color: '#405159',
      backgroundColor: 'transparent',
      borderRadius: 30,
      "&:hover": {
        borderColor: '#405159'
      },
      "&:focus": {
        borderColor: '#405159'
      },
    }
  },
  menu: (provided, state) => {
    return { ...provided,
      zIndex: 9999
    }
  },
  menuPortal: (provided, state) => {
    return { ...provided,
      zIndex: 9999
    }
  },
  

}

const errorStyles = {
  control: (provided, state) => {
    return { ...provided, 
      height: 45,
      color: '#405159',
      backgroundColor: 'transparent',
      borderRadius: 30,
      boxShadow: state.isFocused ? '0 0 0 1px #E95252' : 'none' , 
      borderColor: '#E95252', 
      "&:hover": {
        borderColor: '#E95252'
      },
    }
  },
}
export const SearchFieldComponent = ({
  initValue, 
  name,
  meta, 
  values, 
  label, 
  intlLocale, 
  intlMessages, 
  className, 
  disabled, 
  createNewValue, 
  menuPortalTarget,
  defaultSearch,
  loadOptions, onInputChange, onChange, onValueClick, 
  defaultOptions=true,
  canCreateNewValue=false,
  selectStyles={},
  isError=false,
  ...rest }) => (
  <FormControl id={name}>
    <FormSearchInput
      {...rest}
      loadOptions={loadOptions}
      defaultOptions={createNewValue ? [createNewValue] : defaultSearch ? true : []}
      onChange={inputValue => onChange(inputValue)}
      onValueClick={onValueClick}
      isDisabled={disabled}
      onInputChange={onInputChange}
      isClearable={true}
      styles={ isError 
        ? {...defaultSelectStyles, ...selectStyles, ...errorStyles} 
        : {...defaultSelectStyles, ...selectStyles}
      }
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      menuPortalTarget={menuPortalTarget}
      canCreateNewValue={canCreateNewValue}
    />
  </FormControl>
)