import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchRecord } from './services';
import { useMediaStore } from '../state/media';

export const QUERY_KEY_NAME = 'RecordDetails';

import { toString } from 'lodash';

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRecord = async ({ recordId, clinic }) => {
    return fetchRecord(recordId, null, clinic);
}

export const useRecordDetails = ({ recordId, clinic, config={} }) => {
    const setVideoUrls = useMediaStore((state) => state.setVideoUrls);

    function handleSuccess(data){
        const byId = {};
        // do something with the data
        (data?.record?.patientrecord_uploads || []).map((recordUpload) => {
            if (recordUpload?.upload?.file){
                byId[recordUpload.id] = recordUpload?.upload?.file;
            }
        });
        setVideoUrls(byId);
        return;
    }

    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!recordId,
        queryKey: [QUERY_KEY_NAME, {id: toString(recordId)}],
        queryFn: () => queryRecord({recordId, clinic}),
        onSuccess: (data) => {
            handleSuccess(data)
        }
      });
}