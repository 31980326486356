import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

export const CenteredSpinner = ({props, spinnerProps}) => {
    return (
        <Flex w={['full']} align={'center'} justify={'center'} p={10} {...props}>
            <Spinner 
                size='xl'
                thickness='4px'
                speed='0.65s' 
                color='#44C5A6'
                {...spinnerProps}
            />
        </Flex>
    )
}