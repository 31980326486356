import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const NotFoundIcon = ({width=300, height=180,  ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 328 197" fill="none" {...props}>
        <path d="M236.76 65.555V159.213C236.76 160.869 235.375 162.216 233.671 162.216H101.4C94.1109 162.216 88.207 156.477 88.207 149.391V57.304C88.207 52.0242 92.6047 47.749 98.0358 47.749H135.361C138.736 47.749 141.463 50.4103 141.463 53.6808C141.463 56.962 144.2 59.6125 147.564 59.6125H230.636C234.012 59.6125 236.738 62.2738 236.738 65.5443L236.76 65.555Z" fill="#A3B5BB"/>
        <path d="M233.246 160.253L96.2148 136.974C89.6851 136.231 84.4095 131.801 83.238 126.111L70.632 64.2285C69.5562 58.9378 74.5284 54.3451 80.5886 55.0347L209.108 69.6592C214.59 70.2829 219.022 73.9969 219.993 78.7811L235.141 158.5C235.341 159.502 234.404 160.385 233.246 160.253Z" fill="#DFE2E2"/>
        <path opacity="0.25" d="M68.2086 161.949H253.218C255.186 161.949 256.78 164.418 256.78 167.474C256.78 170.531 255.186 173 253.218 173H68.2086C66.2406 173 64.6465 170.531 64.6465 167.474C64.6465 164.418 66.2406 161.949 68.2086 161.949Z" fill="#D8D9D8"/>
        <path d="M233.945 162.344H96.452C89.8775 162.344 84.1056 158.123 82.2586 151.998L62.3042 85.3704C60.6001 79.6737 64.9867 73.9878 71.0885 73.9878H200.49C206.009 73.9878 210.857 77.5255 212.396 82.677L235.616 160.175C235.935 161.254 235.11 162.344 233.945 162.344Z" fill="#BDC9CD"/>
        <g filter="url(#filter0_b_5983_27092)">
        <ellipse cx="221.737" cy="68" rx="39.1783" ry="38" fill="#D4E7ED" fillOpacity="0.37"/>
        </g>
        <path d="M260.915 68C260.915 88.9868 243.374 106 221.737 106C200.099 106 182.559 88.9868 182.559 68C182.559 47.0132 200.099 30 221.737 30C243.374 30 260.915 47.0132 260.915 68ZM189.94 68C189.94 85.0328 204.176 98.8406 221.737 98.8406C239.298 98.8406 253.534 85.0328 253.534 68C253.534 50.9672 239.298 37.1594 221.737 37.1594C204.176 37.1594 189.94 50.9672 189.94 68Z" fill="#66767C"/>
        <rect width="8.87053" height="57.1736" rx="4.43526" transform="matrix(0.693299 -0.72065 0.74143 0.671031 242.357 93.3926)" fill="#66767C"/>
        <defs>
        <filter id="filter0_b_5983_27092" x="179.559" y="27" width="84.3574" height="82" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5983_27092"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5983_27092" result="shape"/>
        </filter>
        </defs>
    </Icon>
  )
}