import React from 'react';
import { Input } from '@chakra-ui/react';
import { AsYouType } from 'libphonenumber-js'


export const PhoneInput = ({ 
    placeholder, 
    value,
    isInvalid, 
    onChange, 
    defaultCountry='US',
    isDisabled=false 
}) => {

    const showFormattedValue = (value) => {
        const formattedValue = value.startsWith("+") ? new AsYouType().input(value) : new AsYouType(defaultCountry).input(value);
        return formattedValue;
    }

    const handleChange = (e) => {
        onChange(e.target.value);
    };
  
    return (
        <Input 
            placeholder={placeholder}
            value={showFormattedValue(value)} 
            onChange={handleChange} 
            isInvalid={isInvalid} 
            isDisabled={isDisabled} 
        />
    )
}