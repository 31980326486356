import { useQuery, useQueryClient } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { services } from '../_redux/services';
import { toString } from 'lodash';

export const QUERY_KEY_NAME = 'PatientNotes';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries
    },
    refetchOnMount: false
}

export const getQueryData = (patientUid) => {
    const queryClient = useQueryClient();
    return queryClient.getQueryData([QUERY_KEY_NAME, {id: toString(patientUid)}]);
}

export const queryRequest = async ({ patientUid, query, clinic }) => {
    return services.fetchPatientNotes(patientUid, query, clinic);
}

export const usePatientNotes = ({ patientUid, query, clinic, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!patientUid,
        queryKey: [QUERY_KEY_NAME, {id: toString(patientUid)}],
        queryFn: () => queryRequest({patientUid, query, clinic})
      });
}