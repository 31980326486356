import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import {
    Box, Flex, SimpleGrid, Button, Spinner,
    FormControl, FormLabel, FormErrorMessage, VStack, Input
} from '@chakra-ui/react';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { useSelectOptions } from '../api/getSelectOptions';
import { convertToAPIValues } from 'src/_libs/forms';
import { editDentist } from '../api/services';
import { useDentistDetails, updateDentistDetails } from '../api/getDentistDetails';

import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';

// Edit user is much different from create so new form is made
export const EditDentistForm = ({
    onSuccess,
    onClose,
    dentistId
}) => {
    const { data, isLoading, isFetching, isError, error } = useDentistDetails({dentistId})
    const { data: countriesData, isLoading: countriesIsLoading, isFetching: countriesIsFetching } = useSelectOptions({'option': 'countries'});
    const { data : gendersData, isLoading : gendersIsLoading, isFetching : gendersIsFetching } = useSelectOptions({'option': 'genders'});
    const { formatMessage } = useIntl();
    const defaultData = React.useMemo(() => [], []);
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        try{
            const result = await editDentist(dentistId, convertedValues);
            updateDentistDetails(result?.id, result)
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    if (isError){
        return (
            <Box>
                <ErrorNotFound error={error} size={"md"} buttonType={'refresh'} />
            </Box>
        )
    }
    if (isLoading || isFetching){
        return ( 
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        )
    } else {
        
        return (
            <>

                <SimpleGrid columns={[1]} spacing={colSpacing}  mt={rowSpacing}>
                    <VStack>
                        <FormControl id={'email'}>
                            <FormLabel>
                                <FormattedMessage
                                    id="adminPage.form.field.dentistEmail.label"
                                    defaultMessage="User Email"
                                />
                            </FormLabel>
                            <Input type={"text"} isDisabled={true} value={data?.dentist?.email}/>
                            <FormErrorMessage>{''}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </SimpleGrid>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={data}
                    mutators={{ 
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                    }
                    }}
                    render = {
                        ({
                            handleSubmit,
                            submitting
                        }) => {
                            return (
                                <Box as={'form'} onSubmit={handleSubmit}>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="country" validate={required} isDisabled={true}>
                                            {({input, meta}) => (
                                                <SelectOptionField 
                                                    isDisabled={true}
                                                    isLoading={countriesIsFetching || countriesIsLoading}
                                                    label={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                                    input={input}
                                                    selectOptions={countriesData?.options || defaultData}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="first_name" validate={required}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="last_name" validate={required}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'adminPage.form.field.last_name.label', defaultMessage: 'Last Name'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="date_of_birth">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'adminPage.form.field.date_of_birth.label', defaultMessage: 'Date of Birth'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.date_of_birth.label', defaultMessage: 'Date of Birth'})}
                                                    {...input}
                                                    type="date"
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="gender">
                                            {({input, meta}) => (
                                                <SelectOptionField 
                                                    isLoading={gendersIsFetching || gendersIsLoading}
                                                    isMultiple={false}
                                                    label={formatMessage({id: 'adminPage.form.field.gender.label', defaultMessage: 'Gender'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.gender.label', defaultMessage: 'Gender'})}
                                                    input={input}
                                                    selectOptions={gendersData?.options || defaultData}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="categories">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'adminPage.form.field.dentist.categories.label', defaultMessage: 'Categories'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.categories.label', defaultMessage: 'Categories'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="description" >
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    type='textarea'
                                                    label={formatMessage({id: 'adminPage.form.field.dentist.description.label', defaultMessage: 'Description'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.dentist.description.label', defaultMessage: 'Description'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                        <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.cancel.button.label"
                                                defaultMessage="Cancel"
                                            />
                                        </Button>
                                        <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.submit.button.label"
                                                defaultMessage="Submit"
                                            />
                                        </Button>
                                    </SimpleGrid>
                                </Box>
                            )
                        }
                    }
                />
            </>
        )
    }   
}