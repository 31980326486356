import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { services } from '../_redux/services';
import { toString } from 'lodash';

export const QUERY_KEY_NAME = "PATIENT_REQUESTS";

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    },
    refetchOnMount: false
}

export const queryPatientRequests = async ({ patientUid, ...queryParams }) => {
    // filter through the input params and remove any empty values
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    // format the params object into a URLSearchParams object
    const searchParams = new URLSearchParams(paramsObj)
    return services.getPatientRequests(patientUid, searchParams );
}

export const usePatientRequests = ({ patientUid, status, clinic }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!patientUid,
        queryKey: [QUERY_KEY_NAME, {id: toString(patientUid), status}],
        queryFn: () => queryPatientRequests({patientUid, status, clinic}),
      });
}