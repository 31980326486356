import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';


export const QUERY_KEY_NAME = 'BulkReports';

import { fetchBulkReports } from './services'

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    },
}

export const queryRequest = async (queryParams) => {
    // filter through the input params and remove any empty values
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    // format the params object into a URLSearchParams object
    const searchParams = new URLSearchParams(paramsObj)
    return fetchBulkReports(searchParams);
}

export const useBulkReports = ({ date_filter, start_date, end_date, patient, clinic=null, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        queryKey: [QUERY_KEY_NAME, {date_filter, start_date, end_date, patient, clinic}],
        queryFn: () => queryRequest({date_filter, start_date, end_date, patient, clinic}),
      });
}