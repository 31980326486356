import React, {useEffect, useState} from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';

import { REQUEST_TYPES } from '../_config';


import { Flex, Accordion, Spinner, useDisclosure } from '@chakra-ui/react';

// component redux
import { actions } from './_redux/actions';
import {
  getDetailsRequestType,
} from './_redux/selectors';

// components
import { PatientSection } from '../RequestDetailPage/PatientSection';
//import { PatientRecordsList } from './PatientRecordsList';
import { ConfirmAppointmentModal } from './ConfirmAppointmentModal';
import { PatientRecordViewerModal } from './PatientRecordViewer';
import { AppointmentStartSection, AppointmentCompleteForm } from './AppointmentCompleteModal';

import { CompletedTasksSection, RequestTasksSection } from './RequestTasksSection';

// actions
import { actions as patientActions } from '../Patients/_redux/actions';

// selectors
//import { getUUID } from '../App/_redux/selectors';
import {
  getCurrentPatient,
  //getPatientRequests,
  //getPatientRequestsLoading,
  //getPatientRequestsError,
  //getPatientRecords,
  //getPatientRecordsLoading,
  //getPatientRecordsError,
} from '../Patients/_redux/selectors';

  // styles
import {
  StyledDetailsSection,
  StyledRequestDetailsContainer,
  WrappedPage
} from './styles';
import { consoleLog } from '../_helpers/consoleLog';
import { generalModalStyles, ModalCloseIcon } from '../_css/styles';
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { PatientReportPdfReviewModal } from './PatientReportReviewModal';
import { PatientSingleRecordModal } from 'src/PatientRecords/PatientSingleRecordModal';
import { usePatientRequests } from './api/getPatientRequests';
import { useAppConfig } from 'src/App/state/appConfig';
import { usePatientDetails } from 'src/Patients/api/getPatientDetails';


const getAccordionIndices = (pendingRequests, completedRequests) => {
  let indices = [];
  if (pendingRequests?.total > 0) {
    indices.push(0);
  }
  if (completedRequests?.total > -1) {
    indices.push(1);
  }
  return indices;
}

const DetailsSection = ({currentPatientId, currentPatientData, onPatientRecordClick}) => {
  const [ currentReportUid, setCurrentReportUid ] = useState(null); 
  const clinic = useAppConfig(state => state.clinic?.id)
  const { data: pendingRequests, isLoading: isPendingLoading, isFetching: isPendingFetching } = usePatientRequests({patientUid: currentPatientId, status: 'PENDING', clinic});
  const { data: completedRequests, isLoading: isCompletedLoading, isFetching: isCompletedFetching } = usePatientRequests({patientUid: currentPatientId, status: 'COMPLETED', clinic});
  const { isOpen, onOpen, onClose } = useDisclosure();
  /*
  //const patientRequests = useSelector(getPatientRequests);
  //const patientRequestsLoading = useSelector(getPatientRequestsLoading);
  //const patientRequestsError = useSelector(getPatientRequestsError);

  //const patientRecords = useSelector(getPatientRecords);
  //const patientRecordsLoading = useSelector(getPatientRecordsLoading);
  //const patientRecordsError = useSelector(getPatientRecordsError);

  useEffect(()=>{
    if (currentPatientId){
      dispatch(patientActions.getCurrentPatientRequestsByUUid(currentPatientId));
      dispatch(patientActions.getCurrentPatientRecordsByUUid(currentPatientId));
    }
  }, [currentPatientId])
  */

  //const sortedRequests = patientRequests.sort(sortByPendingFirst);

  const handleRequestReviewClick = (reportUid) => {
    setCurrentReportUid(reportUid);
    console.log("current report id handleRequestReviewClick ", reportUid)
    onOpen();
  }

  return (
    <StyledDetailsSection key={`styledDetailsSection_${currentPatientId}`}>
      <PatientReportPdfReviewModal isOpen={isOpen} reportUid={currentReportUid} onClose={onClose} />
      <div className={'sectionColumn'}>
        {
          /*<AppointmentTasksSection 
          key={`appointmentTasksSection_${currentPatientId}`}
          loading={patientAppointmentsLoading}
          error={patientAppointmentsError}
          appointments={patientAppointments}
          />*/
        }
        { (isPendingLoading || isPendingFetching || isCompletedFetching || isCompletedLoading) ? (
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        ) : (
          <Accordion
            reduceMotion={true}
            allowToggle={true}
            allowMultiple={true}
            defaultIndex={getAccordionIndices(pendingRequests, completedRequests)}
          >
            <RequestTasksSection 
              patientUid={currentPatientId}
              onReviewClick={handleRequestReviewClick}
              onPatientRecordClick={onPatientRecordClick}
            />
            <CompletedTasksSection 
              patientUid={currentPatientId}
              patientData={currentPatientData}
              onReviewClick={handleRequestReviewClick}
              onPatientRecordClick={onPatientRecordClick}
            />
          </Accordion>
        )}
        {/*<PatientRecordsList
          loading={patientRecordsLoading}
          error={patientRecordsError}
          records={patientRecords}
        />*/}
      </div>
      {/*<div className={'sectionColumn'}>
        <div className={'patientChat'}>
          <ReportVideoChat currentRequestUid={currentRequestUid}/>
          <ChatConversation conversationId={conversationId}/>
        </div>
      </div>*/}
    </StyledDetailsSection>
  )
 }

/**
 * Detail Page
 */
export const PatientDetailsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const urlPatientUid = match.params?.patient_uid;
  const currentRequestType = useSelector(getDetailsRequestType);
  const currentPatientId = useSelector(getCurrentPatient);
  const [ currentPatientRecord, setCurrentPatientRecord ] = useState(null);
  const clinic = useAppConfig(state => state.clinic?.id);
  const { data } = usePatientDetails({patientUid: urlPatientUid, clinic});

  const [ isVideoChatDialogOpen, setIsVideoDialogChatOpen] = useState(false);
  const [ [isVideoChatFinished, initAppointmentFormPage], setIsVideoChatFinished ] = useState([false, 0]);


  useEffect(()=>{
    return () => {};
  }, [])

  useEffect(()=>{
    let searchParams = new URLSearchParams(location.search);
    // task modal,
    if (searchParams.get('mdl') == `task.${REQUEST_TYPES.REPORT}` &&  searchParams.get('rid')){
      dispatch(actions.update({currentRequestId: searchParams.get('rid') }));
      consoleLog(`open report modal ${searchParams.get('rid')}`)
      dispatch(actions.openReportModal());
    } else if (searchParams.get('mdl') == `task.${REQUEST_TYPES.VIDEOCHAT}` && searchParams.get('rid')){
      dispatch(actions.update({currentRequestId: searchParams.get('rid') }));
      dispatch(actions.openAppointmentFormModal());
    } else if (searchParams.get('mdl') == `appointment.${REQUEST_TYPES.VIDEOCHAT}` && searchParams.get('rid')){
      dispatch(actions.update({currentRequestId: searchParams.get('rid') }));
      setIsVideoDialogChatOpen(true);
      // open start video chat modal
      //dispatch(actions.openAppointmentCompleteModal());
    } else {
      dispatch(actions.update({currentRequestId: null}));
    }
  }, [location.search])

  useEffect(()=>{
    // fetch request when id changes in url
    //
    if (urlPatientUid != null && urlPatientUid?.length > 0){
      let currPatient = urlPatientUid.split("?")[0]
      window.scrollTo(0, 0);
      dispatch(patientActions.updateCurrentPatient(currPatient))
    }
  }, [urlPatientUid])

  /*useEffect(()=>{
    // when patient is loaded, update chat/user redux
    //if (currentPatientId){
      //if (!patientsListLoading && patientsList[currentPatientId] == null){
      //  dispatch(patientActions.loadPatientDetailsByUUid(currentPatientId));
      //}
      //dispatch(noteActions.fetchPatientNotes(currentPatientId));
      //dispatch(actions.updateCurrentPatientUuid(currentPatientId));
      //dispatch(patientActions.getCurrentPatientRequestsByUUid(currentPatientId));
      //dispatch(patientActions.getCurrentPatientRecordsByUUid(currentPatientId));
      //dispatch(patientActions.getCurrentPatientAppointmentsByUUid(currentPatientId));
    }
  }, [currentPatientId, myUuid])*/

  const confirmAppointmentModalOnClose = () => {
    dispatch(actions.closeAppointmentFormModal());
    let searchParams = new URLSearchParams(location.search);
    searchParams.delete('mdl');
    searchParams.delete('rid');
    history.push(location.pathname+searchParams.toString());
  }

  const onVideoChatFinished = () => {
    // dentist close video chat. 
    setIsVideoChatFinished([true, 0]);
  }

  const onVideoChatAlreadyComplete = () => {
    // dentist clicked skip because video chat was already completed.
    setIsVideoChatFinished([true, 1]);
  }

  const closeVideoDialogChat = () => {
    setIsVideoDialogChatOpen(false);
    setIsVideoChatFinished([false, 0]); // also reset the video chat state
    let searchParams = new URLSearchParams(location.search);
    searchParams.delete('mdl');
    searchParams.delete('rid');
    history.push(location.pathname+searchParams.toString());
  }

  return (
    <WrappedPage>
      <StyledRequestDetailsContainer key={urlPatientUid}>
        <PatientRecordViewerModal />
        <ConfirmAppointmentModal 
          onClose={confirmAppointmentModalOnClose}
        />
        <PatientSingleRecordModal 
          isOpen={currentPatientRecord != null}
          onClose={() => setCurrentPatientRecord(null)}
          recordUid={currentPatientRecord}
        />
        <Modal
          isOpen={isVideoChatDialogOpen}
          onRequestClose={() => closeVideoDialogChat()}
          style={generalModalStyles}
          shouldCloseOnOverlayClick={false}
          contentLabel="Start Appointment"
        >
          <div style={{ position: 'relative', height: '100%'}}>
            {!isVideoChatFinished && <ModalCloseIcon onClick={() => closeVideoDialogChat()} ><CloseSvg/ ></ModalCloseIcon>}
            {
              !isVideoChatFinished 
                ? <AppointmentStartSection 
                    currentRequestType={currentRequestType} 
                    onClose={onVideoChatFinished} 
                    onSkip={onVideoChatAlreadyComplete}
                  />
                : <AppointmentCompleteForm 
                    initialPage={initAppointmentFormPage}
                    patientUid={currentPatientId} 
                    onNotCompleted={() => setIsVideoChatFinished([false, 0])}
                    onFinish={() => closeVideoDialogChat()}
                  />
            }
          </div>
        </Modal>
        {/*<UpcomingSection />*/}
        <PatientSection 
          patientUid={currentPatientId}
        />
        <DetailsSection
          currentPatientId={currentPatientId}
          currentPatientData={data?.patient || {}}
          onPatientRecordClick={(recordUid) => setCurrentPatientRecord(recordUid)}
        />
      </StyledRequestDetailsContainer>
    </WrappedPage>
  )
}
