import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchReport } from './services';
import { toString } from 'lodash';

import { QUERY_KEY_NAMES } from './constants';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_REPORT;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    }
}

export const queryReportDetails = async ({ reportId, clinic }) => {
    return fetchReport(reportId, clinic );
}

export const useReportDetails = ({ reportId, clinic }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!reportId,
        queryKey: [QUERY_KEY_NAME, {id: toString(reportId)}],
        queryFn: () => queryReportDetails({reportId, clinic}),
      });
}