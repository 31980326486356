import { useQuery } from 'react-query';
import { queryConfig, queryClient } from 'src/_libs/react-query';

import { services } from '../_redux/services';
import { toString } from 'lodash';

export const QUERY_KEY_NAME = 'PatientDetails';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries
    },
    refetchOnMount: false
}

export const getQueryData = (patientUid) => {
    return queryClient.getQueryData([QUERY_KEY_NAME, {id: toString(patientUid)}]);
}

export const queryRequest = async ({ patientUid, clinic }) => {
    return services.fetchPatientDetailsByUUid(patientUid, clinic);
}

export const updateQueryPatientDetails = (patientUid, newData) => {
    queryClient.setQueryData([QUERY_KEY_NAME, {id: toString(patientUid)}], (oldData) => {
        return {
            patient: {
                ...oldData.patient,
                ...newData
            }
        }
    })
}

export const usePatientDetails = ({ patientUid, clinic, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!patientUid,
        queryKey: [QUERY_KEY_NAME, {id: toString(patientUid)}],
        queryFn: () => queryRequest({patientUid, clinic})
      });
}