import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toUpper, lowerCase, startCase, upperFirst } from 'lodash';
import cn from 'classnames';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';

import { FormattedMessage } from 'react-intl';

import {formatName, formatString, localeFormatDate } from '../_helpers';

// components
import { LoadingEllipsis } from '../_components/Loaders';
import { PatientNotes as PatientNotesinModal } from '../PatientNotes';

import { MdOutlineStickyNote2 } from 'react-icons/md';
import { Flex, Box, Text, Skeleton, Center, useDisclosure, Button,
  Modal, ModalOverlay, ModalHeader, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';

//redux state
import { actions } from './_redux/actions';
import {
  getCurrentPatientNotes,
  getCurrentPatientNotesLoading,
  //getCurrentPatientNotesIsMore,
} from './_redux/selectors';


// actions
//import { actions as patientActions } from '../Patients/_redux/actions';
import { actions as noteActions } from '../PatientNotes/_redux/actions';
import { actions as dentistActions } from '../Dentists/_redux/actions';

// selectors
import { getCurrentPatient, getPatientInformation  } from '../Patients/_redux/selectors';
import { getIntlMessages, getIntlLocale, getProfileIsStaff } from '../App/_redux/selectors';

  // styles
import { StyledHeader } from './_common/styles';

//styles
import { consoleLog } from '../_helpers/consoleLog';
import { getDentistsById, getDentistsLoading } from '../Dentists/_redux/selectors';
import { Link } from 'react-router-dom';
import { GenderText } from 'src/_components/LabelFormats/Gender';
import { usePatientDetails } from 'src/Patients/api/getPatientDetails';
import { useAppConfig } from 'src/App/state/appConfig';
import { CenteredSpinner } from 'src/_components/Loaders/CenteredSpinner';
import { usePatientNotes } from 'src/Patients/api/getPatientNotes';
import { EditPatientForm } from 'src/Patients/PatientEditForm';
import { PlusIcon } from 'src/_images/icons/PlusIcon';

const AddNewButton = ({handleClick}) => {
  return (
    <Button 
      variant={'ghost'} 
      onClick={handleClick} 
      size={'sm'}
      leftIcon={<PlusIcon width={8} height={8}/>}
    >
        <FormattedMessage 
          id={'patientSection.notes.add.button.text'}
          defaultMessage={'Add'}
        />
    </Button>
  )
}


const PatientNoteItem = ({note, openNoteModal}) => {
  const locale = useSelector(getIntlLocale);

  const handleClick = () => {
    openNoteModal();
  }

  return(
    <div className={'patientNoteItem'} onClick={handleClick}>
      <div className={'patientNoteContent'}><span>{note.text}</span></div>
      <div className={'patientNoteItemDate'}><span>{localeFormatDate(note.created_at, locale, "MMM DD" )}</span></div>
    </div>
  )
}

// TODO remove
// eslint-disable-next-line no-unused-vars
const PatientNotesRedux = ({setIsAddNote}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentPatient = useSelector(getCurrentPatient);
  const currentPatientNotes = useSelector(getCurrentPatientNotes);
  const [height, setHeight] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0)
  //const currentPatientNotesIsMore = useSelector(getCurrentPatientNotesIsMore);
  const loading = useSelector(getCurrentPatientNotesLoading);
  const ref = useRef(null);
  //const requests = useSelector(getPatientRequests)

  useEffect(() => {
    consoleLog("currentpatient " + currentPatient)
    if (currentPatient){
      dispatch(noteActions.fetchPatientNotes(currentPatient));
    }
    if (ref.current){
      setHeight(ref.current.clientHeight)
      setWidth(ref.current.clientWidth)
    }
    function handleResize() {
      if (ref.current){
        setHeight(ref.current.clientHeight)
        setWidth(ref.current.clientWidth)
      }
    }
    window.addEventListener('resize', handleResize)
  }, [currentPatient])

  const addNewClick = () => {
    setIsAddNote(true)
    dispatch(actions.openPatientNotesModal());
  }

  if (loading){
    return (
      <div className={'patientNote'}>
        <div className={'columnTitle'}>
          <span>{startCase(intlMessages['requestDetailPage.header.patient.notes.title'])}</span>
          <AddNewButton handleClick={addNewClick}/>
        </div>
        <LoadingEllipsis />
      </div>
    )
  } else {
    return(
      <div className={'patientNote'}>
        <div className={'columnTitle'}>
          <span>{startCase(intlMessages['requestDetailPage.header.patient.notes.title'])}</span>
          <AddNewButton handleClick={addNewClick}/>
        </div>
        <div ref={ref} className={'columnContent'}>
          <Scrollbars
              style={{height: height}}
            >
          {currentPatientNotes.map((note) => (
            <PatientNoteItem
              key={note.id}
              setIsAddNote={setIsAddNote}
              note={note}
              />
          ))}
          </Scrollbars>
          { currentPatientNotes.length < 1 &&
              <Flex direction={'column'} align={'center'} justify={'center'}>
                <MdOutlineStickyNote2 size={26} fill={'#7A8F99'} style={{margin: 0}} />
                <Text fontSize={16} mt={3}>{upperFirst(intlMessages['requestDetailPage.header.patient.notes.blank'])}</Text>
              </Flex>
          }
        </div>
      </div>
    )
  }

}

const PatientNotes = ({patientUid, openNoteModal}) => {
  const intlMessages = useSelector(getIntlMessages);
  const clinic = useAppConfig(state => state.clinic?.id)
  const { data, isLoading, isFetching } = usePatientNotes({patientUid, clinic})
  const [height, setHeight] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0)
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current){
      setHeight(ref.current.clientHeight)
      setWidth(ref.current.clientWidth)
    }
    function handleResize() {
      if (ref.current){
        setHeight(ref.current.clientHeight)
        setWidth(ref.current.clientWidth)
      }
    }
    window.addEventListener('resize', handleResize)
  }, [data?.patient_notes])

  const addNewClick = () => {
    openNoteModal()
  }

  if (isLoading || isFetching ){
    return (
      <div className={'patientNote'}>
        <div className={'columnTitle'}>
          <span>{startCase(intlMessages['requestDetailPage.header.patient.notes.title'])}</span>
          <AddNewButton handleClick={addNewClick}/>
        </div>
        <LoadingEllipsis />
      </div>
    )
  } else {
    return(
      <div className={'patientNote'}>
        <div className={'columnTitle'}>
          <span>{startCase(intlMessages['requestDetailPage.header.patient.notes.title'])}</span>
          <AddNewButton handleClick={addNewClick}/>

        </div>
        <div ref={ref} className={'columnContent'}>

        <Scrollbars
          style={{height: height}}
        >
        { (data?.patient_notes || []).length < 1 ? (
            <Flex direction={'column'} align={'center'} justify={'center'} pt={'25px'}>
              <MdOutlineStickyNote2 size={26} fill={'#7A8F99'} style={{margin: 0}} />
              <Text fontSize={16} mt={3}>{upperFirst(intlMessages['requestDetailPage.header.patient.notes.blank'])}</Text>
            </Flex>
          ) : (
            <Box>
              {(data?.patient_notes || []).map((note) => (
                <PatientNoteItem
                  key={note.id}
                  openNoteModal={openNoteModal}
                  note={note}
                  />
              ))}
            </Box>
          )}
          </Scrollbars>
        </div>
      </div>
    )
  }
}

const PatientNotesModal = ({patientUid, isOpen, onClose, focused=false}) => {
  const clinic = useAppConfig(state => state.clinic?.id)
  const { data, isLoading, isFetching } = usePatientNotes({patientUid, clinic})
  const currentPatientNotes = data?.patient_notes || []


  const closeModal = () => {
    onClose();
  }

  return (
    
    <Modal 
      isOpen={isOpen} 
      onClose={closeModal}  
      shouldCloseOnOverlayClick={false}
      isCentered 
      scrollBehavior={'inside'}
    >
      <ModalOverlay bg="blackAlpha.300" />
      <ModalContent minW={'580px'} bg="#f7f9fa">
        <ModalHeader >
          <Center>
              <FormattedMessage 
                id={'requestDetailPage.header.patient.newNote.header.title'}
                defaultMessage={'Patient Notes'}
              />
          </Center>
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody pb={'10px'}>
          <PatientNotesinModal 
              patientUid={patientUid}
              notes={currentPatientNotes} 
              focused={focused}  
              loading={isLoading || isFetching} 
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const PatientEditFormModal = ({patientUid, isOpen, onClose, onSuccess}) => {
  return (
    <Modal 
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      isCentered
      scrollBehavior={'inside'}
    >
      <ModalOverlay bg="blackAlpha.300" />
      <ModalContent minW="700px" bg="#f7f9fa">
        <ModalHeader >
          <Center>
              <FormattedMessage 
                id={'requestDetailPage.header.patient.editForm.header.title'}
                defaultMessage={'Patient Details'}
              />
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditPatientForm 
            patientUid={patientUid}
            onSuccess={onSuccess}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
        
    </Modal>
  )
}

const ProfileAvatar = ({src, initials, isLoaded}) => {
  if (!isLoaded){
    return (
      <Skeleton>
        <div className='patientAvatar'>
          <img  />
        </div>
      </Skeleton>
    )
  }
  if (src && src.length > 0){
    return (
      <div className='patientAvatar'>
        <img src={src} />
      </div>
    )
  } else {
    return (
      <div className='patientAvatar'>
        <div className='initialsAvatar'>
          {initials}
        </div>
      </div>
    )
  }
}

const ListItemParent = ({relationship,  parent}) => {
  const intlMessages = useSelector(getIntlMessages);

  const related_to = parent
    ? ` ${formatName(intlMessages['format.fullName'], parent.first_name, parent.last_name) }`
    : ''

  if (parent == null){
    return <div></div>
  }

  return (
    <Flex>
      {
        related_to 
        ? <Link to={`/patients/${parent.unique_id}`}>{formatString(intlMessages['format.relationshipTo'], upperFirst(lowerCase(relationship)), related_to)}</Link> 
        : '' 
      }
    </Flex>
  )
}

export const PatientSectionRedux = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale)
  const patientDetails = useSelector(getPatientInformation);
  const isStaff = useSelector(getProfileIsStaff);
  const dentistsById = useSelector(getDentistsById);
  const dentistsLoading = useSelector(getDentistsLoading);

  const [ isAddNote, setIsAddNote ] = useState(false);

  useEffect(() => {
    // fetch dentist if not found in byId
    if (isStaff && patientDetails?.dentist !== undefined){
      if (dentistsLoading[patientDetails?.dentist] !== true && dentistsById[patientDetails?.dentist] === undefined){
        dispatch(dentistActions.fetchDentistDetails(patientDetails?.dentist));
      }
    }
  }, [patientDetails, dentistsById, dentistsLoading, isStaff])

  const dentist = dentistsById[patientDetails?.dentist];
  

  return (
    <StyledHeader>
      <div className={'patientAvatarWrapper'}>
        <div className={'columnTitle'} style={{visibility:'hidden'}}>
          {'Avatar'}
        </div>
        <ProfileAvatar
          isLoaded={patientDetails?.first_name}
          src={patientDetails.avatar}
          initials={(!patientDetails.first_name && "B") || patientDetails.first_name[0]}
          />
      </div>
      <div className={'patientSection'}>
        <div className={'columnTitle'}>
          <div>{startCase(intlMessages['requestDetailPage.header.patient.information.title'])}</div>
        </div>
        <div className={'patientSummaryWrapper'}>
          <div className={cn("patientColumn", "patientInformation")}>
            <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.name'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name !== undefined}>
                    <div>{patientDetails.first_name}{' '}{patientDetails.last_name}</div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.birthdate'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name !== undefined}>
                  <div>{localeFormatDate(patientDetails.date_of_birth, intlLocale) + ` (${moment().diff(patientDetails.date_of_birth, 'years')})`}</div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.gender'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name}>
                  <div><GenderText gender={patientDetails?.gender} /></div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.parent'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.parent !== undefined}>
                  <ListItemParent 
                    relationship={patientDetails.relationship}
                    parent={patientDetails.parent} 
                  />
                </Skeleton>
              </div>
              {
                isStaff &&
                (
                  <div className={'informationRow'}>
                    <div>{toUpper(intlMessages['requestDetailPage.header.patient.dentist'])}</div>
                    <Skeleton minWidth='250px' w='fit-content' isLoaded={dentist !== undefined}>
                      <div>{`Dr. ${formatName(intlMessages['format.fullName'], dentist?.first_name, dentist?.last_name)}`} {isStaff && ` (${dentist?.country})`}</div>
                    </Skeleton>
                  </div>
                )
              }
          </div>
        </div>
      </div>
      <PatientNotes setIsAddNote={setIsAddNote}/>
      <PatientNotesModal focused={isAddNote} />
    </StyledHeader>
  )
}

export const PatientSection = ({patientUid}) => {
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale)
  //const patientDetails = useSelector(getPatientInformation);
  const { isOpen: isNotesModalOpen, onOpen: onOpenNotesModal, onClose: onCloseNotesModal } = useDisclosure(); 
  const { isOpen: isEditFormOpen, onOpen: onOpenEditForm, onClose: onCloseEditForm } = useDisclosure(); 
  const clinic = useAppConfig(state => state.clinic?.id)
  const { data, isLoading, isFetching } = usePatientDetails({patientUid, clinic })
  const { data: dentistData, isLoading: isDentistLoading, isFetching: isDentistFetching } = usePatientDetails({useDentistDetails: data?.patient?.dentist, clinic })
  const isStaff = useSelector(getProfileIsStaff);
  const [ isAddNote, setIsAddNote ] = useState(false);

  const openNoteModal = () => {
    onOpenNotesModal();
    setIsAddNote(true);
  }
  
  const patientDetails = data?.patient || {};
  const dentist = dentistData || {};

  if (isLoading || isFetching){
    return (
      <StyledHeader>
        <CenteredSpinner />
      </StyledHeader>
    )
  }

  return (
    <StyledHeader>
      <div className={'patientAvatarWrapper'}>
        <div className={'columnTitle'} style={{visibility:'hidden'}}>
          {'Avatar'}
        </div>
        <ProfileAvatar
          isLoaded={patientDetails?.first_name !== undefined}
          src={patientDetails.avatar}
          initials={(!patientDetails.first_name && "B") || patientDetails.first_name[0]}
          />
      </div>
      <div className={'patientSection'}>
        <div className={'columnTitle'}>
          <div>{startCase(intlMessages['requestDetailPage.header.patient.information.title'])}</div>
          <Flex grow={1} justify={'flex-end'} pr={'30px'}>
            <Button variant={"ghost"} size={"sm"} onClick={onOpenEditForm} >
              <FormattedMessage 
                id={'requestDetailPage.header.patient.edit.button.text'}
                defaultMessage={'Edit'}
              />
            </Button>
          </Flex>
        </div>
        <div className={'patientSummaryWrapper'}>
          <div className={cn("patientColumn", "patientInformation")}>
            <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.name'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name !== undefined}>
                    <div>{patientDetails.first_name}{' '}{patientDetails.last_name}</div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.birthdate'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name !== undefined}>
                  <div>{patientDetails?.date_of_birth 
                    ? localeFormatDate(patientDetails.date_of_birth, intlLocale) + ` (${moment().diff(patientDetails.date_of_birth, 'years')})` 
                    : '-'}</div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.gender'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name !== undefined}>
                  <div><GenderText gender={patientDetails?.gender} /></div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.parent'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.parent !== undefined}>
                  <ListItemParent 
                    relationship={patientDetails.relationship}
                    parent={patientDetails.parent} 
                  />
                </Skeleton>
              </div>
              {
                isStaff &&
                (
                  <div className={'informationRow'}>
                    <div>{toUpper(intlMessages['requestDetailPage.header.patient.dentist'])}</div>
                    <Skeleton minWidth='250px' w='fit-content' isLoaded={isDentistFetching || isDentistLoading}>
                      <div>{`Dr. ${formatName(intlMessages['format.fullName'], dentist?.first_name, dentist?.last_name)}`} {isStaff && ` (${dentist?.country})`}</div>
                    </Skeleton>
                  </div>
                )
              }
          </div>
        </div>
      </div>
      <PatientEditFormModal
        patientUid={patientUid}
        isOpen={isEditFormOpen}
        onClose={onCloseEditForm}
      />
      <PatientNotes 
        patientUid={patientUid}
        openNoteModal={openNoteModal}
      />
      <PatientNotesModal 
        patientUid={patientUid}
        isOpen={isNotesModalOpen}
        onClose={onCloseNotesModal}
        setIsAddNote={setIsAddNote}
        focused={isAddNote} 
      />
    </StyledHeader>
  )
}
