import { authHeader, handleResponse } from '../../_helpers';
import { API_URLS } from '../../_config';

export const services = {
  getRequest,
  getPatientRequests
};

function getRequest(requestId) {
  const requestUrl = API_URLS.getDentistRequestDetailId;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{requestId}', requestId);

  return fetch(url, requestOptions).then(handleResponse);
}

function getPatientRequests(patientUid, searchParams) {
  const requestUrl = API_URLS.getDentistPatientRequestsUUid;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patient_uuid}', patientUid) + '?' + searchParams.toString();

  return fetch(url, requestOptions).then(handleResponse);
}