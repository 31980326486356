import { useMutation } from 'react-query';
import { queryClient } from 'src/_libs/react-query';

//import { nanoid } from 'nanoid';

import { services } from '../_redux/services';

import { QUERY_KEY_NAME as GET_NOTES_QUERY_KEY_NAME } from './getPatientNotes';

const QUERY_KEY_NAME = "SavePatientNote";

//const update = async ({ noteUid, data, clinic }) => {
//    return services.updateNote(noteUid, data, clinic);
//}

const create = async ({ patientUid, data, clinic }) => {
    return services.createPatientNote(patientUid, data, clinic);
}

//const handleUpdateError = (noteUid, error, context) => {
//    console.log(error);
//    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {reportId: noteUid}], context.previousData);
//}

/*const defaultData = {
    patient: {
        //id: nanoid(4),
        //unique_id: nanoid(20)
    },
    created_by: {
        //id: nanoid(4),
        //unique_id: nanoid(20),
        "first_name": "",
        "last_name": ""
    },
    extra_data: {},
}*/

/*const handleMutate = async (patientUid, data) => {
    // cancel current queries to notes
    await queryClient.cancelQueries([GET_NOTES_QUERY_KEY_NAME, {patientUid}])
    // create optimistic note data for placeholder
    const now = new Date();
    const optimisticNote = {
        ...defaultData,
        id: nanoid(4),
        unique_id: nanoid(20),
        text: data, 
        created_at: now.toISOString(),
        created_at_ts: now.getTime()/1000
    };

    // add the optimistic note to patient's note
    queryClient.setQueryData([GET_NOTES_QUERY_KEY_NAME, {patientUid}], (oldData) => {
        return {
            ...oldData,
            patient_notes: [
                optimisticNote,
                ...oldData.patient_notes
            ]
        }
    });
    // Return context with the optimistic note
    return {optimisticNote};
}*/

const handleSuccess = async (patientUid, result, /* context */) => {
    // if i added optimistically in 
    queryClient.setQueryData([GET_NOTES_QUERY_KEY_NAME, {patientUid}], (oldData) => {
        return {
            ...oldData,
            patient_notes: [
                result,
                ...oldData.patient_notes
            ]
        }
    });
    // if i added optimistically in
    //queryClient.setQueryData([GET_NOTES_QUERY_KEY_NAME, {patientUid}], old => old.map(note => note.id === context.optimisticNote.unique_id ? result : note))

}

const handleError =  (patientUid, error, context) => {
    console.log(error);
    queryClient.setQueryData([GET_NOTES_QUERY_KEY_NAME, {patientUid}], context.previousData);
    // if i added optimistically in onMutate
    //queryClient.setQueryData([GET_NOTES_QUERY_KEY_NAME, {patientUid}], old => old.filter(note => note.id !== context.optimisticNote.unique_id))
}

//export const useUpdateNote = ({ noteUid, clinic }) => {
//    return useMutation({
//        mutationKey: QUERY_KEY_NAME_UPDATE,
//        onMutate: (data) => handleUpdateSuccess(noteUid, data),
//        //onError: (error, context) => {handleUpdateError(noteUid, error, context)},
//        mutationFn: (data) => update({ noteUid, data, clinic })
//    });
//}

export const useCreateNote = ({ patientUid, clinic }) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAME,
        //onMutate: (data) => handleMutate(patientUid, data),
        onSuccess: (result, _variables, context) => handleSuccess(patientUid, result, context),
        onError: (error, context) => {handleError(patientUid, error, context)},
        mutationFn: (data) => create({ patientUid, data, clinic })
    });
}