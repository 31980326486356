export const storage = {
  set: (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  get: (key) => {
    try {
      const value =  JSON.parse(window.localStorage.getItem(key));
      return value;
    } catch {
      return null;
    }
  },
  getToken: () => {
    try {
      const user =  JSON.parse(window.localStorage.getItem(`user`));
      return user.token;
    } catch {
      return null;
    }
  },
  setToken: (token) => {
    window.localStorage.setItem(`token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`token`);
  },
  setCurrentClinic: (clinic) => {
    window.localStorage.setItem(`currentClinic`, JSON.stringify(clinic));
  },
  getCurrentClinic: () => {
    try {
      const clinic =  JSON.parse(window.localStorage.getItem(`currentClinic`));
      return clinic;
    } catch {
      return null;
    }
  },
  getCurrentClinicId: () => {
    try {
      const clinic =  JSON.parse(window.localStorage.getItem(`currentClinic`));
      return clinic?.id;
    } catch {
      return null;
    }
  },
  setPatientIsShowCompleted: (isShowCompleted) => {
    window.localStorage.setItem(`isShowCompleted`, JSON.stringify(isShowCompleted));
  },
  getPatientIsShowCompleted: () => {
    try {
      const isShowCompleted =  JSON.parse(window.localStorage.getItem(`isShowCompleted`));
      return isShowCompleted;
    } catch {
      return false;
    }
  },
};
