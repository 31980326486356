import { useQuery, useQueryClient } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchRequest } from './services';
import { toString } from 'lodash';

export const QUERY_KEY_NAME = 'RequestDetails';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries
    },
    refetchOnMount: false
}

export const getQueryData = (requestId) => {
    const queryClient = useQueryClient();
    return queryClient.getQueryData([QUERY_KEY_NAME, {id: toString(requestId)}]);
}

export const queryRequest = async ({ requestId, clinic }) => {
    return fetchRequest(requestId, clinic);
}

export const useRequestDetails = ({ requestId, clinic, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!requestId,
        queryKey: [QUERY_KEY_NAME, {id: toString(requestId)}],
        queryFn: () => queryRequest({requestId, clinic})
      });
}