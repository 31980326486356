import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchSelectOptions } from './services';

export const QUERY_KEY_NAME = 'Dentist_SelectOptions';

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (select_option) => {
    return fetchSelectOptions(select_option)
}

export const useSelectOptions = ({ option="" }) => {
    let queryName = null
    if( option == "request_statuses"){
        queryName = `${QUERY_KEY_NAME}_requestStatuses`
    } else if( option == "request_types"){
        queryName = `${QUERY_KEY_NAME}_requestTypes`
    } else if(option == "genders"){
        queryName = `${QUERY_KEY_NAME}_genders`
    } else if (option == "relationships"){
        queryName = `${QUERY_KEY_NAME}_relationships`
    }

    return useQuery({
        ...newQueryConfig,
        queryKey: [queryName, {}],
        queryFn: () => queryRequest(option), // in brackets because im parsing the object in queryRequest
      });
}