import React from 'react';
import { 
    Grid, GridItem, Input, Textarea, Box, Th, Tr, Td, Button, IconButton, ButtonGroup,
    Editable, EditablePreview, EditableInput, EditableTextarea, useEditableControls,

 } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseIconInCircle } from 'src/_images/icons/CloseIconInCircle';
import { CheckIconInCircle } from 'src/_images/icons/CheckIconInCircle';
import { PlusIcon } from 'src/_images/icons/PlusIcon';

import { nanoid } from 'nanoid';
import { omit } from 'lodash';

const GRID_TEMPLATE_COLUMNS = ["1fr 3fr 5fr 1fr"];
const GRID_GAP = '15px';
const GRID_PROPS = {
    px: '10px',
    borderBottom: '1px solid #E2E8F0',
    py:'10px'
}

const INPUT_BORDER_RADIUS = '5px';

function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
        <IconButton
            icon={<CloseIconInCircle />}
            borderRadius={'50%'}
            variant={'outline'}
            {...getCancelButtonProps()}
        />
        <IconButton 
            icon={<CheckIconInCircle />} 
            borderRadius={'50%'}  
            {...getSubmitButtonProps()} 
        />
      </ButtonGroup>
    ) : null;
  }

const LocaleItem = ({item, handleEdit, handleDelete, handleUndoDelete}) => {

    const handleSubmit = (value) => {
        handleEdit({
            ...item,
            ...value
        });
    }

    const editableProps = item.isDeleted ? {isDisabled: true, color: '#7A8F99'} : {};

    return (
        <Grid {...GRID_PROPS} templateColumns={GRID_TEMPLATE_COLUMNS} gap={GRID_GAP} mt={'10px'}>
            <GridItem>
                <Editable 
                    defaultValue={item?.locale}
                    onSubmit={(value) => handleSubmit({locale: value})}
                    {...editableProps}
                >
                    <EditablePreview />
                    <Input borderRadius={INPUT_BORDER_RADIUS} size={'sm'} as={EditableInput} />
                    <EditableControls />
                </Editable>
            </GridItem>
            <GridItem>
                <Editable 
                    defaultValue={item?.title}
                    onSubmit={(value) => handleSubmit({title: value})}
                    {...editableProps}
                >
                    <EditablePreview />
                    <Input borderRadius={INPUT_BORDER_RADIUS} size={'sm'} as={EditableInput} />
                    <EditableControls />
                </Editable>
            </GridItem>
            <GridItem>
                <Editable 
                    defaultValue={item?.message}
                    onSubmit={(value) => handleSubmit({message: value})}
                    {...editableProps}
                    isFullWidth
                >
                    <EditablePreview />
                    <Textarea borderRadius={INPUT_BORDER_RADIUS} rows={4} size={'sm'} as={EditableTextarea} />
                    <EditableControls />
                </Editable>
            </GridItem>
            <GridItem>
                {
                    item.isDeleted ? (
                        <Button size={'sm'} variant={'outline'} onClick={() => handleUndoDelete(item.id)}>
                            <FormattedMessage 
                                id={'adminPage.reportTemplateFormModal.locale.button.undo'}
                                defaultMessage="Undo"
                            />
                        </Button>
                    ) : (
                        <Button size={'sm'} variant={'deleteOutline'} onClick={() => handleDelete(item.id)}>
                            <FormattedMessage 
                                id={'adminPage.reportTemplateFormModal.locale.button.delete'}
                                defaultMessage="Delete"
                            />
                        </Button>
                    )
                }
                
            </GridItem>
        </Grid>
    )
}

const AddNewLocaleItem = ({onSave, onCancel}) => {
    const [ item, setItem ] = React.useState({locale: '', title: '', message: ''});
    const formatMessage = useIntl().formatMessage;

    const handleCancel = () => {   
        onCancel()
    }

    const handleSave = () => {
        onSave({...item, isNew: true, id: nanoid()});
    }

    const handleEdit = (newData) => {
        console.log("newData ", newData)
        setItem((prev) => {
            return {
                ...prev,
                ...newData
            }
        })
    }

    return (
        <Grid {...GRID_PROPS} templateColumns={GRID_TEMPLATE_COLUMNS} gap={GRID_GAP}>
            <GridItem>
                <Input    
                    placeholder={formatMessage({
                        id: 'adminPage.reportTemplateFormModal.locale.table.placeholder.locale',
                        defaultMessage: 'Locale'
                    })} 
                    onBlur={(event) => handleEdit({locale: event.target.value})}
                    borderRadius={INPUT_BORDER_RADIUS}
                    size={'sm'}
                />
            </GridItem>
            <GridItem>
                <Input 
                    placeholder={formatMessage({
                        id: 'adminPage.reportTemplateFormModal.locale.table.placeholder.title',
                        defaultMessage: 'Title'
                    })}
                    onBlur={(event) => handleEdit({title: event.target.value})}
                    borderRadius={INPUT_BORDER_RADIUS}
                    size={'sm'}
                />
            </GridItem>
            <GridItem> 
                <Textarea 
                    placeholder={formatMessage({
                        id: 'adminPage.reportTemplateFormModal.locale.table.placeholder.message',
                        defaultMessage: 'Message'
                    })}
                    onBlur={(event) => handleEdit({message: event.target.value})}
                    borderRadius={INPUT_BORDER_RADIUS}
                    size={'sm'}
                    rows={4}
                />
            </GridItem>
            <GridItem />
            <GridItem />
            <GridItem />
            <GridItem>
                <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
                    <Button size={'sm'} variant={'outline'} onClick={handleCancel}>
                        <FormattedMessage 
                            id={'adminPage.reportTemplateFormModal.locale.button.cancel'}
                            defaultMessage="cancel"
                        />
                    </Button>
                    <Button size={'sm'} onClick={handleSave}>
                        <FormattedMessage 
                            id={'adminPage.reportTemplateFormModal.locale.button.save'}
                            defaultMessage="Save"
                        />
                    </Button>
                </ButtonGroup>
            </GridItem>
        </Grid>
    )
}

export const EditReportTemplateLocaleForm = ({
    inputValue, onChange 
}) => {
    const [ isAdding, setIsAdding ] = React.useState(false);
    // input value should be [{locale: 'th', title: 'title' , message: '{{teeth}} are god.'}, ... ]


    const handleAddLocale = (newData) => {
        console.log("handleAddLocale newData ", newData)
        console.log("handleAddLocale inputValue ", inputValue)
        onChange(
            [...inputValue, newData]
        )
        setIsAdding(false);
    }

    const handleDeleteLocale = (id) => {
        onChange(
            inputValue
                .filter(item => !(item.id === id && item.isNew)) // Remove the item if it's new
                .map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isDeleted: true  // Mark as deleted if not new
                        }

                    }
                    return item;
                }
        ))
    }

    const handleUndoDeleteLocale = (id) => {
        onChange(
            inputValue.map((item) => {
                if (item.id === id) {
                    return omit(item, ['isDeleted']);
                }
                return item;
            }
        ))
    }

    const handleUpdateLocale = (newData) => {
        console.log("handleUpdateLocale newData ", newData)
        onChange(
            inputValue.map((item) => {
                if (item.id === newData?.id) {
                    return {
                        ...item,
                        ...newData
                    }
                }
                return item;
            }
        ))
    }

    return (
        <Box mt={'10px'}>
            <Grid {...GRID_PROPS} templateColumns={GRID_TEMPLATE_COLUMNS} gap={GRID_GAP} >
                <GridItem>
                    <Th fontWeight={'bold'}>
                        <FormattedMessage 
                            id={'adminPage.reportTemplateFormModal.locale.tableHeader.locale'}
                            defaultMessage="Locale"
                        />
                    </Th>

                </GridItem>
                <GridItem>
                    <Th>
                        <FormattedMessage 
                            id={'adminPage.reportTemplateFormModal.locale.tableHeader.title'}
                            defaultMessage="Title"
                        />
                    </Th>
                </GridItem>
                <GridItem>
                    <Th>
                        <FormattedMessage 
                            id={'adminPage.reportTemplateFormModal.locale.tableHeader.message'}
                            defaultMessage="Message"
                        />
                    </Th>
                </GridItem>
                <GridItem>
                    <Th></Th>
                </GridItem>
            </Grid>
            {(inputValue || []).map((item) => (
                <LocaleItem
                    key={item.index}
                    item={item}
                    handleEdit={handleUpdateLocale}
                    handleDelete={handleDeleteLocale}
                    handleUndoDelete={handleUndoDeleteLocale}
                />
            ))}
            <Box mt={'10px'} />
            <>
                {isAdding ? (
                    <AddNewLocaleItem onSave={handleAddLocale} onCancel={() => setIsAdding(false)}/>
                )  : (
                    <Tr>
                        <Td>
                            <Button
                                onClick={() => setIsAdding(true)}
                                leftIcon={<PlusIcon height={8} width={8} />}
                            >
                                <FormattedMessage 
                                    id={'adminPage.reportTemplateFormModal.locale.button.addLocale'}
                                    defaultMessage="Add Locale" 
                                />
                            </Button></Td>
                        <Td></Td>
                        <Td></Td>
                    </Tr>
                )}
            </>
        </Box>
    )
}