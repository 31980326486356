import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const NoReportsFoundIcon = ({width=112, height=94}) => {
  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 110 94" fill="none">
        <path opacity="0.25" d="M2.44256 88.2578H109.558C110.698 88.2578 111.616 89.4172 111.616 90.8526C111.616 92.2881 110.693 93.4475 109.558 93.4475H2.44256C1.30324 93.4475 0.384766 92.2881 0.384766 90.8526C0.384766 89.4172 1.30826 88.2578 2.44256 88.2578Z" fill="#D8D9D8"/>
        <path d="M90.0461 0.550781H21.9682C19.6537 0.550781 17.7773 2.4271 17.7773 4.74165V83.9366C17.7773 86.2512 19.6537 88.1275 21.9682 88.1275H90.0461C92.3606 88.1275 94.2369 86.2512 94.2369 83.9366V4.74165C94.2369 2.4271 92.3606 0.550781 90.0461 0.550781Z" fill="#AEBDC2"/>
        <path d="M88.4646 12.9504V57.2482C86.7782 56.5456 84.9312 56.1641 82.9939 56.1641H41.7677C41.7125 56.1641 41.6673 56.1641 41.6171 56.1691H41.6121C41.6121 56.1691 41.6021 56.1641 41.6021 56.1691C39.0876 56.2043 36.8039 57.183 35.0874 58.769C34.4952 59.306 33.9732 59.9234 33.5315 60.5909C32.4876 62.1568 31.8803 64.0339 31.8803 66.0566V68.6464C27.3682 65.4995 24.417 60.2697 24.417 54.3472V12.9504C24.417 10.3606 26.5149 8.2627 29.1047 8.2627H83.7869C86.3717 8.2627 88.4746 10.3606 88.4746 12.9504H88.4646Z" fill="#E3E7E8"/>
        <path d="M41.8408 63.7686V72.2908C38.1116 72.2457 34.6586 71.0511 31.8027 69.0586V66.3835C31.8027 64.3006 32.4301 62.3582 33.5142 60.7421C33.976 60.0495 34.508 59.4121 35.1253 58.86C35.8079 58.6391 41.5045 56.9477 41.8458 63.7736L41.8408 63.7686Z" fill="#9EAEB3"/>
        <path d="M41.8425 68.8109V63.7718C41.4962 56.9509 35.8047 58.6373 35.1221 58.8582C36.8938 57.2169 39.2527 56.2131 41.8425 56.173C41.8425 56.168 41.8526 56.168 41.8576 56.168H84.5844C86.587 56.168 88.4892 56.5595 90.2308 57.2872C92.3388 58.1605 94.2008 59.5006 95.6815 61.187C97.5285 63.2899 98.7932 65.9149 99.2098 68.8109H41.8375H41.8425Z" fill="#F2F2F2"/>
        <path d="M109.138 93.449H56.6337C52.2069 93.449 48.2519 91.5117 45.5467 88.43C43.2429 85.8301 41.8477 82.4072 41.8477 78.663V68.8057H99.22C99.3254 69.5083 99.3806 70.2311 99.3806 70.9538V85.0522C101.162 92.3749 109.143 93.439 109.143 93.439L109.138 93.449Z" fill="#E3E7E8"/>
        <path d="M67.6406 5.74121H45.2257C44.0698 5.74121 43.1328 6.67825 43.1328 7.83414V8.69239C43.1328 9.84828 44.0698 10.7853 45.2257 10.7853H67.6406C68.7965 10.7853 69.7336 9.84828 69.7336 8.69239V7.83414C69.7336 6.67825 68.7965 5.74121 67.6406 5.74121Z" fill="#40525A"/>
        <path d="M62.6523 4.17676H50.2202C49.8127 4.17676 49.4824 4.50708 49.4824 4.91455V6.57083C49.4824 6.9783 49.8127 7.30862 50.2202 7.30862H62.6523C63.0598 7.30862 63.3901 6.9783 63.3901 6.57083V4.91455C63.3901 4.50708 63.0598 4.17676 62.6523 4.17676Z" fill="#40525A"/>
        <path d="M43.1613 19.7725H35.432C33.378 19.7725 31.7129 21.4376 31.7129 23.4915V31.2208C31.7129 33.2748 33.378 34.9399 35.432 34.9399H43.1613C45.2153 34.9399 46.8803 33.2748 46.8803 31.2208V23.4915C46.8803 21.4376 45.2153 19.7725 43.1613 19.7725Z" fill="#FAFAFB"/>
        <path d="M63.3211 24.0635H51.3913C50.5237 24.0635 49.8203 24.5119 49.8203 25.0651V26.0762C49.8203 26.6294 50.5237 27.0778 51.3913 27.0778H63.3211C64.1888 27.0778 64.8922 26.6294 64.8922 26.0762V25.0651C64.8922 24.5119 64.1888 24.0635 63.3211 24.0635Z" fill="#FAFAFB"/>
        <path d="M76.2819 30.0918H52.8995C51.1989 30.0918 49.8203 30.5402 49.8203 31.0934V32.1046C49.8203 32.6577 51.1989 33.1062 52.8995 33.1062H76.2819C77.9825 33.1062 79.3611 32.6577 79.3611 32.1046V31.0934C79.3611 30.5402 77.9825 30.0918 76.2819 30.0918Z" fill="#FAFAFB"/>
        <path d="M74.649 41.5459H38.8595C36.2566 41.5459 34.1465 41.9943 34.1465 42.5475V43.5587C34.1465 44.1118 36.2566 44.5603 38.8595 44.5603H74.649C77.2519 44.5603 79.362 44.1118 79.362 43.5587V42.5475C79.362 41.9943 77.2519 41.5459 74.649 41.5459Z" fill="#FAFAFB"/>
        <path d="M74.649 48.1787H38.8595C36.2566 48.1787 34.1465 48.6271 34.1465 49.1803V50.1915C34.1465 50.7446 36.2566 51.1931 38.8595 51.1931H74.649C77.2519 51.1931 79.362 50.7446 79.362 50.1915V49.1803C79.362 48.6271 77.2519 48.1787 74.649 48.1787Z" fill="#FAFAFB"/>
        <path d="M86.0025 74.1016H54.0306C51.7053 74.1016 49.8203 74.55 49.8203 75.1032V76.1143C49.8203 76.6675 51.7053 77.1159 54.0306 77.1159H86.0025C88.3278 77.1159 90.2129 76.6675 90.2129 76.1143V75.1032C90.2129 74.55 88.3278 74.1016 86.0025 74.1016Z" fill="#FAFAFB"/>
        <path d="M90.0963 81.9385H57.17C54.7753 81.9385 52.834 82.3869 52.834 82.9401V83.9512C52.834 84.5044 54.7753 84.9528 57.17 84.9528H90.0963C92.491 84.9528 94.4323 84.5044 94.4323 83.9512V82.9401C94.4323 82.3869 92.491 81.9385 90.0963 81.9385Z" fill="#FAFAFB"/>
    </Icon>
  )
}
