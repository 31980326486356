import React from 'react';
import { FormErrorMessage } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { hasErrorMessage } from 'src/_libs/forms';

export const FormattedFormErrorMessage = ({ error }) => {
    const intl = useIntl();
    const errorMessage = hasErrorMessage(error)
    if (errorMessage) {
        return (
            <FormErrorMessage>
                {intl.formatMessage(errorMessage)}
            </FormErrorMessage>
        )
    }
    return (
        <FormErrorMessage>
            {error  ||  ""}
        </FormErrorMessage>
    )
}