import React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useSelector } from 'react-redux';
import { cloneDeep } from "lodash";
import {
    Box, SimpleGrid, Button, 
    // eslint-disable-next-line no-unused-vars
} from '@chakra-ui/react';
import moment from 'moment';
import { upperFirst } from 'lodash';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required, mustBePhoneNumber, convertToAPIValues} from 'src/_libs/forms';
import { useSelectOptions } from 'src/App/api/getSettings';

import { getIntlMessages } from 'src/App/_redux/selectors';

import { services } from './_redux/services';
import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { useAppConfig } from 'src/App/state/appConfig';
import { ClinicPatientSearch } from './ClinicPatientSearch';

import { localeFormatDate } from 'src/_helpers';

// Edit user is much different from create so new form is made
export const PatientForm = ({
    onClose,
    onCreated,
    initValues={},
    onSuccess=null
}) => {
    const clinic = useAppConfig(state => state.clinic?.id);
    const intlLocale = useIntl().locale;
    const intlMessages = useSelector(getIntlMessages);
    const { data : gendersData, isLoading : gendersIsLoading, isFetching : gendersIsFetching } = useSelectOptions({'option': 'genders'});
    const { data: relationshipsData, isLoading: relationshipsIsLoading, isFetching: relationshipsIsFetching } = useSelectOptions({'option': 'relationships'});
    const { formatMessage } = useIntl();
    const defaultData = React.useMemo(() => [], []);
    
    const colSpacing = 4;
    const rowSpacing = 4;



    const datePlaceholder = moment().locale("ko").localeData().longDateFormat('L').replace(/\W$/, "").replace(/\W/g, "/");
    //const dateOnlyNumbs = datePlaceholder.replace(/\W/g, "");
    let pattern = /\W/g;
    var match;
    const dateIndexes = [];
    while ((match = pattern.exec(datePlaceholder)) != null) {
        dateIndexes.push(match.index);
    }

    const parseDate = (value) => {
      // parse to API format, which is YYYY-MM-DD
      let apiDate = localeFormatDate(value, intlLocale, "YYYY-MM-DD");
      if (apiDate){
        return apiDate;
      } return value;
    }

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        // when there is no parent, then the relationship is SELF
        if (!convertedValues.parent){
            convertedValues.relationship = "SELF"
        }

        try{
          let resp = await services.createPatient(convertedValues, clinic);
          onCreated(resp);
          onSuccess && onSuccess(resp);
          return null;
        } catch(err){
          return {
            ...err,
            [FORM_ERROR]: upperFirst(intlMessages['form.error.general.error'])
          }
        }
    }
    

    // name, birthday, gender, parent/guardian, 
    // external_id
        return (
            <>
                {/*<SimpleGrid columns={[1]} spacing={colSpacing}  mt={rowSpacing}>
                    <VStack>
                        <FormControl id={'email'}>
                            <FormLabel>
                                <FormattedMessage
                                    id="editPatientForm.field.ploveruser.label"
                                    defaultMessage="Original Name"
                                />
                            </FormLabel>
                            <Input type={"text"} isDisabled={true} value={original_user_name}/>
                            <FormErrorMessage>{''}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </SimpleGrid>*/}
                <Form
                    onSubmit={handleSubmit}
                    initialValues={initValues}
                    mutators={{ 
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (values.date_of_birth){
                          parseDate(values.date_of_birth);
                        }
                        if (values?.parent?.value && !values.relationship){
                            errors.relationship =  formatMessage({id: 'editPatientForm.field.relationship.required', defaultMessage: 'required'})
                        }
                        return errors ;
                    }}
                    render = {
                        ({
                            handleSubmit,
                            values,
                            submitting,
                        }) => {
                            return (
                                <Box as={'form'} onSubmit={handleSubmit}>
                                  <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                      <Field name="parent">
                                          {({input, meta}) => (
                                              <ClinicPatientSearch  
                                                  input={input}
                                                  meta={meta}
                                                  isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                  error={getFinalFormMetaError(meta)}
                                                  label={formatMessage({id: 'editPatientForm.field.accountrole.main_dentist.label', defaultMessage: 'Parent/Guardian'})}
                                                  placeholder={formatMessage({id: 'editPatientForm.field.accountrole.main_dentist.placeholder', defaultMessage: 'Search for a Patient'})}
                                                  onSelect={(val) => {
                                                      input.onChange(val);
                                                  }}
                                              />
                                          )}
                                      </Field>
                                      <Field name="relationship">
                                          {({input, meta}) => (
                                              <SelectOptionField 
                                                  isDisabled={!values?.parent?.value ? true : false}
                                                  isLoading={relationshipsIsLoading || relationshipsIsFetching}
                                                  label={formatMessage({id: 'editPatientForm.field.country.label', defaultMessage: 'Relationship'})}
                                                  placeholder={formatMessage({id: 'editPatientForm.field.country.label', defaultMessage: 'Relationship'})}
                                                  input={input}
                                                  selectOptions={relationshipsData?.options}
                                                  isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                  error={!(!values?.parent?.value ? true : false) && meta?.error}
                                              />
                                          )}
                                      </Field>
                                  </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="first_name" validate={required}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.first_name.label', defaultMessage: 'First Name'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.first_name.placeholder', defaultMessage: 'First Name'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="last_name" >
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.last_name.label', defaultMessage: 'Last Name'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.first_name.placeholder', defaultMessage: 'Last Name'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="external_id">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.external_id.label', defaultMessage: 'External ID'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.external_id.placeholder', defaultMessage: 'External ID'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="date_of_birth">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.date_of_birth.label', defaultMessage: 'Date of Birth'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.date_of_birth.placeholder', defaultMessage: 'Date of Birth'})}
                                                    {...input}
                                                    type="date"
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="gender">
                                            {({input, meta}) => (
                                                <SelectOptionField 
                                                    isLoading={gendersIsFetching || gendersIsLoading}
                                                    isMultiple={false}
                                                    label={formatMessage({id: 'editPatientForm.field.gender.label', defaultMessage: 'Gender'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.gender.placeholder', defaultMessage: 'Gender'})}
                                                    input={input}
                                                    selectOptions={gendersData?.options || defaultData}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="phonenumber" validate={mustBePhoneNumber}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.phonenumber.label', defaultMessage: 'Phone Number'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.phonenumber.placeholder', defaultMessage: 'Phone Number'})}
                                                    {...input}
                                                    type={'phonenumber'}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="email">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.email.label', defaultMessage: 'Email Address'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.email.placeholder', defaultMessage: 'Email Address'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>

                                    <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                        <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.cancel.button.label"
                                                defaultMessage="Cancel"
                                            />
                                        </Button>
                                        <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.submit.button.label"
                                                defaultMessage="Submit"
                                            />
                                        </Button>
                                    </SimpleGrid>
                                </Box>
                            )
                        }
                    }
                />
            </>
        )
}