import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';
import { PAGE_SIZE } from 'src/_config';

import { services  } from '../_redux/services';

export const QUERY_KEY_NAME = 'PatientList';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    },
    refetchOnMount: false
}

export const queryRequest = async (queryParams) => {
    console.log("queryParams", queryParams)
    // filter through the input params and remove any empty values
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    // format the params object into a URLSearchParams object
    const searchParams = new URLSearchParams(paramsObj)
    return services.searchPatients(searchParams)
}

export const usePatientList = ({ q, sort_by="name", sort_direction="asc", offset=0, pg_sz=PAGE_SIZE , clinic=null, config={} }) => {

    return useQuery({
        ...newQueryConfig,
        ...config,
        queryKey: [QUERY_KEY_NAME, {q, sort_by, sort_direction, offset, pg_sz, clinic }],
        queryFn: () => queryRequest({q, offset, pg_sz, sort_by, sort_direction, clinic}),
      });
}