import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const CheckIconInCircle = ({width=11, height=7, color="#405159",  ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 11 7" fill="none" color={color} {...props}>
        <path d="M1.6499 4.025L4.1249 5.6L10.1749 1.75" stroke="currentColor"/>
    </Icon>
  )
}