import { API_URLS } from '../../_config';

import { authHeader, handleResponse } from '../../_helpers';

export const services = {
  fetchPatientNotes,
  createPatientNote,
  updateNote,
};

function fetchPatientNotes(uid, query, clinic) {
  const requestUrl = API_URLS.getDentistPatientNotes;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patient_uid}', uid) + '?' + new URLSearchParams({...query, clinic});
  return fetch(url, requestOptions).then(handleResponse);
}

function createPatientNote(uid, text, clinic) {
  const requestUrl = API_URLS.createDentistPatientNotes;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ text: text }),
  };
  let url = requestUrl.url.replace('{patient_uid}', uid);
  if (clinic){
    url += '?clinic=' + clinic
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function updateNote(uid, data, clinic) {
  const requestUrl = API_URLS.updateDentistPatientNotes;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{note_uid}', uid);
  if (clinic){
    url += '?clinic=' + clinic
  }
  return fetch(url, requestOptions).then(handleResponse);
}
