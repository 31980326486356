import { create } from 'zustand';
import { storage } from 'src/_helpers/storage';

export const useAppConfig = create((set) => ({
    clinic: storage.getCurrentClinic(),
    setConfig: (config, value) => 
        set((state) => {
            if (config === 'clinic'){
                storage.setCurrentClinic(value);
            }
            return {
                ...state,
                [config]: value
            }
        }),

}));