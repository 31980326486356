import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { 
  FormControl, 
  FormLabel, 
  FormErrorMessage 
} from '@chakra-ui/react';
import { SelectFieldFromDefault, SelectFieldFromDefaultNoInput } from './SelectComponents';

export const SelectOptionFieldNoInput = ({name, value, placeholder, isInvalid, selectOptions, onChange, isMultiple=false, label="", error="", isLoading=false, isDisabled=false}) => {

  if (isLoading){
    return <Skeleton height={'45px'} w={'full'} />
  }

  return (
    <FormControl id={name} isInvalid={isInvalid} >
      {(label != null) && (
      <FormLabel align="center" pb={1}>
        {label}
      </FormLabel>)}
      <SelectFieldFromDefaultNoInput 
        value={value}
        name={name}
        placeholder={placeholder}
        isDisabled={isDisabled}
        multi={isMultiple}
        isError={isInvalid}
        options={selectOptions}
        onChange={onChange}
      />
      <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
    </FormControl>
  )
}


export const SelectOptionField = ({
  input, placeholder, isInvalid, selectOptions, isMultiple=false, label="", error="", 
  isLoading=false, isDisabled=false, allowUnfoundOption=false
}) => {

    return (
      <FormControl id={input.name} isInvalid={isInvalid} >
        <FormLabel align="center">
          {label}
        </FormLabel>
        <SelectFieldFromDefault 
          input={input}
          name={input.name}
          placeholder={placeholder}
          isDisabled={isDisabled}
          multi={isMultiple}
          isError={isInvalid}
          isLoading={isLoading}
          allowUnfoundOption={allowUnfoundOption}
          options={selectOptions}
        />
        <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
      </FormControl>
    )
  }
  