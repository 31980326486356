import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const NoPendingReportIcon = ({width=110, height=94}) => {
  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 110 94" fill="none">
        <rect x="8.57812" width="77" height="89" rx="5" fill="#BECACE"/>
        <path d="M15.5781 14C15.5781 11.2386 17.8167 9 20.5781 9H73.5781C76.3396 9 78.5781 11.2386 78.5781 14V77C78.5781 79.7614 76.3396 82 73.5781 82H35.5781C24.5324 82 15.5781 73.0457 15.5781 62V14Z" fill="#E9ECED"/>
        <rect x="23.5781" y="20" width="15" height="15" rx="3" fill="#FBFBFC"/>
        <rect x="43.5781" y="31" width="26" height="3" rx="1.5" fill="#FBFBFC"/>
        <rect x="43.5781" y="24" width="15" height="3" rx="1.5" fill="#FBFBFC"/>
        <rect x="33.5781" y="7" width="27" height="5" rx="2" fill="#66757B"/>
        <path d="M64.2574 61.75L105.398 41.6327L109.421 49.8608L68.2808 69.9781L58.041 69.8875L64.2574 61.75Z" fill="#66757B"/>
        <rect x="40.5781" y="5" width="14" height="3" rx="1" fill="#66757B"/>
        <path d="M33.5781 64H15.5781C15.5781 73.9411 23.637 82 33.5781 82V64Z" fill="#B1BEC2"/>
        <rect x="0.578125" y="89" width="94" height="5" rx="2.5" fill="#F7F7F7"/>
    </Icon>
  )
}