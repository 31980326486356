import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const NoPatientsFoundIcon = ({width=113, height=68, color='#C7D3D9'}) => {
  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 113 68" fill="none" color={color}>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.1743 11.2456C15.1743 5.03482 20.1857 0 26.3675 0C32.5494 0 37.5608 5.03482 37.5608 11.2456C37.5608 17.4592 32.5494 22.494 26.3675 22.494C20.1857 22.494 15.1743 17.4592 15.1743 11.2456ZM34.7199 11.2455C34.7199 6.61888 30.9727 2.85417 26.3676 2.85417C21.7624 2.85417 18.0152 6.61888 18.0152 11.2455C18.0152 15.8751 21.7624 19.6398 26.3676 19.6398C30.9727 19.6398 34.7199 15.8751 34.7199 11.2455Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.3076 22.5789C45.3076 16.3682 50.319 11.3333 56.5009 11.3333C62.6827 11.3333 67.6941 16.3682 67.6941 22.5789C67.6941 28.7925 62.6827 33.8274 56.5009 33.8274C50.319 33.8274 45.3076 28.7925 45.3076 22.5789ZM64.8532 22.5789C64.8532 17.9522 61.106 14.1875 56.5009 14.1875C51.8957 14.1875 48.1486 17.9522 48.1486 22.5789C48.1486 27.2084 51.8957 30.9731 56.5009 30.9731C61.106 30.9731 64.8532 27.2084 64.8532 22.5789Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M52.6502 41.8069L41.3696 30.4736H11.3637L7.22027 34.6371C4.92652 36.9422 2.87213 39.0067 0 41.8904V56.6667H30.1333V68H82.8667V56.6667H113V41.8904L101.636 30.4736H71.6304L67.4735 34.6506C65.2074 36.9279 63.173 38.9723 60.3498 41.8069H52.6502ZM48.6331 41.8069H41.4971L37.3674 45.9566C35.0681 48.2673 33.0112 50.3343 30.1333 53.2238V53.8125H2.8409V43.0721L12.5427 33.3278H40.1934L48.6331 41.8069ZM64.3672 41.8069H71.5029L82.8667 53.2238V53.8125H110.159V43.0721L100.46 33.3278H72.8093L64.3672 41.8069ZM70.3268 44.6612L80.0257 54.4054V65.1458H32.9742V54.4054L42.676 44.6612H70.3268Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M86.6342 0C80.4523 0 75.4409 5.03482 75.4409 11.2456C75.4409 17.4592 80.4523 22.494 86.6342 22.494C92.8161 22.494 97.8275 17.4592 97.8275 11.2456C97.8275 5.03482 92.8161 0 86.6342 0ZM86.6342 2.85417C91.2394 2.85417 94.9866 6.61888 94.9866 11.2455C94.9866 15.8751 91.2394 19.6398 86.6342 19.6398C82.0291 19.6398 78.2819 15.8751 78.2819 11.2455C78.2819 6.61888 82.0291 2.85417 86.6342 2.85417Z" fill="currentColor"/>
    </Icon>
  )
}