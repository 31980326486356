import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const CloseIconInCircle = ({width=8, height=8, color="#405159",  ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 8 8" fill="none" color={color} {...props}>
        <path d="M1.6001 1.59998L6.4001 6.39998" stroke="currentColor"/>
        <path d="M6.3999 1.59998L1.5999 6.39998" stroke="currentColor"/>
    </Icon>
  )
}