import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const CaretUpIcon = ({width=11, height=7, color="#405159",  ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 11 7" fill="none" color={color} {...props}>
        <path 
            d="M9.96552 6L5.48276 1L1 6" 
            stroke="currentColor" 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </Icon>
  )
}
