import React from 'react';
import { Input, Textarea, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { PhoneInput } from './PhoneInput';
import { FormattedFormErrorMessage } from './FormattedErrorMessage';

export const InputComponent = ({ label, placeholder, name, type="text", value, isInvalid, error, onChange, isDisabled=false }) => {
    if (type == "textarea"){
        return (
            <VStack>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <Textarea placeholder={placeholder} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled} />
                    <FormattedFormErrorMessage error={error} />
                </FormControl>
            </VStack>
        )
    } else if (type == "phonenumber"){ 
        return (
            <VStack>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <PhoneInput 
                        placeholder={placeholder} 
                        value={value} 
                        onChange={onChange} 
                        isInvalid={isInvalid} 
                        isDisabled={isDisabled} />
                    <FormattedFormErrorMessage error={error} />
                </FormControl>
            </VStack>
        )
    } else {
        return (
            <VStack>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <Input placeholder={placeholder} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled} />
                    <FormattedFormErrorMessage error={error} />
                </FormControl>
            </VStack>
        )
    }
}