import React from 'react';
import {
    Flex, Menu, MenuButton, Heading, MenuList, MenuItem,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { SelectChevronDown } from 'src/_images/icons/SelectChevronDown';
import { useHistory } from 'react-router';

export const HeaderMenu = ({page}) => {
    const history = useHistory();
    const { formatMessage } = useIntl(); 
    const baseAdminPath = "/adm/collections"
    const adminPages = [
        {
            key: "dentists",
            title: formatMessage({id: "admin.header.menuitem.dentists", defaultMessage: "Dentists"}),
        },
        {
            key: "clinics",
            title: formatMessage({id: "admin.header.menuitem.clinics", defaultMessage: "Clinics"}),
        },
        {
            key: "userRequests",
            title: formatMessage({id: "admin.header.menuitem.userRequests", defaultMessage: "User Requests"}),
        },
        {
            key: "patientUsers",
            title: formatMessage({id: "admin.header.menuitem.patientUsers", defaultMessage: "Patient Users"}),
        },
        {
            key: "reportTemplates",
            title: formatMessage({id: "admin.header.menuitem.reportTemplates", defaultMessage: "Report Templates"}),
        }
    ]

    return (
        <Menu>
            <MenuButton>
                <Flex align={'center'}>
                    <Heading as={'h1'} mr={'5px'} grow={1} whiteSpace={'nowrap'}>
                        {adminPages.filter((item) => item.key == page)[0]?.title || "----"}
                    </Heading>
                    <SelectChevronDown />
                </Flex>
            </MenuButton>
            <MenuList>
                {
                    adminPages.map((item) => (
                        <MenuItem key={item.key} isDisabled={page == item.key} onClick={() => history.push(`${baseAdminPath}/${item.key}`)}>
                            {item.title}
                        </MenuItem>
                    ))
                }
            </MenuList>
      </Menu>
    )
}