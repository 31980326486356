import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Button } from '@chakra-ui/react';

import { formatName } from '../_helpers/utils';
import { localeFormatDate } from '../_helpers/date';

import { Form, Field } from 'react-final-form';
import { required} from '../_components/FormElements';

// components
import { LoadingEllipsis } from '../_components/Loaders';

// selectors
import { getIntlMessages, getIntlLocale, getProfileFirstName, getProfileLastName } from '../App/_redux/selectors';

// styles
import {
  StyledInputLayout,
  StyledTextArea,
  StyledButton,
  ErrorLabel,
} from '../_css/styles'
import { useIntl } from 'react-intl';
import { useAppConfig } from 'src/App/state/appConfig';
import { useCreateNote } from 'src/Patients/api/savePatientNote';
import { FORM_ERROR } from 'final-form';


const StyledPatientNotesContainer = styled.div`
  padding: 0 20px;
`;

const StyledPatientNotes = styled.div`
  display:flex;
  flex-direction: column;
  width: 500px;
  padding-bottom: 50px;

  svg{
    text-align:center;
    margin: 20px auto;
  }

  .header {
    text-size: 16px;
    padding: 30px 0px 30px;
    font-family: Proxima Nova Semibold;
    font-size: 18px;
    text-align:center;
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 30px;
    text-transform: capitalize;
  }

  .newPatientNote{
    display:flex;
    flex-direction: column;
    margin: 0 10px;
    border: 1px solid #C7D3D9;
    border-radius: 4px;
    overflow: hidden;
    height: 47px;

    ${StyledTextArea}{
      outline: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: none;
      height: 180px;
    }

    .details{
      color: #7A8F99;
      display:flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      padding: 5px 15px 5px;

      .info{
        display:flex;
        justify-content: flex-start;

        > span{
          margin-right: 10px;
        }
      }

      ${StyledButton}{
        margin:0;
        min-width: unset;
        width: 100px;
        text-transform: capitalize;
      }
    }
  }

  .patientNote{
    display:flex;
    flex-direction: column;
    margin: 0 10px;
    margin-top: 20px;
    border: 1px solid #E6EDF0;
    border-radius: 4px;
    padding: 10px 15px 5px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

    .content{
      font-size: 14px;
      font-family: Proxima Nova;
      white-space: break-spaces;
    }

    .details{
      color: #7A8F99;
      display:flex;
      justify-content: flex-start;
      font-size: 12px;
      margin-top: 10px;

      > span{
        margin-right: 10px;
      }
    }
  }
`;


//const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const AddNewPatientNote = ({patientUid, focused=false}) => {
  const clinic = useAppConfig(state => state.clinic?.id);
  const intlMessages = useSelector(getIntlMessages);
  const createNoteMutation = useCreateNote({ patientUid, clinic })
  const { formatMessage } = useIntl()
  const locale = useSelector(getIntlLocale);
  const firstName = useSelector(getProfileFirstName);
  const lastName = useSelector(getProfileLastName);
  const [ boxSize, setBoxSize ] = useState(focused ? 8 : 1)
  const textBox = useRef(null);
  //const formRef = useRef(null);

  useEffect(()=>{
    if (textBox.current != null){
      if (focused){
        textBox.current.focus();
      }
      textBox.current.addEventListener("focus", function(){
        setBoxSize(8);
      });
      //textBox.current.addEventListener("blur", function(){
      // setBoxSize(1);
      //});
    }
  }, [textBox.current])

  const handleSubmit = async (values) => {
    // pass
    try {
      await createNoteMutation.mutateAsync(values.text)
      textBox.current.blur();
      setBoxSize(1);
      return null;
    } catch (err) {
      console.error("error creating note ", err)
      return {[FORM_ERROR]: intlMessages['requestDetailPage.report.form.error.submit']}
    }
  }

  return (
      <motion.div className={'newPatientNote'}
        animate={ (boxSize == 1) ?  {height: 47} :  {height: 250} }
        transition= {{type: 'tween'}}
        >
          <Form
            onSubmit={handleSubmit}
            render = {({
              submitting,
              handleSubmit,
              form
            }) => {
              return(
                <form onSubmit={async (event) => {
                    const error = await handleSubmit(event)
                    if (error) { return error }
                    form.reset();
                  }}>
                  <div className={'content'}>
                      <Field name="text" parse={v => v} validate={required}>
                          {({ input, meta}) => (
                            <StyledInputLayout>
                                <StyledTextArea
                                  {...input}
                                  ref={textBox}
                                  columns={20}
                                  disabled={submitting}
                                  type="text"
                                  placeholder={formatMessage({id: 'patientSection.notes.new.placeholder', defaultMessage: 'Create New Note'})}
                                />
                              <ErrorLabel
                                isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                                >
                                  {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && 'error' || meta.submitError}
                              </ErrorLabel>
                            </StyledInputLayout>
                          )}
                      </Field>
                    </div>
                    <div
                      className={'details'}
                      >
                      <div className={'info'}>
                        <span>{localeFormatDate(new Date(), locale, "MMM DD" )}</span>
                        <span>{formatName(intlMessages['format.fullName'], firstName, lastName)}</span>
                      </div>
                      <Button isDisabled={submitting} size={'md'} type="submit">
                        {intlMessages['requestDetailPage.report.form.button.submit']}
                      </Button>
                    </div>
                  </form>
              )
            }}
          />
      </motion.div>
  )
}

export const PatientNote = ({note}) => {
  const intlMessages = useSelector(getIntlMessages);
  const locale = useSelector(getIntlLocale);

  return (
    <motion.div
      className={'patientNote'}
      animate={{opacity: [0,1], y: ["20%", "0%"]}}
      transition= {{type: 'tween', delay: 0.1}}
      >
      <div className={'content'}>
        {note.text}
      </div>
      <div className={'details'}>
        <span>{localeFormatDate(note.created_at, locale, "MMM DD" )}</span>
        <span>{formatName(intlMessages['format.fullName'], note.created_by.first_name, note.created_by.last_name)}</span>
      </div>
    </motion.div>
  )
}

export const PatientNotes = ({patientUid, notes, focused, loading}) => {

  return (
    <StyledPatientNotes>
      <StyledPatientNotesContainer>
        <AddNewPatientNote patientUid={patientUid} focused={focused}/>
        { loading
            ? <LoadingEllipsis />
            : notes.map((note) => (
                <PatientNote key={note.id} note={note} />
              ))
        }
      </StyledPatientNotesContainer>
    </StyledPatientNotes>
  )
}
