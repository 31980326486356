import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const PlusIcon = ({width=9, height=9, color='#405159', ...props}) => {
  return (
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 12 12" fill="none" color={color} {...props} >
      <path d="M6 0V12" stroke="currentColor" strokeWidth="2" />
      <path d="M12 6H0" stroke="currentColor" strokeWidth="2" />
    </Icon>
  );
};
