import React from 'react';
import { Box } from '@chakra-ui/react';

export function getCategoryColor(category = ''){ 
    const cat = category.toUpperCase();
    if (cat === 'CARIES' || cat === 'TOOTH') {
        return '#DC4E41';
    } else if (cat === 'GUMS') {
        return '#FFA500';
    } else {
        return '#367FFF'
    }
    
    /*else if (cat === 'COLOR') {
        return '#367FFF';
    } else if (cat === 'HABITS') {
        return '#DAFF00';
    } else if (cat === 'ORTHO') {
        return '#00574F';
    } else if (cat === 'MULTIPLE') {
        return '#405159'
    } else {
        return '#efefef';
    }  */ 
}

export const CategorytDot = ({category, color=null, width=['12px'], height=['12px'], marginRight=['14px']}) => {
    return (
        <Box
            w={width}
            h={height}
            borderRadius={['50%']}
            bg={color ? color : getCategoryColor(category)}
            mr={marginRight}
        />
    )
}