import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import { Box, Flex, SimpleGrid, Button, Spinner } from '@chakra-ui/react';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { useSelectOptions } from '../api/getSelectOptions';
import { DentistSearch } from './DentistSearch';
import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { createClinic, editClinic } from '../api/services';
import { convertToAPIValues } from 'src/_libs/forms';
import { useClinicDetails, updateClinicDetails } from '../api/getClinicDetails';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';

export const EditClinicForm = ({
    onSuccess,
    onClose,
    clinicId
}) => {
    const { data, isLoading, isFetching, isError, error } = useClinicDetails({clinicId});

    const handleSuccess = (data) => {
        updateClinicDetails(data?.id, data)
        onSuccess && onSuccess();
    }

    if (isError){
        return (
            <Box>
                <ErrorNotFound error={error} size={"md"} buttonType={'refresh'} />
            </Box>
        )
    }
    if (isLoading || isFetching){
        return ( 
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        )
    }else {
        return <ClinicForm onSuccess={handleSuccess} onClose={onClose} initValues={data} />
    }
}

const formatDentistValue = (values) => {
    if (!values.dentist){
        return values;
    }
    return {
        ...values,
        dentist: {
            ...values.dentist,
            data: values.dentist
        }
    }
}

export const ClinicForm = ({
    onSuccess,
    onClose,
    initValues = {},
  }) => {
    const { data, isLoading, isFetching } = useSelectOptions({'option': 'countries'});
    const { formatMessage } = useIntl();
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        if (initValues.id){ // editClinic if id exists
            try{
                const result = await editClinic(initValues.id, convertedValues);
                onSuccess && onSuccess(result);
                onClose();
                return null;
            } catch (err) {
                return {
                  ...err,
                };
            }
        } else { // createClinic if id does not exist
            try{
                const result = await createClinic(convertedValues);
                onSuccess && onSuccess(result);
                onClose();
                return null;
            } catch (err) {
                return {
                  ...err,
                };
            }

        }
    }

    const isEditForm = initValues.id ? true : false;

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={formatDentistValue(initValues)} // format dentist value to add data field for select option componentf
            render = {
                ({
                    handleSubmit,
                    submitting
                }) => {
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing} isDisabled={isEditForm}>
                                <Field name="country" validate={required} >
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isDisabled={isEditForm}
                                            isLoading={isFetching || isLoading}
                                            label={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                            input={input}
                                            selectOptions={data?.options}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]}  spacing={colSpacing} mt={rowSpacing}>
                                <Field name="name" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.name.label', defaultMessage: 'Name'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.name.label', defaultMessage: 'Name'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                                <Field name="branch">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.branch.label', defaultMessage: 'Branch'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.branch.label', defaultMessage: 'Branch'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]}  spacing={colSpacing} mt={rowSpacing}>
                                <Field name="email_address">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.contact_email.label', defaultMessage: 'Contact Email'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.contact_email.label', defaultMessage: 'Contact Email'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                                <Field name="phone_number">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.contact_phone_number.label', defaultMessage: 'Contact Phone No.'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.contact_phone_number.label', defaultMessage: 'Contact Phone No.'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="address" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.address.label', defaultMessage: 'Address'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.address.label', defaultMessage: 'Address'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="geopoint" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.geopoint.label', defaultMessage: 'Geopoint'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.geopoint.label', defaultMessage: 'Geopoint'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            {isEditForm && // only show dentist field if it is an edit form
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="dentist">
                                        {({input, meta}) => (
                                            <DentistSearch  
                                                input={input}
                                                meta={meta}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                label={formatMessage({id: 'adminPage.form.field.accountrole.main_dentist.label', defaultMessage: 'Main Dentist'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.accountrole.main_dentist.placeholder', defaultMessage: 'Search for a dentist'})}
                                                onSelect={(val) => {
                                                    input.onChange(val);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                            }
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.cancel.button.label"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.submit.button.label"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </SimpleGrid>
                        </Box>
                    )
                }
            }
        />
    )
}