import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { WrappedPage as BaseWrappedPage } from '../PageComponents/WrappedPage';
import {
  StyledRecordUploadMedia,
  StyledRecordUploadIcon,
  StyledLoadingMedia,
} from '../_components/RecordUploadMedia/styles';
import { ScrollView } from '../_css/styles';

export const StyledReportCategory = styled(motion.div)`
    border-radius: ${({ selected }) => (selected ? '20px' : '0')};
    background: ${({ selected }) => (selected ? 'white' : 'none')};
    padding-right:${({ selected }) => (selected ? '7px' : '0')};

    display:flex;
    margin: 0 3px;
    &:hover{
      cursor:pointer;
    }
    .reportLabel{
      overflow:hidden;
      width: fit-content;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      span{
        white-space: nowrap;
      }
    }
    .reportLabel::before {
      flex-shrink:0;
      content:'';
      display:inline-block;
      width:10px;height:10px;
      border-radius:10px;
      vertical-align:middle;
      margin-left:7px;
      margin-right:12px;}
    .reportLabel.COLOR::before {background-color:#367FFF}
    .reportLabel.ORTHO::before {background-color:#00574F}
    .reportLabel.GUMS::before {background-color:#daff00}
    .reportLabel.HABITS::before {background-color:#daff00}
    .reportLabel.CARIES::before {background-color:#DC4E41}
    .reportLabel.OTHERS::before {background-color:#367FFF}
    .reportLabel.CALCULUS::before {background-color:#ffa500}
    .reportLabel.ALL::before {background-color:#000000}
    .reportLabel:not(:last-child) {margin-bottom:5px;}
  }
`;

export const StyledReportCategories = styled.div`
  width: 400px;
  .reportCategoriesContainer {
    display: flex;
    justify-content: center;
    ${StyledReportCategory} {
    }
  }

  .currentCategoryRegions {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
  }
`;

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-bottom: 30px;
  align-items: flex-end;
  text-align: left;
  margin-right: ${(props) => (props.senderMe ? '20px' : '0px')};

  > .messageContent {
    display: flex;
    justify-content: ${(props) => (props.senderMe ? 'flex-end' : 'flex-start')};
    align-items: flex-end;
    width: 100%;

    > .messageTime {
      order: ${(props) => (props.senderMe ? '1' : '2')};
      margin: 0px 10px;
    }

    > .messageText {
      max-width: 60%;
      align-items: right;
      border-radius: 30px;
      background-color: ${(props) => (props.senderMe ? '#273238' : '#F0F3F5')};
      color: ${(props) => (props.senderMe ? '#FFFFFF' : '#12171A')};
      order: ${(props) => (props.senderMe ? '2' : '1')};
      padding: 15px 25px;
    }
  }
`;

export const ModalPrevIcon = styled.div`
  position: absolute;
  left: 0;
  margin-left: 5px;
  z-index: 3;
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    width: 30px;
    height: 50px;
    fill: #c4c4c4;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  .disabled {
    svg {
      fill: #ededed;
    }
    &:hover {
      cursor: default;
      opacity: 1;
    }
  }
`;

export const ModalNextIcon = styled.div`
  position: absolute;
  right: 0;
  margin-right: 5px;
  z-index: 3;
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    width: 30px;
    height: 50px;
    fill: #c4c4c4;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  .disabled {
    svg {
      fill: #ededed;
    }
    &:hover {
      cursor: default;
      opacity: 1;
    }
  }
`;

export const StyledPatientRecords = styled.div`
    overflow:hidden;
    background: #F7F9FA;
    border: 1px solid #E6EDF0;
    box-sizing: border-box;
    border-radius: 4px;

    .sectionTitle.recordTitle{
      padding: 25px 15px 10px;
    }

    .loadingBoxes{
      padding: 25px;

    }

    .uploadBoxes{
        padding:0 15px;
      display:grid;
      grid-auto-flow: column;
      overflow-x:scroll;
      padding-top: 15px;
      padding-bottom: 25px;
    }

    ${StyledRecordUploadMedia} {
      float:left;margin-left:0;
      &:hover{ cursor:pointer;}
    }
    ${StyledRecordUploadIcon}{
      float:left;margin-left:0;
      &:hover{ cursor:pointer;}
    }
    ${StyledLoadingMedia}{
      float:left;margin-left:0;
    }
    ${StyledRecordUploadMedia}:not(:last-child) {margin-right:20px;}
    ${StyledLoadingMedia}:not(:last-child) {margin-right:20px;}
    ${StyledRecordUploadIcon}:not(:last-child) {margin-right:20px;}
      }
`;

export const StyledCurrentReport = styled.div`
  .videoChatSection {
    margin: 20px 5px;

    &:hover {
      transform: translateY(1px);
    }

    a,
    .aSpan {
      background: #273238;
      border: solid 2px #273238;
      box-shadow: 0px 2px 14px rgba(39, 50, 56, 0.3);
      border-radius: 28px;
      color: white;
      font-size: 16px;
      padding: 8px 10px;
      font-family: 'Proxima Nova Semibold';
      width: fit-content;
      min-width: 180px;
      margin: 20px auto;

      text-decoration: none;
      &:hover {
        text-decoration: none;
        opacity: 0.7;
        box-shadow: none;
        cursor: pointer;
      }
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
      }
    }
    .noChats {
      margin: 20px 5px;
    }
  }
`;

export const StyledReportVideoChat = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  border-right: 'light';
  filter: drop-shadow(-5px 0px 10px rgba(0, 0, 0, 0.05));
  background: #f7f9fa;

  .sectionTitle.chatTitle {
    padding: 25px 15px 10px;
  }

  .videoChatSection {
    margin: 40px auto;
    &.onlyText {
      margin: 40px 5px;
    }

    a {
      background: #273238;
      border-color: #273238;
      box-shadow: 0px 2px 14px rgba(39, 50, 56, 0.3);
      border-radius: 28px;
      color: white;
      font-size: 16px;
      padding: 8px 10px;
      font-family: 'Proxima Nova Semibold';
      width: fit-content;
      min-width: 180px;
      margin: 20px auto;

      text-decoration: none;
      &:hover {
        text-decoration: none;
        cursor: pointer;
        color: white;
        opacity: 0.7;
      }
      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
      }
    }
    .noChats {
      margin: 20px 5px;
      padding-left: 20px;
    }
  }
`;

export const StyledReportChat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  position: 'fixed';
  border-right: 'light';
  filter: drop-shadow(-5px 0px 10px rgba(0, 0, 0, 0.05));
  background: #f7f9fa;

  ${ScrollView} {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sectionTitle.chatTitle {
    padding: 25px 15px 10px;
  }

  .messageInput {
    .messageEditor {
      position: relative;
      border-radius: 20px;

      .textEditor {
        display: flex;
        align-items: center;
        padding: 30px;
        padding-bottom: 0px;
        .textAreaWrapper {
          display: flex;
          align-items: center;
          flex-grow: 1;
          .textArea {
            flex-grow: 1;
          }
        }
      }
    }
  }

  .divider {
  }

  .noMessages {
    color: #7a8f99;
    font-style: italic;
  }
`;

export const StyledReportCommentItem = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid #E6EDF0;

  .itemHeader{
    display:flex;
  }

  .category{
    font-family: Proxima Nova Bold;
    &:before {content:'';display:inline-block;width:10px;height:10px;border-radius:10px;vertical-align:middle;margin-left:7px;margin-right:12px;}
    &.COLOR::before {background-color:#367FFF}
    &.ORTHO::before {background-color:#00574F}
    &.GUMS::before {background-color:#daff00} 
    &.HABITS::before {background-color:#daff00}
    &.CARIES::before {background-color:#DC4E41}
    &.OTHERS::before {background-color:#367FFF}
    &.CALCULUS::before {background-color:#ffa500}
    &:not(:last-child) {margin-bottom:5px;}

    &:after{
      content:'|';
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  .regions{
    font-family: Proxima Nova Semibold;
    span{
      &:not(:last-child){
        :after{
          content: ", "
        }
      }
    }
`;

export const StyledReportComments = styled.div`
  padding: 0 25px;
`;

export const StyledPatientReportPreview = styled.div`
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  border: 1px solid #e6edf0;
  background: #f7f9fa;
  border-radius: 4px;
  box-sizing: border-box;
  padding-bottom: 20px;

  .sectionTitle.reportTitle {
    padding: 25px 15px 10px;
  }

  .loadingSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 70px 0;
  }

  .blankSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 70px 0;
    svg {
      height: 50px;
      width: 50px;
    }
    a {
      margin-top: 10px;
    }
  }

  .chartSection {
    display: flex;
  }
  .divider {
    background-color: #e6edf0;
    height: 2px;
    margin: 20px;
  }
  .informationSection {
    font-size: 16px;
    color: #273238;
    height: 300px;
    overflow-y: auto;
    padding: 0 20px;
  }
`;

export const StyledTask = styled.div``;

export const StyledTasksSection = styled.div`
  display: flex;
  flex-direction: column;

  .sectionTitle.tasksTitle {
    padding: 25px 15px 10px;
    box-shadow: none;
  }

  .sectionContent {
    padding: 15px;
    display: flex;
    background-color: #f7f9fa;

    ${StyledTask} {
    }
  }
`;

export const StyledDetailsSection = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;

  grid-gap: 30px;
  row-gap: 10px;

  .sectionColumn {
    margin: 0 10px;
    position: relative;
    text-align: left;
    overflow: auto;
  }

  .sectionTitle {
    font-family: Proxima Nova Semibold;
    font-size: 19px;
    color: #12171a;
    padding: 5px 0;
    background: #ffffff;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
  }

  .patientChat {
    overflow: hidden;
    height: 872px;
    ${StyledReportChat} {
      max-height: 55vh;
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  .columnTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Proxima Nova Semibold;
    font-size: 17px;
    letter-spacing: 1px;
    color: #405159;
    text-align: left;
    padding-bottom: 25px;
  }

  .patientAvatarWrapper {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .patientAvatar {
      > img {
        border-radius: 4px;
        width: 128px;
        height: 128px;
      }

      > div {
        width: 128px;
        height: 128px;
        border-radius: 4px;
        background: #405159;
        font-family: Proxima Nova Semibold;
        font-size: 40px;
        line-height: 30px;
        color: #ffffff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .patientSection {
    width: 45%;
    display: flex;
    flex-direction: column;
  }

  .patientSummaryWrapper {
    display: grid;
    grid-template-columns: auto;

    grid-gap: 20px;
    row-gap: 10px;

    .patientColumn {
      padding: 0;
      text-align: left;

      &.patientInformation {
        display: grid;
        row-gap: 10px;

        .informationRow {
          display: grid;
          grid-template-columns: 3fr 4fr;
          grid-gap: 40px;
          text-align: left;
        }
      }
    }
  }

  .patientHistory {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid #e6edf0;
    padding-left: 20px;

    .patientHistoryItem {
      width: 100%;
      font-family: Proxima Nova Semibold;
      font-size: 16px;
      color: #405159;
      display: flex;
      justify-content: space-between;
      text-align: right;

      background: #f7f9fa;
      border: 1px solid #e6edf0;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 15px 20px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .patientHistoryItemDate {
        font-size: 14px;
        text-align: right;
        color: #7a8f99;
      }
    }
    .blankPatientHistoryItem {
    }
  }

  .patientNote {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid #e6edf0;
    padding-left: 20px;

    svg {
      margin: 20px auto;
    }

    .columnContent {
      width: 100%;
      height: 128px;
      overflow-y: scroll;
    }

    .patientNoteItem {
      width: 100%;
      max-width: 455px;
      font-family: Proxima Nova;
      position: relative;
      font-size: 12px;
      color: #405159;
      display: flex;
      justify-content: space-between;
      text-align: right;

      background: #f7f9fa;
      border: 1px solid #e6edf0;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 10px 10px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        cursor: pointer;
      }

      .patientNoteContent {
        text-align: left;
        position: relative;
        overflow: hidden;
        width: 80%;
        text-overflow: ellipsis;

        > span {
          white-space: nowrap;
          width: 250px;
        }
      }

      .patientNoteItemDate {
        font-size: 12px;
        text-align: right;
        color: #7a8f99;
      }
    }
    .blankPatientHistoryItem {
    }

    .createNote {
    }
  }
`;

export const StyledUpcomingItem = styled(Link)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4fr 3fr;
  padding: 15px;
  padding-bottom: 10px;
  background: ${({ selected }) => (selected ? '#ffffff' : 'rgba(54, 127, 255, 0.08)')};
  z-index: ${({ selected }) => (selected ? 1 : 0)};
  border-radius: 4px;

  &:hover {
    text-decoration: none;
  }

  .upcomingInfo {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.944444px;
    color: #12171a;
    display: grid;
    grid-template-columns: 4fr 4fr 1fr;
  }

  .upcomingTime {
    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      vertical-align: middle;
      margin-right: 8px;
      background-color: #367fff;
      visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    }
  }
  .upcomingDate {
    font-family: Proxima Nova Semibold;
    font-size: 14px;
    letter-spacing: 0.830769px;
    color: #7a8f99;
  }
  .upcomingIcon {
    transform: ${({ selected }) => (selected ? 'rotate(180deg)' : '')};
    svg {
      width: 10px;
    }
  }
  .upcomingInfoName {
    font-family: Proxima Nova;
    font-size: 12px;
    color: #273238;
    display: flex;
    justify-content: flex-start;
    padding: 0 31px;
    visibility: ${({ selected }) => (selected ? 'hidden' : 'visible')};
  }
`;

export const StyledUpcomingSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  .prevArrow {
    cursor: pointer;
    padding-right: 10px;
    svg {
    }
  }

  .nextArrow {
    padding-left: 10px;
    &:hover {
      cursor: pointer;
    }
    svg {
    }
  }
  .upcomingWrapper {
    display: grid;
    width: inherit;
    padding-right: 30px;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .noAppointments {
    color: #7a8f99;
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
    padding-bottom: 10px;
  }
`;

export const StyledNoteSection = styled(motion.div)`
  visibility: ${(isSelected) => (isSelected ? 'visible' : 'hidden')};
  position: absolute;
  top: 60px;
  background-color: #f7f9fa;
`;

export const StyledReportForm = styled.div`
  position: relative;
  width: 250px;
  overflow-y: hidden;
  height: 440px;

  .ToothSet {
    svg {
      height: 200px;
    }
  }
`;

export const StyledPageTitle = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */
  color: #12171a;
  text-align: left;
`;

export const SubsectionTitleStyles = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 18px;
  color: #12171a;
`;

export const StyledTodoList = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  padding: 20px 0 10px;
  margin: 0 20px;
`;

export const StyledAppointmentsList = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  height: 100%;
  width: fit-content;
  padding: 30px 50px;
  .sectionTitle.appointmentListTitle {
    padding: 25px 15px 10px;
  }

  .sectionContent {
    padding: 15px;
    display: flex;
    background-color: #f7f9fa;

    ${StyledTask} {
    }
  }
`;

export const StyledRequestDetailsContainer = styled.div`
  padding: 20px 0px;
  display: -ms-grid;
  display: grid;
  grid-auto-rows: max-content;
`;

export const WrappedPage = styled(BaseWrappedPage)`
  grid-auto-rows: max-content;
`;
