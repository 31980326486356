import React, {  useState } from 'react';
import { 
  Box, 
  Text,
  Flex,
  Center,
  Button,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  FormLabel,
  Checkbox,
  Spinner,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Progress, useDisclosure

} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClinicPatientSearchNoInput } from 'src/Patients/ClinicPatientSearch'

import { useAppConfig } from 'src/App/state/appConfig';
import { useBulkReports } from 'src/Reports/api/getBulkReports';

import { useDentistSelectOptions } from 'src/SettingsPage/api/getDentistSelectOptions';
//import { convertToAPIValues } from 'src/_libs/forms';
import { localeFormatDate } from 'src/_helpers';
import { getIntlLocale } from 'src/App/_redux/selectors';
import { SelectOptionFieldNoInput } from 'src/_components/Forms/SelectOptionField';
import { DownloadIcon } from 'src/_images/icons/DownloadIcon';
import { useSearchParamsObject } from 'src/_hooks/useSearchParams';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DateRangeModal } from './DateRangeModal';
import { NoReportsFoundIcon } from 'src/_images/icons/NoReportsFound';

function formatDateToYYYYMMDD(dateString) {
    // Create a new Date object from the input date string
    const date = new Date(dateString);
  
    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Combine them into the desired format
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
  }

const DateGroup = ({date, items, selectedItems, handleDateCheckboxChange, handleCheckboxChange}) => {
  const locale = useSelector(getIntlLocale); 
  const formatMessage = useIntl().formatMessage;

  const getReportLabel = (item) => { 
    if (item.patient){
      let label = formatMessage({
          id: 'format.fullName',
          defaultMessage: '{givenName} {familyName}',
        },
        {
          givenName: item.patient.first_name,
          familyName: item.patient.last_name
        }
      );
      if (item.patient.external_id){
          label = label + " " + formatMessage({
            id: 'downloadReportPage.reportlabel.externalId',
            defaultMessage: ' (Ex ID: {externalId})',
          },
          {
            externalId: item.patient.external_id
          }
        )
      }
      return label
    } else {
      return formatMessage({
        id: 'downloadReportPage.text.unknownPatient',
        defaultMessage: 'Error - Unknown Patient'
      })
    }
    
  }

  return (
    <AccordionItem
      mt={['10px']}
      isFocusable={false}
    >
      <Box 
        p={['15px']} pt={['15px']} pb={['15px']}
        boxShadow={['0px 0px 4px 0px rgba(0, 0, 0, 0.15)']}
        borderRadius={['5px']}
        mr={'5px'}
      >
        <AccordionButton _hover={{bg: 'transparent'}} _focus={{outline: 'none'}}>
          <Flex justify={'space-between'} w={'full'}>
            <Flex textAlign='left' align={'center'}>
                <Checkbox
                  isChecked={selectedItems[date] ? selectedItems[date].every((isSelected) => isSelected) : false}
                  onChange={() => handleDateCheckboxChange(date)}
                >
                  <FormLabel ml={'5px'}  mb={'0'}>
                    {localeFormatDate(date, locale)}
                  </FormLabel>
                </Checkbox>
            </Flex>
            <AccordionIcon />
          </Flex>
        </AccordionButton>  
      </Box>
    <AccordionPanel pb={4} pr={'4px'}>
      {(items || []).map((item, index) => (
          <AccordionItem key={index}
            ml={'10px'}
            pl={'20px'}
            py={'20px '}
            mt={['10px']}
            borderRadius={['5px']}
            boxShadow={['0px 0px 4px 0px rgba(0, 0, 0, 0.15)']}
            isFocusable={false}
          >
            <Flex textAlign='left' align={'center'} onClick={() => handleCheckboxChange(date, index)}>
                <Checkbox
                  isChecked={selectedItems[date] ? selectedItems[date][index] : false}
                  onChange={() => handleCheckboxChange(date, index)}
                >
                  <FormLabel ml={'5px'} mb={'0'} textTransform={'capitalize'}>
                    {getReportLabel(item)}
                    <Text as={'span'} ml={'10px'}>{item.patient?.id ? ` ID: ${item.patient?.id}` : ''}</Text>
                  </FormLabel>
                </Checkbox>
            </Flex>
          </AccordionItem>
      ))}
    </AccordionPanel>
  </AccordionItem>
  )
}

const ItemList = ({grouped, order, selectedItems, handleCheckboxChange, selectAll, setSelectAll, setSelectedItems}) => {

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const newSelectedItems = order.reduce((acc, date) => {
            acc[date] = new Array(grouped[date].length).fill(newSelectAll);
            return acc;
        }, {});
        setSelectedItems(newSelectedItems);
    };

    const handleDateCheckboxChange = (date) => {
        const allSelected = selectedItems[date] ? selectedItems[date].every((isSelected) => isSelected) : true;
        const newSelectedItems = { ...selectedItems };
        newSelectedItems[date] = new Array(grouped[date].length).fill(!allSelected);
        setSelectedItems(newSelectedItems);
    };

    if (!order || order.length === 0){
      return (
        <Box ml={'5px'} pr={'5px'}>
          <Flex pt={'200px'} flexGrow={1} direction={'column'} align={'center'} justify={'center'}>
            <NoReportsFoundIcon />
            <Text mt={'5px'} fontSize={14} color={'none.500'}>
              <FormattedMessage 
                id={'patientDetailsPage.details.completedrequests.noTasks.description'}
                defaultMessage={'No Reports Found'}
              />
            </Text>
          </Flex>
        </Box>
      )
    }
    
    return (
        <Box ml={'5px'} pr={'5px'}>
          <Flex w={'full'} pl={'31px'} py={'5px'} justify={'space-between'}>
            <Checkbox
              isChecked={selectAll}
              onChange={handleSelectAllChange}
            >
              <FormLabel ml={'5px'} mb={0}>
                <FormattedMessage
                  id={'downloadReportPage.checkbox.selectAll'}
                  defaultMessage={'Select All'}
                />
              </FormLabel>
            </Checkbox>
            <Flex>
              <Text
                color={'#9B9B9B'}
              >
                <FormattedMessage
                  id={'downloadReportPage.text.total'}
                  defaultMessage={'{count} Items Selected'}
                  values={{count: Object.values(selectedItems).reduce((acc, arr) => acc + arr.filter((isSelected) => isSelected).length, 0)}}
                />
              </Text>
            </Flex>
          </Flex>
          <Accordion 
            defaultIndex={[0]}
            allowMultiple allowToggle
            pb={'50px'}
          >
          
            {(order || []).map((date) => (
              <DateGroup 
                key={'dategroup-'+date}
                date = {date}
                items={grouped[date]}
                selectedItems={selectedItems}
                handleDateCheckboxChange={handleDateCheckboxChange}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))}
          </Accordion>
        </Box>
    )
}

const groupByDate = (data) => {
  if (data?.reports){
      const grouped = data?.reports.reduce((acc, item) => {
            // no report so just return
            if (item?.report?.report_upload?.download_url == null){
                return acc;
            }
            const date = formatDateToYYYYMMDD(item.created_at);
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
      }, {});

      // Sort dates in descending order
      const order = Object.keys(grouped).sort((a, b) => new Date(b) - new Date(a));

      return { grouped, order };
      
  } else{
      return {}
  }
};

export const DownloadReport = () => {
    const history = useHistory(); 
    const clinic = useAppConfig(state => state.clinic?.id);
    const searchParams = useSearchParamsObject();
    const { data, isLoading, isFetching } = useBulkReports({...searchParams, clinic});
    const { isOpen: isDatePickerOpen, onOpen: onDatePickerOpen, onClose: onDatePickerClose } = useDisclosure();
    const { data: filtersData, isLoading: filtersIsLoading, isFetching: filtersIsFetching } = useDentistSelectOptions({option: 'report_bulk_filter'});
    //const [ currentFilter, setCurrentFilter ] = useState('last_week')
    const [ selectedItems, setSelectedItems ] = useState({})
    const [selectAll, setSelectAll] = useState(false);
    const [ isGenerating, setIsGenerating ] = useState(false)
    const [progress, setProgress] = useState(0); // Progress state
    //const [ isReportRendering, setReportRendering ] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [ patientValueError, setPatientValueError ] = useState(false)
    const defaultData = React.useMemo(() => [], []);
    //const previewRefs = React.useRef({});
    const { formatMessage } = useIntl();

    const { grouped, order } = groupByDate(data);

    const handleCheckboxChange = (date, index) => {
        const newSelectedItems = { ...selectedItems };
        if (newSelectedItems[date]) {
          newSelectedItems[date][index] = !newSelectedItems[date][index];
        } else {
          // create new array for date if it doesnt exists. length based on the original grouped data
          newSelectedItems[date] = new Array(grouped[date].length).fill(false);
          newSelectedItems[date][index] = true;
        }
        setSelectedItems(newSelectedItems);
    };

    const getReportLabel = (item) => { 
      if (item.patient){
        let label = formatMessage({
            id: 'format.fullName',
            defaultMessage: '{givenName} {familyName}',
          },
          {
            givenName: item.patient.first_name,
            familyName: item.patient.last_name
          }
        );
        if (item.patient.external_id){
          label = label + `(${item.patient.external_id})`
        }
        return `${item.patient.id} - ${label}`
      } else {
        return formatMessage({
          id: 'downloadReportPage.text.unknownPatient',
          defaultMessage: 'Error - Unknown Patient'
        })
      }
    }

    const downloadPDFsInBulk = async () => {
        setIsGenerating(true);
        setProgress(0);
        let generatedCount = 0;
        const zip = new JSZip();
        await document.fonts.ready;
        
        const total = Object.values(selectedItems).reduce((acc, arr) => acc + arr.filter((isSelected) => isSelected).length, 0)

        for (const date of order) {
          for (let i = 0; i < grouped[date].length; i++) {
            if (!selectedItems[date]){
              // if date was never selected, its not created in selectedItems
              continue;
            }
            if (selectedItems[date][i]) {
                const item = grouped[date][i];
                //const element = previewRefs.current[`${date}-${item?.id}`];
                if (!item) {
                    continue;
                }
                var downloadUrl = item?.report?.report_upload?.download_url;
                if (!downloadUrl){
                    continue;
                }
                try {
                    const response = await axios.get(downloadUrl, { responseType: 'blob' });
                    //const pdfTitle = `${formatDateToYYYYMMDD(item?.created_at)} ${item?.user_profile?.first_name}`;
                    const pdfTitle = getReportLabel(item);
                    zip.file(`${pdfTitle}-${localeFormatDate(item.created_at, 'en', 'YYYYMMDD')}.pdf`, response.data);
                    generatedCount++;
                    setProgress((generatedCount / total) * 100);
                } catch (err){
                    console.log("error downloading pdf ", err)
                    continue;
                }
            }
          }
        }
    
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const currentDate = new Date();
          saveAs(content, `${currentDate.getTime()}.zip`); 
          setIsGenerating(false);
        });
    };

    const handleFilterChange = (v) => {
        //if (!searchParams?.patient){
        //    setPatientValueError(true)
        //    return;
        //}
        let params = searchParams;
        if (v?.value == 'custom'){
            // open date picker
            onDatePickerOpen();
            return;
        } else if (v?.value){
            params.date_filter = v?.value;
        } else {
            delete params.date_filter
        }
        const newParams = new URLSearchParams(params)
        history.replace({search: newParams.toString()})
    }

    const handleDatePickerChange = (date) => {
        //if (!searchParams?.patient){
        //    setPatientValueError(true)
        //    return;
        //}
        let params = searchParams;
        params.date_filter = 'custom'
        params.start_date = formatDateToYYYYMMDD(date[0].toISOString())
        params.end_date = formatDateToYYYYMMDD(date[1].toISOString())
        const newParams = new URLSearchParams(params)
        history.replace({search: newParams.toString()})
        //}`)
    }

    const handlePatientValueChange = (val) => {
        if (val){
            let params = searchParams;
            params.patient = val.value;
            params.patientQuery = val.label.trim();
            const newParams = new URLSearchParams(params)
            history.push({search: newParams.toString()})
        } else {
            let params = searchParams;
            delete params.patient
            delete params.patientQuery
            const newParams = new URLSearchParams(params)
            history.push({search: newParams.toString()})
        }
    }

    return (
      <>
        <DateRangeModal 
          isOpen={isDatePickerOpen} 
          onClose={onDatePickerClose} 
          onSelected={handleDatePickerChange}
        />

        <Modal closeOnOverlayClick={false} isOpen={isGenerating} isCentered size={'lg'}>
          <ModalOverlay />
          <ModalContent >
            <ModalHeader
              boxShadow={'0px -2px 15px 0px rgba(0, 0, 0, 0.08)'}
              backgroundColor={'#fff'}
              borderTopRadius={'10px'}
            >
              <Center>
                <Text
                  color={'#12171A'}
                  fontSize={'16px'}
                >
                  <FormattedMessage
                    id={'downloadReportPage.modal.title'}
                    defaultMessage={'Download Report'}
                  />
                </Text>
              </Center>
            </ModalHeader>
            <ModalBody>
              <Box>
                <Flex w={'full'} align={'flex-start'} py={'40px'} px={'30px'} pt={'20px'} direction={'column'}>
                  <Text 
                    color={'#273238'}
                    fontSize={'36px'}
                  >
                    <FormattedMessage 
                      id={'downloadReportPage.text.progress.title'}
                      defaultMessage={'Downloading Reports'}
                    />
                  </Text>
                  <Text
                    mt={'10px'}
                    color={'#9B9B9B'}
                    fontSize={'15px'}
                  >
                    <FormattedMessage 
                      id={'downloadReportPage.text.progress.description'}
                      defaultMessage={'Please wait while we prepare your reports for download. Please do not navigate away from this page.'}
                    />
                  </Text>
                  <Progress 
                    borderRadius={'10px'}
                    mt={'40px'}
                    colorScheme={'progressColor'}
                    hasStripe={true}
                    isAnimated={true}
                    value={progress+2} 
                    w={'full'} 
                  />
                </Flex>
                </Box>
              </ModalBody>
          </ModalContent> 
        </Modal>
        <Box>
            <h2>
                <FormattedMessage 
                    id={'settings.menu.menulist.menuItem.downloadreport.title'}
                    defaultMessage={'Download Report'}
                />
            </h2>
            <Box bg={'transparent'} position={'relative'} height={'950px'} overflow={'overlay'} pr={'20px'}>
                <Flex mt={'10px'} w={'full'} align={'center'} justify={'space-between'} px={'5px'}>
                    <Flex>
                        <Flex w={'220px'} mr={'10px'}>
                            <ClinicPatientSearchNoInput
                                clinic={clinic}
                                name={'patient'}
                                value={searchParams?.patient ? {value: searchParams?.patient, label: searchParams?.patientQuery ? searchParams?.patientQuery :  `Patient ID: ${searchParams?.patient}`} : null}
                                label={null}
                                error={patientValueError}
                                placeholder={formatMessage({id: 'adminPage.form.field.patient.placeholder', defaultMessage: 'Filter by patient'})}
                                onSelect={(val) => {
                                    handlePatientValueChange(val);
                                }}
                            />
                        </Flex>
                        <Flex w={'180px'}>
                            <SelectOptionFieldNoInput 
                                isLoading={filtersIsFetching || filtersIsLoading}
                                isMultiple={false}
                                value={searchParams?.date_filter}
                                name={'sort_by'}
                                onChange={handleFilterChange}
                                selectOptions={filtersData?.options || defaultData}
                                isInvalid={false}
                                label={null}
                                error={null}
                                placeholder={formatMessage({id: 'downloadReportPage.select.dateFilter.placeholder', defaultMessage: 'Date Filter'})}
                                optionValue={searchParams?.date_filter == 'custom' ? 
                                    {value: 'custom' , label: `${searchParams.start_date} - ${searchParams.end_date}`} : null
                                }
                            />
                        </Flex>

                    </Flex>
                         
                  <Button
                      leftIcon={<DownloadIcon />}
                      isLoading={isGenerating}
                      isDisabled={isGenerating || !Object.values(selectedItems).some((arr) => arr.some((isSelected) => isSelected))}
                      onClick={downloadPDFsInBulk}
                  >
                      <FormattedMessage 
                          id={'downloadReportPage.button.download'}
                          defaultMessage={'Download'}
                      />
                  </Button>                       
                </Flex>
                <Box mt={'20px'}>
                    {
                        (isLoading || isFetching) ? (
                            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                                <Spinner 
                                    size='xl'
                                    thickness='4px'
                                    speed='0.65s' 
                                    color='#44C5A6'
                                />
                            </Flex>
                        ) : (
                            <ItemList 
                                grouped={grouped}
                                order={order}
                                selectedItems={selectedItems} 
                                handleCheckboxChange={handleCheckboxChange}
                                setSelectedItems={setSelectedItems}
                                setSelectAll={setSelectAll}
                                selectAll={selectAll}
                            />
                        )
                    }
                </Box>
            </Box>
        </Box>
      </>
    )
}
