import React, { useRef, useState, useEffect, useCallback } from "react";
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useRecordDetails } from "../api/getRecord";
import { Box, Flex, Image, Text, Button, Switch, Checkbox } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { RecordUpload } from 'src/_helpers/models';
import { FormattedMessage, useIntl } from "react-intl";
import { TOOTH_NUMBERING_SYSTEM, DEFAULT_ALGO_VER, DEFAULT_ALGO_TYPE } from 'src/_config';

import { useQueryClient } from 'react-query';
import { toString } from 'lodash';

import { Detections } from "./Detections";
import { LoadingCircle, LoadingEllipsis } from 'src/_components/Loaders';
import { MdFileDownload, MdRefresh, MdErrorOutline } from 'react-icons/md'
import { PaperclipIcon } from 'src/_images/icons/PaperclipIcon';

import InnerImageZoom from 'react-inner-image-zoom'
import ReactPlayer from 'react-player/lazy'
import ReactTooltip from 'react-tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { useUploadDetections } from "../api/getUploadDetections";
import { useMediaStore } from "../state/media";
import { getIntlLocale, getIntlMessages } from 'src/App/_redux/selectors';

import { StyledVideoView } from "./styles";

import { localeFormatDate } from 'src/_helpers';
import { consoleLog } from 'src/_helpers/consoleLog';
import { QUERY_KEY_NAME as REQUEST_QUERY_KEY_NAME } from "../api/getRequest";
import ArrowBackIcon from "src/_images/icons/ArrowBackIcon";
import { useLocation } from "react-router";
import { useAuth } from "src/_libs/auth";
import { useAppConfig } from "src/App/state/appConfig";

const CURRENT_TOOTH_NUMBERING_SYSTEM = 'palmer';

const ContainerLoadingOverlay = ({isShowing=false}) => {
    if (!isShowing) {
            return <></>
    }
    return (
        <Flex position={['absolute']} w={['full']} h={['calc(100vh - 72px)']} justify={['center']} align={['center']} bg={['#00000020']}>
            <LoadingCircle />
        </Flex>
    )
}
ContainerLoadingOverlay.propTypes = {
    isShowing: PropTypes.bool,
}
function useCurrentRef() {
    const [ image, setImage ] = useState(null);
    const ref = useCallback(node => {
      if (node !== null) {
        setImage(node);
      }
    }, [])
    return [image, ref];
  }
  
const VideoFrame = ({frame}) => {
    const { setVideoPlayer } = useMediaStore();
  
    useEffect(()=>{
      ReactTooltip.rebuild();
      return () => {};
    }, [])
    const handleClick = () => {
      setVideoPlayer({seekTime: frame.timestamp})
      //dispatch(actions.updateStateObject('videoPlayer', {seekTime: frame.timestamp}))
    }
    const toolTipHtml = `<img width="450" src='${frame.frame}' />`;
    return (
      <Box data-for={"framePreview"} data-tip={toolTipHtml} data-html={true} ml={['5px']} onClick={handleClick}>
        <Image w={['80px']} ml={['5px']} _hover={{cursor: 'pointer'}} src={frame.frame} />
      </Box>
    )
  }

const VideoFrameList = () => {
    const { videoFrames } = useMediaStore();
    const ref = useRef(null);
  
    if (videoFrames && videoFrames.length > 0){
      return (
        <div ref={ref}>
          <Scrollbars
              style={{width: '100%'}}
              autoHide
              autoHeight
            >
            <Flex m={['15px 0']} sx={{"> div:first-child": {marginLeft: 0}}}>
              {videoFrames.map((frame, index) => <VideoFrame key={index} index={index} frame={frame} />)}
            </Flex>
          </Scrollbars>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }


const DetectionCanvas = ({frame, isDetectionOn, totalFrames}) => {
    const ref = useRef();
    const lastDrawFrame = useRef(0);
    const { isDetectionLabelShowing } = useMediaStore();
    const canvas = ref.current;
    const context = canvas && canvas.getContext('2d');
    const user = useAuth()
    const isStaff = user?.user?.is_staff;
  
  
    const maxSameFrame = 0;
  
    let lineWidth = 3;
    let boxColor = "#BE3B3C";
    //let colorMap = {
    //  "caries1": "#BE3B3C",
    //  "caries2": "#BE3B3C",
    //}
    useEffect(()=>{
      if (context && isDetectionOn && frame){
        // DEBUG console.log("should be drawing frame ", frame)
        // frame = [frameNum, detections array]
        // frame = [frameTs, frameNum, detectionsArray ]
        // if same frame for 4 frames and no current detections, remove
        if (Math.abs(frame[0] - (lastDrawFrame?.current || 0)) > maxSameFrame){
          context.clearRect(0, 0, canvas.width, canvas.height)
        }
        if (frame[1].length > 0){
          context.clearRect(0, 0, canvas.width, canvas.height)
          frame[1].map(elem => {
              //let [ left, top, right, bottom] = elem;
              // eslint-disable-next-line no-unused-vars
              let [ score, x0, y0, x1, y1, label] = elem;
              const canvasWidth = 560;
              const canvasHeight = 420;
              const mediaWidth = 640;
              const mediaHeight = 480;
              let left = x0*canvasWidth/mediaWidth // width of html canvas / width of video frame TODO: make dynamic not hard-coded
              let top = y0*canvasHeight/mediaHeight  // height of html canvas / height of video frame
              let right = x1*canvasWidth/mediaWidth 
              let bottom = y1*canvasHeight/mediaHeight
              context.beginPath();
              context.lineWidth = `${lineWidth}`;
              context.strokeStyle = boxColor;
              // x, y, width, height
              context.rect(left, top, right-left, bottom-top);
              context.stroke();

              if (isDetectionLabelShowing && isStaff == true){
                // Calculate the position for the text

                // Calculate the position for the text
                const boxWidth = Math.min(right, canvasWidth - lineWidth / 2) - left;
                //const boxHeight = Math.min(canvasHeight - lineWidth / 2, bottom) - top;

                const lines = [
                  `${label}: ${score}`,
                  `frame: ${frame[0]}/${totalFrames}`
                ]
                const lineHeight = 20; // Adjust the line height as needed
                const textHeight = lines.length * lineHeight;

                let textX = left;
                let textY;
                // Place text above or below the box depending on its position
                if (top < canvasHeight / 4) {
                    // If the box is near the top, place text below the box
                    textY = bottom + 20; // Adjust 20 for the desired spacing
                } else {
                    // Otherwise, place text above the box
                    textY = top - textHeight + 10; // Adjust 10 for the desired spacing
                  }

                // Adjust text alignment based on the box's horizontal position
                if (left > canvasWidth / 2) {
                    // If the box is on the right side of the canvas, align text to the right
                    textX = left + boxWidth; // Right edge of the box
                    context.textAlign = "right";
                } else {
                    // Otherwise, align text to the left
                    textX = left; // Left edge of the box
                    context.textAlign = "left";
                }

                // Draw the text
                context.font = "14px Proxima Nova"; // Set the font size and family as needed
                context.fillStyle = boxColor; // Set the text color
                lines.forEach((line, index) => {
                  context.fillText(line, textX, textY + (index * lineHeight));
              });
              }
          });
          lastDrawFrame.current = frame[0];
        }
      }
    }, [frame, isDetectionOn, isStaff])
  
    return(
      <canvas className={isDetectionOn ? 'DETECTIONON' : 'DETECTIONOFF'} ref={ref} width={560} height={420} />
    )
  }

const DetectionStatus = ({data}) => {
  // status
  // no detections
  // 
  if (data?.status != "COMPLETE"){
    return (
      <Flex w={'full'} justify={'space-between'} pt={'5px'} px={'8px'}>
        <Text>
          <FormattedMessage 
            id='requestDetailPage.mediaColumn.detection.statusNotComplete'
            defaultMessage='Detections are currently being processed...'
          />
        </Text>
        <Text> </Text>
      </Flex>
    )
  }
  const hasDetections = (data?.detections || []).length > 0;
  if (hasDetections){
    return (
      <Flex w={'full'} justify={'space-between'} pt={'5px'} px={'8px'}>
        <Text>
          <FormattedMessage 
            id='requestDetailPage.mediaColumn.detection.hasDetections'
            defaultMessage='*Hover over the colored areas to view detections.'
          />
        </Text>
        <Text>
          {`${data.algorithm_type || DEFAULT_ALGO_TYPE} v ${data.algorithm_version || DEFAULT_ALGO_VER}`}
        </Text>
      </Flex>
    )
  } else {
    return (

      <Flex w={'full'} justify={'space-between'} pt={'5px'}>
        <Text>
          <FormattedMessage 
            id='requestDetailPage.mediaColumn.detection.nodetections'
            defaultMessage='*No abnormalities detected.' 
          />
        </Text>
        <Text>
          {`${data.algorithm_type || DEFAULT_ALGO_TYPE} v ${data.algorithm_version || DEFAULT_ALGO_VER}`}
        </Text>
      </Flex>
    )
  }
}

  // print out string for detection data
const PrintedDetectionData = ({data}) => {
  const { formatMessage } = useIntl();
  const locale = useSelector(getIntlLocale);

  let detectionString =  "";
  if (data?.run_date_timestamp){
    detectionString = detectionString + ' ' + formatMessage({
      id: 'requestDetailPage.mediaColumn.detection.run_datetime',
      defaultMessage: 'RUN: {run_date_timestamp}'
    }, {
      run_date_timestamp: localeFormatDate(new Date(data.run_date_timestamp * 1000), locale, 'lll')
    })
  }
  return detectionString
}

const DetectionBoxDescription = ({detectionData, isDetectionLabelShowing, setIsDetectionLabelShowing}) => {
  const { user } = useAuth();

  if (user?.is_staff){
    return (
      <Flex w={'full'} direction={'column'}>
        <DetectionStatus 
          data={detectionData}
        />
        <Flex w={'full'} justify={'space-between'} pt={'5px'} px={'8px'}>
          <Flex>
            <Text fontSize={'14px'} color={'#405159'}>
              <PrintedDetectionData 
                data={detectionData}
              />
            </Text>
          </Flex>
          <Checkbox
            ml={'10px'}
            mb={0}
            colorScheme={'brand'}
            isChecked={isDetectionLabelShowing}
            onChange={() => setIsDetectionLabelShowing(!isDetectionLabelShowing)}
            size="md"
            textTransform={'capitalize'}
          >
              <FormattedMessage
                id={'requestDetailPage.mediaColumn.detection.label.title'}
                defaultMessage={'show labels'}
              />
          </Checkbox>
        </Flex>
      </Flex>
     
    )
  } else {

      return (
        <Flex w={'full'} justify={'space-between'} pt={'5px'} px={'8px'}>
            <DetectionStatus 
              data={detectionData}
            />
        </Flex>
      )
  }

}

const VideoView = ({ id, uid, srcUrl, hasDetections, description, onError}) => {
    //console.log("hasDetections ", hasDetections)
    const clinic = useAppConfig(state => state.clinic?.id);
    const { data: detectionData, isLoading: detectionsIsLoading } = useUploadDetections({recordUploadUid: uid, clinic });
    // data/fps
    const { isDetectionShowing, isDetectionLabelShowing, setIsDetectionLabelShowing, setCurrentVideoFrameData } = useMediaStore();


    const videoPlayerRef = useRef();
    //const currentVideoFrameList = useSelector(getCurrentVideoFrames);
    //const videoFrames = useSelector(getVideoFrames);
  
    const [ canvasFrame, setCanvasFrame] = useState();
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ videoPlaying, setVideoPlaying ] = useState(false);
    const [ videoInfo, setVideoInfo ] = useState([-1,-1]); // fps, frames
    const [ videoSeek, setVideoSeek ] = useState(0);
  
    const canUseDetections = true //isStaff

    let detections = [];
    if (detectionData?.detections){
      if (typeof detectionData?.detections === 'string') {
        detections = JSON.parse(detectionData.detections);
      } else  {
        detections = detectionData.detections;
      }
    }
    let dataByFrame = (detections || []).reduce((acc, curr) => {
      acc[curr[1]] = curr;
      return acc;
    }, {});

    useEffect(()=>{
        // fetch detections if user is staff
        // TODO: maybe make this unrestricted. change var canUseDetections for isStaff if restricted
        if (canUseDetections){
            if (detectionData?.fps && detectionData?.frames){
                setVideoInfo([detectionData?.fps, detectionData?.frames])
            }
        }
    }, [detectionData?.fps, detectionData?.frames])
  
    useEffect(()=>{
      if (videoInfo[1] > -1){
        setIsLoaded(true)
      }
    }, [videoInfo])
  
    const onProgress = (prog) => { 
      let {playedSeconds, loaded, loadedSeconds, played } = prog;
      setCurrentVideoFrameData({playedSeconds, loaded, loadedSeconds, played, id, uid, srcUrl})
      setVideoSeek(playedSeconds*videoInfo[0])
      if (loaded >= 1){
        (!isLoaded) && setIsLoaded(true);
        (hasDetections === false && !dataByFrame) && setVideoInfo([30, loadedSeconds*30]);
        //console.log("isDetectionOn ", isDetectionShowing, " dataByFrame " , dataByFrame, " ", loadedSeconds)
        if (isDetectionShowing && dataByFrame){
          let currFrame = Math.round(played*videoInfo[1]);
          let currDetection = dataByFrame[currFrame];
          // currDetection: timestamp, frame num, detecitons array
          (currDetection && setCanvasFrame([currFrame, currDetection[2]])) || setCanvasFrame([currFrame,[]]);
        }
      }
    }
  
    const onBuffer = (a=null) => {
      consoleLog("buffer started: " , Object.keys(a))
      consoleLog(videoPlayerRef.current)
    }
  
    const onBufferEnd = (a=null) => {
      consoleLog("buffer ended: " + Object.keys(a))
      consoleLog(videoPlayerRef.current.player)
    }
  
    const handleMouseEnterDetection = (frameNum) => {
      if (videoInfo[1] > 0) {
        try{
          setVideoPlaying(false);
          setVideoSeek(frameNum);
          videoPlayerRef.current && videoPlayerRef.current.seekTo(frameNum/videoInfo[1], 'fraction');
        } catch (err) {
          consoleLog("error seeking to frame: ", err);
        }
      } 
    }
  
    //console.log("detecionts ", dataByFrame)

    return(
      <StyledVideoView>
        <div className={'videoPlayerContainer'}>
          { (description && description.length > 0) && 
            <Flex align={'center'}
              position={'absolute'} width={'100%'} top={0} left={0} right={0} 
              padding={'10px 15px'} 
            >
                <Text color={'white'}>{description}</Text>
            </Flex>
          }
          <ReactPlayer
            ref={videoPlayerRef}
            onProgress={onProgress}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            playing={videoPlaying}
            onPlay={() => setVideoPlaying(true)}
            onPause={() => setVideoPlaying(false)}
            url={srcUrl}
            muted={true}
            playbackRate={0.8}
            width={'100%'}
            height={'auto'}
            controls={false} 
            onError={onError}
          />
          <DetectionCanvas frame={canvasFrame} isDetectionOn={isDetectionShowing} totalFrames={videoInfo[1]} />
        </div>
        {
          (!detectionsIsLoading) ? (
            <Box>
              <Detections
                    key={uid}
                    isShowing={isDetectionShowing}
                    currentDetections={(canUseDetections && isDetectionShowing)? dataByFrame : {}}
                    data={detectionData}
                    currFrame={videoSeek}
                    loaded={isLoaded}
                    onMouseEnter={handleMouseEnterDetection}
                    containerWidth={560}
                />
                <DetectionBoxDescription 
                  detectionData={detectionData} 
                  isDetectionLabelShowing={isDetectionLabelShowing} 
                  setIsDetectionLabelShowing={setIsDetectionLabelShowing}
                />
              </Box>
            ) : (
            <Flex height={'60px'} align={'center'} justify={'center'}><LoadingEllipsis /></Flex>
          )
        }
      </StyledVideoView>
    )
  }

const PhotoView = ({srcUrl, description, onError}) => {
    const [image, ref] = useCurrentRef();
    const { setImageIndex } = useMediaStore();
    
    useEffect(()=>{
      if (image !== null){
        setImageIndex(ReactDOMServer.renderToString(image.current))
        //dispatch(actions.updateStateObject("currentImageComponent", {[currentImageIndex]:  ReactDOMServer.renderToString(image.current)}))
      }
    }, [image]);

    useEffect(()=>{
      return () => {};
    }, [])

    return(
      <Box ref={ref} bg={['#F7F9FA']}>
        { description && 
          <Flex align={'center'}
            position={'absolute'} width={'100%'} top={0} left={0} right={0} 
            bg={'rgba(0,0,0,0.2)'} padding={'10px 15px'} 
          >
              <Text color={'white'}>{description}</Text>
          </Flex>
        }
        <img 
          src={srcUrl}
          style={{display:'none', width:640, height:0}}
          onError={onError}
          width={0}
        />
        <InnerImageZoom
          zoomScale={1.5}
          zoomSrc={srcUrl}
          src={srcUrl}
        />
      </Box>
    )
  }

const isVideo = (fileUrl, mimeType) => {
    if (mimeType && mimeType.includes("video")){
      return true;
    } else if (fileUrl && (fileUrl.includes(".mp4") || fileUrl.includes(".mkv") || fileUrl.includes(".m4v") || fileUrl.includes(".webm") || fileUrl.includes(".mov")) ){
      return true;
    } else {
      return false;
    }
}

const MediaView = ({currentUpload}) => {
    const intlMessages = useSelector(getIntlMessages);
      
    const [ error, setError] = useState(null);
    const [ mimeType, setMimeType ] = useState(null);
  
    useEffect(() => {
      if (currentUpload) {
        if (currentUpload.getUploadHead()){
          fetch(currentUpload.getUploadHead(), {method: 'HEAD'})
            .then( response => {
              if (response.status === 403){
                setError(true);
                return null;
              }
              let contenttype = response.headers.get("content-type");
              setMimeType(contenttype)
            }).catch(() => {
              setError(true);
            })
        } else if (currentUpload.getUpload()) {
          setMimeType("image/*");
        } else {
          //setError(true);
          setMimeType("video/*");
        }
      }
    }, [currentUpload])
  
    useEffect(()=>{
      return () => {};
    }, [])
  
    const refetchUpload = () => {
      //console.log("refetchUpload")
    }
  
    const onImageError = () => {
      consoleLog("MediaSection image error")
      setMimeType("video/*");
    }
  
    const onVideoError = (...props) => {
      consoleLog("MediaSection video error: ", props)
      //setError(true);
    }
  
    let status = "";
    if (currentUpload && currentUpload.getRegion() > -1){
      status = intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.label`]
      status += ": "
      status += TOOTH_NUMBERING_SYSTEM[CURRENT_TOOTH_NUMBERING_SYSTEM][intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.teethrange.start`]]
      status += " -> "
      status += TOOTH_NUMBERING_SYSTEM[CURRENT_TOOTH_NUMBERING_SYSTEM][intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.teethrange.end`]]
    }
  
    if (currentUpload == null){
      return (
        <Flex minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
        </Flex>
      )
    }
  
    if (error){
      return(
        <Flex direction={'column'}  borderRadius={'2px'} bg={'#F7F9FA'}  minHeight={'480px'} minWidth={'640px'} align={'center'} justify={'center'}>
            <MdErrorOutline size={26} fill={'#F93D5C'} />
            <Text fontSize={14} mt={2}>{'Error fetching asset'}</Text>
            <Text fontSize={12} color={'none.500'}>{'Click the Refresh button to refetch the asset'}</Text>
            <Button 
              leftIcon={<MdRefresh size={16} />} 
              mt={4}
              size={'sm'}
              fontSize={14} 
              variant="solid" 
              colorScheme={'bdBlue'}
              onClick={refetchUpload}
            >
              {'Refresh'}
            </Button>
        </Flex>
      )
    }

    if (isVideo(currentUpload.getUpload(), mimeType)){
      return (
          <VideoView 
            onError={onVideoError} 
            upload={currentUpload}
            hasDetections={currentUpload !== undefined ? currentUpload?.upload?.has_detections : null} 
            description={status} 
            srcUrl={currentUpload.getUpload()} 
            uid={currentUpload.getId()} 
            id={currentUpload.getIntId()}
          />
      )
    } else if(mimeType && mimeType.includes("image")){
      return (
          <PhotoView 
            onError={onImageError} 
            description={status} 
            srcUrl={currentUpload.getUpload()} 
          />
      )
    } else if(mimeType){
      return (
        <Flex direction={'column'} minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
          <PaperclipIcon width={22} />
          <Text fontSize={14} mt={2}>{'No Preview Available'}</Text>
          <Text fontSize={12} color={'none.500'}>{currentUpload?.upload?.filename}</Text>
          <a style={{'text-decoration': 'none'}} download={currentUpload?.upload?.filename} href={currentUpload.getUpload()} title="downloadFIle">
            <Button 
              leftIcon={<MdFileDownload size={16} />} 
              mt={4}
              size={'sm'}
              fontSize={14} 
              variant="solid" 
              colorScheme={'bdBlue'}
            >
              {'Download'}
            </Button>
          </a>
        </Flex>
      )
    } else {
      return(
        <Flex minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
          <LoadingCircle />
        </Flex>
      )
    }
  }


const Thumbnail = ({upload}) => {
  if (!upload.isVideo()){
    return (
      <Flex>
        <Image 
          src={upload.getUpload()}
          w={['90px']}
          h={['90px']}
          alt={'upload'}
        />
      </Flex>
    )
  } else {
      return (
        <Flex>
          <video 
            src={upload.getUpload()}
            width='90px'
            height='90px'
            alt={'upload'}
          >
            <source src={upload.getUpload()} />
          </video>
        </Flex>
      )
    }
}

const renderThumbnails = (uploads) => {
  return (uploads || []).map(upload => <Thumbnail key={upload.id} upload={new RecordUpload(upload)} />)
}

const MediaColumn = ({recordId, requestUid}) => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const location = useLocation();
    const clinic = useAppConfig(state => state.clinic?.id);
    const { data, isLoading, isFetching } = useRecordDetails({recordId, clinic }); 
    const { imageIndex, isDetectionShowing, setImageIndex, setIsDetectionShowing } = useMediaStore();
    const requestData = queryClient.getQueryData([REQUEST_QUERY_KEY_NAME, {id: toString(requestUid)}]);

    useEffect(() => {
        setImageIndex(0);
        return () => {};
    }, [recordId])

    const handleClick = (index) => {
        setImageIndex(index);
    }

    const goBack = () => {
      const urlSearchParams = new URLSearchParams(location.state?.previousLocation?.search || {});
      history.push({
          pathname: '/',
          search: `?${urlSearchParams.toString()}`
      });
    }

    return (
        <Box
            overflowX="hidden"
            width={["640px"]}
            minHeight={["650px"]}
            p={['40px']}
        >
            <ContainerLoadingOverlay isShowing={(isLoading || isFetching )}/>
            <Flex>
              <Button
                variant="ghost"
                minWidth={['unset']}
                leftIcon={<ArrowBackIcon />}
                onClick={goBack}
              >
                <Text fontSize={['14px']} fontWeight={[400]} letterSpacing={['0.5px']}>
                  <FormattedMessage
                    id={'requestDetailPage.mediaColumn.backButton'}
                    defaultMessage={'Go Back'} 
                  />
                </Text>
              </Button>
            </Flex>
            <Flex mt={['20px']}>
              <Text color={'#273238'} fontSize={['25px']}>
                <FormattedMessage 
                  id={'format.fullName'}
                  defaultMessage="{givenName} {familyName}"
                  values={{
                    givenName: requestData?.request?.patient?.first_name,
                    familyName: requestData?.request?.patient?.last_name
                  }}
                />
              </Text>
            </Flex>
            <Flex align={['center']} justify={['space-between']} pb={['20px']}>
              <Text fontSize={'20px'} color={'#405159'}>
                <FormattedMessage 
                  id={'requestDetailPage.mediaColumn.scans.title'}
                  defaultMessage="Scans"
                />
              </Text>
              <Flex align={['center']}>
                  <Text fontSize={'18px'} color={'#405159'}>
                    <FormattedMessage 
                      id={'requestDetailPage.mediaColumn.detection.title'}
                      defaultMessage="Detection Boxes" 
                    />
                  </Text>
                  <Switch colorScheme={'brand'} margin={0} ml={['10px']} size={'md'} id={'detectionOn'}
                      isChecked={isDetectionShowing} onChange={() => setIsDetectionShowing(!isDetectionShowing)} />
              </Flex>
            </Flex>
            <ReactTooltip id="framePreview" className={'framePreviewTooltip'}/>
            {
              !isFetching &&
              <Carousel
                statusFormatter={() => status}
                selectedItem={imageIndex}
                showThumbs={true}
                showArrows={false}
                showIndicators={false}
                onChange={handleClick}
                thumbWidth={100}
                renderThumbs={() => renderThumbnails(data?.record?.patientrecord_uploads)}
            >
                { (data?.record?.patientrecord_uploads || []).map((upload,) =>
                    <MediaView key={upload.unique_id} currentUpload={new RecordUpload(upload)}/>
                )}
            </Carousel>
            }
            
            <VideoFrameList />
            <Flex mt={['30px']}>
              <Text 
                color={'#273238'} 
                fontSize={['30px']}
              >
                <FormattedMessage 
                  id={'requestDetailPage.mediaColumn.patientnotes'}
                  defaultMessage="Patient Comments"
                />
              </Text>
            </Flex>
            <Flex mt={['10px']}>
              <Text 
                color={'#405159'} 
                fontSize={['15px']}
              >
                {requestData?.request?.remark ? (
                  requestData?.request?.remark
                ) : (
                  <FormattedMessage
                    id={'requestDetailPage.mediaColumn.patientnotes.none'}
                    defaultMessage="No comments"
                    />
                )}
              </Text>
            </Flex>
        </Box>
    )
}
MediaColumn.propTypes = {
    recordId: PropTypes.number.isRequired,
    requestUid: PropTypes.string.isRequired,
}
export {MediaColumn};