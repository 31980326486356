import { authHeader, handleResponse } from 'src/_helpers';
import { API_URLS } from 'src/_config';
import { axios } from 'src/_libs/axios';

/**
 * 
 * @param {*} file the pdf file
 * @param {*} reportId report to upload the pdf report. create on send report
 * @param {*} onUploadProgress function with (event) input use for progress i.e. Math.round((event.loaded) / event.total)
 * @param {*} extraData any extra data needed for report
 * @returns 
 */
export const uploadReportPdf = (file, reportId, clinic, onUploadProgress, extraData = {}) => {
  let formData = new FormData();
  let requestUrl = API_URLS.uploadReport.url.replace("{reportId}", reportId);
  const requestHeaders = {
    'Content-Type': "multipart/form-data",
    ...authHeader()
  }
  formData.append("file", file);
  formData.append("extra_data", JSON.stringify(extraData));

  if (clinic){
    requestUrl = requestUrl + `?clinic=${clinic}`;
  }

  return axios.post(requestUrl, formData, {
    headers: requestHeaders,
    onUploadProgress
  });
};

/**
 * Fetch report details
 * @param {string} reportId: uid of report
 * @returns
 */
export function fetchReport(reportId, clinic) {
  const requestUrl = API_URLS.getDentistRequestReportDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{reportId}', reportId);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Fetch request detail
 * @param {string} requestId: uid of request
 */
export function fetchRequest(requestId, clinic) {
  const requestUrl = API_URLS.getDentistRequestDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{idType}', 'uid').replace('{requestId}', requestId);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Create report
 * @param {string} requestId: uid of request
 */
export function createReportForRequest(requestUid, data, clinic) {
  const requestUrl = API_URLS.createRequestReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    //body: JSON.stringify({...data, clinic}),
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{requestId}', requestUid);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Update report
 * @param {string} report: uid of request
 */
export function updateReport(reportUid, data, clinic) {
  const requestUrl = API_URLS.updateRequestReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    //body: JSON.stringify({...data, clinic}),
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{reportId}', reportUid);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Send report
 * @param {string} report: uid of request
 */
export function sendReport(reportUid, data, clinic) {
  //const requestUrl = API_URLS.sendReport;
  //const requestOptions = {
  //  method: requestUrl.method,
  //  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  //  body: JSON.stringify(data),
  //};
  let url = '/d/dentist/report/{reportId}/send'.replace('{reportId}', reportUid);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return axios.post(url, data)
  //return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Report Recommendations
 * @param {string} report_type (optional) - type of report DMFT or DEFAULT
 */
export function fetchReportRecommendations(reportUid, clinic, locale=null) {
  const requestUrl = API_URLS.getReportRecommendations;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{reportId}', reportUid);
  if (locale && clinic){
    url = url + `?clinic=${clinic}&locale=${locale}`;
  } else if (clinic){
    url = url + `?clinic=${clinic}`;
  } else if (locale){
    url = url + `?locale=${locale}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Report Categories
 * @param {string} report_type (optional) - type of report DMFT or DEFAULT
 */
export function fetchReportCategories(reportType=null, locale=null) {
  const requestUrl = API_URLS.getDentistReportCategories;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + `?report_type=${reportType}`;
  if (locale){
    url = url + `&locale=${locale}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Category templates
 * @param {string} category
 */
export function fetchCategoryTemplates(category, reportType, locale=null) {
  const requestUrl = API_URLS.getDentistReportTemplates;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + `?category=${category}`;
  if (reportType){
    url = url + `&report_type=${reportType}`;
  }
  if (locale){
    url = url + `&locale=${locale}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Get record upload detections
 * @param {string} recordUploadUid
 * @returns
 */
export function fetchRecordUploadDetections(recordUploadUid, clinic) {
  var url, requestUrl;
  requestUrl = API_URLS.getDentistRequestRecordUploadDetections;
  url = requestUrl.url.replace('{recordUploadUid}', recordUploadUid);
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Get record detail
 * @param {string} recordId
 * @param {string} requestId
 * @returns
 */
export function fetchRecord(recordId, requestId, clinic) {
  var url, requestUrl;
  if (requestId) {
    requestUrl = API_URLS.getDentistRequestRecordDetailWithRequest;
    url = requestUrl.url.replace('{requestId}', requestId).replace('{recordId}', recordId);
  } else {
    requestUrl = API_URLS.getDentistRequestRecordDetail;
    url = requestUrl.url.replace('{recordId}', recordId);
  }
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchBulkReports(queryObject){
  const requestUrl = API_URLS.getBulkReports;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json', ...authHeader()},
  };
  const query_string = new URLSearchParams(queryObject).toString();
  const url = requestUrl.url + "?" +  query_string;
  return fetch(url, requestOptions).then(handleResponse);
}
