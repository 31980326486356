import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import {Box, SimpleGrid, Button} from '@chakra-ui/react';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { useSelectOptions } from '../api/getSelectOptions';
import { DentistSearch } from './DentistSearch';
import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { createAccountRole, createDentistAccountRole } from '../api/services';
import { convertToAPIValues } from 'src/_libs/forms';
import { ClinicSearch } from './ClinicSearch';

export const DentistAccountRoleForm = ({
    onSuccess,
    onClose,
    dentistId,
  }) => {
    const { data : rolesData, isLoading: rolesIsLoading , isFetching: rolesIsFetching } = useSelectOptions({'option': 'account_roles'});
    const { formatMessage } = useIntl();
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        convertedValues.user = dentistId;
        try{
            const result = await createDentistAccountRole(dentistId, convertedValues);
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            render = {
                ({
                    handleSubmit,
                    submitting
                }) => {
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="clinic" validate={required}>
                                    {({input, meta}) => (
                                        <ClinicSearch 
                                            input={input}
                                            meta={meta}
                                           isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            label={formatMessage({id: 'adminPage.form.field.accountrole.clinic.label', defaultMessage: 'Clinic'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.accountrole.clinic.placeholder', defaultMessage: 'Search for a clinic'})}
                                            onSelect={(val) => {
                                                input.onChange(val);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="role" validate={required}>
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isLoading={rolesIsFetching || rolesIsLoading}
                                            isMultiple={false}
                                            label={formatMessage({id: 'adminPage.form.field.account_role.label', defaultMessage: 'Role'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.account_role.label', defaultMessage: 'Role'})}
                                            input={input}
                                            selectOptions={rolesData?.options}
                                           isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.cancel.button.label"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.submit.button.label"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </SimpleGrid>
                        </Box>
                    )
                }
            }
        />
    )
}

export const ClinicAccountRoleForm = ({
    onSuccess,
    onClose,
    clinicId,
  }) => {
    const { data : rolesData, isLoading: rolesIsLoading , isFetching: rolesIsFetching } = useSelectOptions({'option': 'account_roles'});
    const { formatMessage } = useIntl();
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        convertedValues.clinic = clinicId;
        try{
            const result = await createAccountRole(clinicId, convertedValues);
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            render = {
                ({
                    handleSubmit,
                    submitting
                }) => {
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="user" validate={required}>
                                    {({input, meta}) => (
                                        <DentistSearch 
                                            input={input}
                                            meta={meta}
                                           isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            label={formatMessage({id: 'adminPage.form.field.accountrole.user.label', defaultMessage: 'User'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.accountrole.user.placeholder', defaultMessage: 'Search for a user'})}
                                            onSelect={(val) => {
                                                input.onChange(val);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="role" validate={required}>
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isLoading={rolesIsFetching || rolesIsLoading}
                                            isMultiple={false}
                                            label={formatMessage({id: 'adminPage.form.field.account_role.label', defaultMessage: 'Role'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.account_role.label', defaultMessage: 'Role'})}
                                            input={input}
                                            selectOptions={rolesData?.options}
                                           isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.cancel.button.label"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.submit.button.label"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </SimpleGrid>
                        </Box>
                    )
                }
            }
        />
    )
}