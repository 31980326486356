import React from 'react';
import { 
    Box, Flex, HStack, Heading, Input, Select, Button, IconButton, Center,
    // eslint-disable-next-line no-unused-vars
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure,
    Table, Thead, Tbody, Tr, Th, Td  } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserCollection as useCollectionQuery } from '../api/getUserCollection';

import { UserForm } from '../components/UserForm';
import { EditDentistForm } from '../components/DentistForm';
import { HeaderMenu } from '../components/HeaderMenu';
import { DentistUserAccountRolePage } from '../components/UserRoles';
import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import PaginationNext from 'src/_images/icons/PaginationNext';
import { useSearchParamsObject } from 'src/_hooks/useSearchParams';
import { useHistory } from 'react-router';

const COLLECTION_PAGE_KEY = "dentists";

export const SearchSection = ({searchInput, setSearchInput, handleSearch}) => {
    const { formatMessage } = useIntl();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    return (
        <Box>     
            <Flex mt={'30px'}>
                <Input type="text" 
                    placeholder={formatMessage({id: "patientsListPage.patientSearch.button.search", defaultMessage: "Search"})}
                    value={searchInput} 
                    onKeyUp={handleKeyPress} 
                    onChange={(e) => setSearchInput(e.target.value)} 
                />
                <Button 
                    variant={"outlined"}
                    onClick={handleSearch} 
                >
                    <FormattedMessage 
                        id="patientsListPage.patientSearch.button.search"
                        defaultMessage="Search"
                    />
                </Button>
            </Flex>
        </Box>
    )
} 

const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, isLoading}) => {
    return (
        <HStack spacing={3} >
            <IconButton 
                isLoading={isLoading}
                icon={<PaginationPrevious />} 
                isDisabled={!canPreviousPage}
                onClick={previousPage}
                borderRadius={'5px'}
            />
            <Select 
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) : 0
                    setPageIndex(page)
                }}
                isDisabled={total < 2}
            >
                {Array.from({length: total}, (_, i) => (
                    <option key={i} value={i} selected={pageIndex == i}>
                        {i + 1}
                    </option>
                ))}
            </Select>
            <IconButton 
                isLoading={isLoading}
                icon={<PaginationNext />} 
                isDisabled={!canNextPage}
                borderRadius={'5px'}
                onClick={nextPage}
            />
        </HStack>
    
    )
}

const accessorClinics = (clinics) => {
    return (clinics || []).map(clinicRole => `${clinicRole.clinic?.name}: ${clinicRole.role}`).join(', ')
}

const PageTable = ({data, isLoading, pagination, setPagination, onEditRow}) => {
    const columns = React.useMemo(
        () => [
            {
                header: 'Email',
                id: 'email_address',
                accessorKey: 'email',
            },
            {
                header: 'Name',
                id: 'name',
                accessorFn: row => `${row.first_name} ${row.last_name}`,
            },
            {
                header: 'Clinic',
                id: 'clinic_name',
                accessorFn: row => accessorClinics(row.clinics),
            }
        ],
        []
    )

    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data?.users || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })


    const handleRowClick = (row) => {
        onEditRow(row?.id)
    }

    return (
        <>
            <Table mt={'20px'}>
                <Thead>
                    {(table.getHeaderGroups() || []).map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                        <Tr key={row.id} onClick={() => handleRowClick(row.original)} _hover={{cursor: 'pointer', bg: '#F7F7F7'}}>
                            {row.getVisibleCells().map(cell => {
                            return (
                                <Td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Td>
                            )
                            })}
                        </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <Flex w="full" mt={'50px'} justify={'flex-end'}>
                <Pagination 
                    isLoading={isLoading}
                    canPreviousPage={table.getCanPreviousPage()}
                    canNextPage={table.getCanNextPage()}
                    pageIndex={table.getState().pagination.pageIndex}
                    total={table.getPageCount()}
                    nextPage={table.nextPage}
                    previousPage={table.previousPage}
                    setPageIndex={table.setPageIndex}
                />
            </Flex>
        </>
    )
}

const DentistUserRolesModal = ({
    dentistId,
    isOpen,
    onClose
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered >
        <ModalOverlay bg="blackAlpha.300" />
        <ModalContent minW="850px" bg="#f7f9fa">
            <ModalHeader>
            <Center>
                    <FormattedMessage 
                        id={'adminPage.dentistUserRoles.title'}
                        defaultMessage={'Dentist User Roles'}
                    />
            </Center>
            </ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
            <Box>
                <DentistUserAccountRolePage 
                    dentistId={dentistId}
                />
            </Box>
            </ModalBody>
        </ModalContent>
        </Modal>
    )
}

const EditDentistFormModal = ({
    dentistId,
    isOpen,
    onClose,
    onSuccess
}) => {
    const { isOpen: isRolesOpen, onOpen: onRolesOpen, onClose: onRolesClose } = useDisclosure();

    const handleOnSuccess = () => {
        onSuccess();
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
          <ModalOverlay bg="blackAlpha.300" />
          <ModalContent minW="700px" bg="#f7f9fa">
            <DentistUserRolesModal dentistId={dentistId} isOpen={isRolesOpen} onClose={onRolesClose} />
            <ModalHeader>
              <Center>
                    <FormattedMessage 
                        id={'adminPage.dentistFormEdit.title'}
                        defaultMessage={'Edit Dentist'}
                    />
              </Center>
            </ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <Flex justify={'space-between'}>
                    <Heading as={'h2'}>
                        <FormattedMessage 
                            id={'adminPage.dentistFormEdit.title'}
                            defaultMessage={'Dentist Details - Public'}
                        />
                    </Heading>
                    <Flex>
                        <Button variant={'link'} onClick={onRolesOpen}>
                            <FormattedMessage id={'adminPage.dentistFormEdit.viewRoles'} defaultMessage={'View Roles'} />  
                        </Button>
                    </Flex>
                </Flex>
                <Box>
                    <EditDentistForm
                        dentistId={dentistId}
                        onSuccess={handleOnSuccess}
                        onClose={onClose}
                    />
                </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
    )
}

const NewUserFormModal = ({
    isOpen,
    onClose,
    onSuccess
}) => {
    // email, password, first_name, last_name, date_of_birth, gender, user_type, country, categories, description, 
    // clini_id, account_role... 
    // clinic_name, branch, address, timezone, geopoint

    const handleOnSuccess = () => {
        onSuccess();
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
          <ModalOverlay bg="blackAlpha.300" />
          <ModalContent minW="700px" bg="#f7f9fa">
            <ModalHeader>
              <Center>
                    <FormattedMessage 
                        id={'adminPage.createUserFormModal.title'}
                        defaultMessage={'Add New User'}
                    />
              </Center>
            </ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <Heading as={'h2'}>
                    <FormattedMessage 
                        id={'adminPage.createUserForm.title'}
                        defaultMessage={'User Details'}
                    />
                </Heading>
              <Box>
                <UserForm
                  onSuccess={handleOnSuccess}
                  onClose={onClose}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
    )
}

const PageHeader = ({onNewItemAdded}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <NewUserFormModal isOpen={isOpen} onClose={onClose} onSuccess={onNewItemAdded} />
            <Flex justify={'space-between'} align={'end'}>
                <HeaderMenu page={COLLECTION_PAGE_KEY} />
                <Flex w={'full'} justify={'flex-end'}>
                    <Button variant="outline" onClick={onOpen}>
                        <FormattedMessage
                            id="admin.dentistuserspage.button.newUser"
                            defaultMessage="+ Add New User"
                        />
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}


export const UserCollectionPage = () => {
    const searchParams = useSearchParamsObject(); 
    const history = useHistory();
    const PAGE_SIZE = 15;
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    })
    const {data, isLoading, isFetching,refetch} = useCollectionQuery({
        offset: pagination.pageIndex, pg_sz: pagination.pageSize
    })

    const handleOpenEditModal = (id) => {
        const params = searchParams;
        params.editItem = id;
        const newParams = new URLSearchParams(params);
        history.push({search: newParams.toString()})
    } 

    const handleCloseEditModal = () => {
        const params = searchParams;
        delete params.editItem;
        const newParams = new URLSearchParams(params);
        history.replace({search: newParams.toString()})
    }

    const handleEditSuccess = () => {
        const params = searchParams;
        delete params.editItem;
        const newParams = new URLSearchParams(params);
        refetch()
        history.replace({search: newParams.toString()})
    }
    return (
        <Box
            minH="100vh"
            pt="102px"
            ml="90px"
            pr="20px"
            bg="#fff"
            pl="60px"
            pb={"100px"}
        >
            <EditDentistFormModal 
                dentistId={searchParams?.editItem} 
                isOpen={!!(searchParams?.editItem)} 
                onClose={handleCloseEditModal} 
                onSuccess={handleEditSuccess} 
            />
            <PageHeader onNewItemAdded={refetch}/>
            <PageTable data={data} onEditRow={handleOpenEditModal} isLoading={isLoading || isFetching} pagination={pagination} setPagination={setPagination} />
        </Box>
    )
}