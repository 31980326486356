import { authHeader, handleResponse } from '../../_helpers';
import { API_URLS } from '../../_config';

export const services = {
  fetchRecord,
  fetchRecordByUid,
  fetchRecordUpload,
  fetchRecordUploadDetections
};

function fetchRecordUpload(recordUploadId, clinic) {
  var url, requestUrl;
  requestUrl = API_URLS.getDentistRequestRecordUpload;
  url = requestUrl.url.replace('{recordUploadUid}', recordUploadId);
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  if (clinic){
    url = url + '?clinic=' + clinic;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchRecordByUid(recordUid, clinic) {
  var url, requestUrl;
  requestUrl = API_URLS.getDentistRequestRecordDetailUuid;
  url = requestUrl.url.replace('{recordUid}', recordUid);
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  if (clinic){
    url = url + '?clinic=' + clinic;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchRecordUploadDetections(recordUploadUid, clinic) {
  var url, requestUrl;
  requestUrl = API_URLS.getDentistRequestRecordUploadDetections;
  url = requestUrl.url.replace('{recordUploadUid}', recordUploadUid);
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  if (clinic){
    url = url + '?clinic=' + clinic;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchRecord(recordId, requestId, clinic) {
  var url, requestUrl;
  if (requestId) {
    requestUrl = API_URLS.getDentistRequestRecordDetailWithRequest;
    url = requestUrl.url.replace('{requestId}', requestId).replace('{recordId}', recordId);
  } else {
    requestUrl = API_URLS.getDentistRequestRecordDetail;
    url = requestUrl.url.replace('{recordId}', recordId);
  }
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  if (clinic){
    url = url + '?clinic=' + clinic;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
