import React from 'react';
import { 
    Box, Flex, Heading, Button, Center,
    // eslint-disable-next-line no-unused-vars
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure,
    Table, Thead, Tbody, Tr, Th, Td  } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { FormattedMessage } from 'react-intl';
import { DentistAccountRoleForm } from './AccountRoleForm';
import { useDentistDetailsRoles } from '../api/getDentistDetailsRoles';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';

const sortRoles = (roles) => {
    // Sort roles by whether the role is 'Owner' (case-insensitive), then by user.email (case-insensitive), then by role name (case-insensitive)
    const sortedRoles = roles.sort((a, b) => {
      const aRoleLower = a.role.toLowerCase();
      const bRoleLower = b.role.toLowerCase();
      
      const aIsOwner = aRoleLower === "owner";
      const bIsOwner = bRoleLower === "owner";
  
      if (aIsOwner && !bIsOwner) return -1; // Prioritize "Owner" roles
      if (!aIsOwner && bIsOwner) return 1;
  
      // Case insensitive comparison of clinics
      const clinicComparison = a.clinic.name.toLowerCase().localeCompare(b.clinic.name.toLowerCase());
      if (clinicComparison !== 0) return clinicComparison;

      // If emails are the same, sort roles alphabetically (case-insensitive)
      return aRoleLower.localeCompare(bRoleLower);
    });
  
    return sortedRoles;
  };

const DentistUserRolesFormModal = ({
    dentistId,
    isOpen,
    onClose,
    onSuccess
    }) => {
        return (
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered >
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent minW="500px" bg="#f7f9fa">
                <ModalHeader>
                <Center>
                        <FormattedMessage 
                            id={'adminPage.clinicRoleForm.title'}
                            defaultMessage={'Add Role'}
                        />
                </Center>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                <Box>
                    <DentistAccountRoleForm
                        dentistId={dentistId}
                        onClose={onClose}
                        onSuccess={onSuccess}
                    />
                </Box>
                </ModalBody>
            </ModalContent>
            </Modal>
        )
}

const PageHeader = ({dentistId, onAdded}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    return (
        <>
            <DentistUserRolesFormModal onSuccess={onAdded} dentistId={dentistId} isOpen={isOpen} onClose={onClose} />
            <Flex justify={'space-between'} align={'end'}>
                <Heading as={'h2'}>
                    <FormattedMessage
                        id="admin.dentistclinicspage.title"
                        defaultMessage="Dentist User Roles"
                    />    
                </Heading>
                <Flex >
                    <Button variant="outline" onClick={onOpen}>
                        <FormattedMessage
                            id="admin.dentistclinicspage.button.newRole"
                            defaultMessage="+ Add New Role"
                        />
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}

const clinicNameAccessor = (row) => {
    if (row.clinic.branch) {
        return `${row.clinic.name} - (${row.clinic.branch}) @ ${row.clinic.address}`;
    } else {
        return `${row.clinic.name} @ ${row.clinic.address}`;
    }

}

// eslint-disable-next-line no-unused-vars
const PageTable = ({data, isLoading, pagination, setPagination}) => {
    const columns = React.useMemo(
        () => [
            {
                header: 'Clinic',
                id: 'clinic',
                accessorFn: clinicNameAccessor,
            },
            {
                header: 'Role',
                id: 'role',
                accessorKey: 'role',
            },
            {
                header: 'Is Default',
                id: 'address',
                accessorFn: row => (row.is_default ? 'Y' : 'N'),
            }
        ],
        []
    )


    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })




    return (
        <>
            <Table mt={'20px'}>
                <Thead>
                    {(table.getHeaderGroups() || []).map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                        <Tr key={row.id} >
                            {row.getVisibleCells().map(cell => {
                            return (
                                <Td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Td>
                            )
                            })}
                        </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </>
    )
}

export const DentistUserAccountRolePage = ({dentistId}) => {
    const { data, isLoading, isFetching, isError, error, refetch } = useDentistDetailsRoles({dentistId});

    const sortedRoles = sortRoles(data?.roles || [])

    if (isError){
        return (
            <Box>
                <ErrorNotFound error={error} size={"sm"} buttonType={'refresh'} />
            </Box>
        )
    }

    return (
        <Box>
            <PageHeader dentistId={dentistId} onAdded={refetch}/>
            <PageTable data={sortedRoles} isLoading={isLoading || isFetching} />
        </Box>
    )
}