import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import {Box, SimpleGrid, Button} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { ClinicDentistSearch } from './DentistSearch';

import { createUserFavoriteDentist } from '../api/services';
import { convertToAPIValues } from 'src/_libs/forms';

import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';

export const FavoriteDentistForm = ({
    onSuccess,
    onClose,
    userId,
  }) => {
    const { formatMessage } = useIntl();
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        convertedValues.user = userId;
        try{
            const result = await createUserFavoriteDentist(userId, convertedValues);
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            render = {
                ({
                    handleSubmit,
                    submitting
                }) => {
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="dentist" validate={required}>
                                    {({input, meta}) => (
                                        <ClinicDentistSearch 
                                            input={input}
                                            meta={meta}
                                           isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            label={formatMessage({id: 'adminPage.form.field.favoritedentist.dentist.label', defaultMessage: 'Dentist'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.favoritedentist.dentist.placeholder', defaultMessage: 'Search for a Dentist'})}
                                            onSelect={(val) => {
                                                input.onChange(val);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.cancel.button.label"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.submit.button.label"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </SimpleGrid>
                        </Box>
                    )
                }
            }
        />
    )
}