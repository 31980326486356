import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const CaretDownIcon = ({width=11, height=7, color="#405159",  ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 11 7" fill="none" color={color} {...props}>
        <path d="M1.09698 1L5.57974 6L10.0625 1" 
            stroke="currentColor" 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </Icon>
  )
}