import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react';
import { NotFoundIcon } from 'src/_images/icons/NotFound';
import { useHistory } from 'react-router';
import ArrowForwardIcon from 'src/_images/icons/ArrowForwardIcon';
import { RedoIcon } from 'src/_images/icons/RedoIcon';

const ErrorButton = ({type="home", ...buttonSizeProps}) => {
    const history = useHistory();
    const formatMessage = useIntl().formatMessage;

    const buttonProps = {
        home: {
            label: formatMessage({
                'id': 'noMatch.error.backHome',
                'defaultMessage': 'Back to Home'
            }),
            props: {
                rightIcon: <ArrowForwardIcon />,
                onClick: () => {history.replace("/")}
            }
        }, 
        refresh: {
            label: formatMessage({
                'id': 'noMatch.error.refresh',
                'defaultMessage': 'Reload Data'
            }),
            props: {
                leftIcon: <RedoIcon />,
                onClick: () => {window.location.reload()}
            }
        }
    }

    return (
        <Button 
            {...(buttonProps[type].props)} 
            {...buttonSizeProps}
        >
            {buttonProps[type].label}
        </Button>
    )
}

export const ErrorNotFound = ({error, message=null, size="xl", buttonType="home"}) => {
    const { formatMessage } = useIntl();

    const sizeProps = {
        xl: {
            container: {
                px: '50px',
                py: '40px'
            },
            icon: {
                height: 180,
                width: 300
            },
            description: {
                mt: '10px'
            },
            buttonContainer: {
                mt: '20px'
            },
            button: {
                size: 'md'
            }
        } ,
        lg: {
            container: {
                px: '40px',
                py: '30px'
            },
            icon: {
                height: 150,
                width: 250
            },
            description: {
                mt: '8px'
            },
            buttonContainer: {
                mt: '15px'
            },
            button: {
                size: 'md'
            }
        },
        md: {
            container: {
                px: '30px',
                py: '20px'
            },
            icon: {
                height: 120,
                width: 200
            },
            description: {
                mt: '5px'
            },
            buttonContainer: {
                mt: '10px'
            },
            button: {
                size: 'md'
            }
        },
        sm: {
            container: {
                px: '20px',
                py: '10px'
            },
            icon: {
                height: 108,
                width: 180
            },
            description: {
                mt: '5px'
            },
            buttonContainer: {
                mt: '8px'
            },
            button: {
                size: 'sm'
            }
        }
    }

    if (sizeProps[size] == undefined) {
        size = "xl";
    }


    // TODO: add other error status codes
    const errorMessage = {
        404: {
            title: formatMessage({
                'id': 'noMatch.error.404.title',
                'defaultMessage': 'Data not found.'
            }),
            description: formatMessage({
                'id': 'noMatch.error.404.description',
                'defaultMessage': 'Please check that your permissions are correct or try again later.'
            })
        },
        500: {
            title: formatMessage({
                'id': 'noMatch.error.500.title',
                'defaultMessage': 'Data not found.'
            }),
            description: formatMessage({
                'id': 'noMatch.error.500.description',
                'defaultMessage': 'There seems to be connection issues. Please check the connection.'
            })
        }
    }

    const title = message ? message : errorMessage[error.status] ? errorMessage[error.status].title : formatMessage({'id': 'noMatch.error.generalError.title', 'defaultMessage': 'Data not found'});
    const description = message ? message : errorMessage[error.status] ? errorMessage[error.status].description : formatMessage({'id': 'noMatch.error.generalError.description', 'defaultMessage': 'Please try again later.'});

    return (
        <Box height={'100%'}>
            <Flex direction={'column'} w={'full'} h={'full'} align={'center'} justify={'center'} 
                {...(sizeProps[size].container) }
            >
                <NotFoundIcon {...(sizeProps[size].icon) }/>
                <Heading size={size}>
                    {title}
                </Heading>
                <Text color={'#6B8A99'} {...(sizeProps[size].description) }>
                    {description}
                </Text>
                <Flex {...(sizeProps[size].buttonContainer) }>
                    <ErrorButton type={buttonType} {...(sizeProps[size].button)} />
                </Flex>
            </Flex>
        </Box>
    )

}