import { intersection, omitBy } from 'lodash';

export const IMAGE_TYPES = ['png', 'jpg', 'jpeg'];
export const VIDEO_TYPES = ['mp4', 'webm', 'm4v', 'mov'];

const uploadObject = {
  id: null,
  unique_id: null,
  file: '',
  file_head: '',
  file_name: '',
  filename: '',
  upload: '',
  upload_head: '',
  preview_url: '',
  converted_url: '',
  extra_data: {},
  getIntId: function () {
    return this.id;
  },
  getId: function () {
    return this.unique_id;
  },
  getHead: function () {
    return this.file_head || this.upload_head;
  },
  getFile: function () {
    if (this.converted_url){
      return this.converted_url;
    } else {
      return this.file || this.upload;
    }
  },
  getPreview: function () {
    return this.preview_url;
  },
  isVideo: function () {
    const tempStr = this.filename.split('.');
    if (tempStr.length > 0) {
      return VIDEO_TYPES.indexOf(tempStr[tempStr.length - 1]) != -1;
    } else {
      return false;
    }
  },
  isImage: function () {
    const tempStr = this.filename.split('.');
    if (tempStr.length > 0) {
      return IMAGE_TYPES.indexOf(tempStr[tempStr.length - 1]) != -1;
    } else {
      return false;
    }
  },
  getRegion: function () {
    if (this.extra_data && typeof this.extra_data.region == 'string') {
      return this.extra_data?.region && this.extra_data.region.replace('t', '');
    } else if (typeof this.extra_data?.region == 'number') {
      return this.extra_data?.region != null ? this.extra_data?.region : -1;
    }
  },
};

export class UserRecord {
  constructor(data) {
    const { id, unique_id, upload_count, type, created_at, file, file_head, file_name } = data;
    this.id = id;
    this.unique_id = unique_id;
    this.upload_count = upload_count;
    this.created_at = created_at;
    this.type = type;
    this.file = file;
    this.file_head = file_head;
    this.file_name = file_name;
  }
  getIntId() {
    return this.id;
  }
  getId() {
    return this.unique_id;
  }
  getUploadCount() {
    return this.upload_count;
  }
  getUpload() {
    return this.file;
  }
  getUploadHead() {
    return this.file_head;
  }
  getUploadFileName() {
    return this.file_name;
  }
}

export class RecordUpload {
  constructor(data) {
    const { id, unique_id, upload, extra_data } = data;
    this.id = id;
    this.unique_id = unique_id;
    this.upload = Object.assign({}, uploadObject, upload);
    this.extra_data = extra_data;
  }

  getIntId() {
    return this.id;
  }
  getId() {
    return this.unique_id;
  }
  getUploadIntId() {
    return this.upload.getIntId();
  }
  getUploadId() {
    return this.upload.getId();
  }
  getUpload() {
    return this.upload.getFile();
  }
  getUploadHead() {
    return this.upload.getHead();
  }
  getPreview() {
    if (this.upload.getPreview()){
      return this.upload.getPreview();
    }
    if (this.upload.isVideo()) {
      return null;
    }  else {
      return this.upload.getFile();
    }
  }
  isVideo() {
    return this.upload.isVideo();
  }
  isImage() {
    return this.upload.isImage();
  }
  getRegion() {
    return this.upload.getRegion();
  }
  getUploadFileName() {
    return this.upload.file_name || this.upload.filename;
  }
}

const chartExtraData = {
  type: '',
  regions: [],
  upload_id: null,
  all_regions: [],
  getRegions: function () {
    return this.regions;
  },
  getType: function () {
    return this.type;
  },
  getAllRegions: function () {
    return this.all_regions;
  },
  getUploadId: function () {
    return this.upload_id;
  },
  isUploadId: function (uploadId) {
    return this.type == 'UPLOAD' && this.upload_id == uploadId;
  },
  setRegions: function (regions, all_regions) {
    this.regions = regions;
    this.all_regions = all_regions;
  },
  getCharting: function () {
    return this.all_regions.reduce((acc, curr) => ((acc[curr] = 'move'), acc), {});
  },
};

export class ReportChart {
  constructor(data) {
    const { id, unique_id, comment, grade, extra_data, created_at } = data;
    this.id = id;
    this.unique_id = unique_id;
    this.comment = comment;
    this.grade = grade;
    this.created_at = created_at;
    this.extraData = Object.assign({}, chartExtraData, extra_data);
  }
  getRegions() {
    return this.extraData.getRegions();
  }
  getId() {
    return this.unique_id;
  }
  getGrade() {
    return this.grade;
  }
  getReportType() {
    return this.extraData.getType();
  }
  getCreatedAt() {
    return this.created_at;
  }
  getComment() {
    return this.comment;
  }
  getExtraData() {
    return this.extra_data;
  }
  getAllRegions() {
    return this.extraData.getAllRegions();
  }
  getUploadId() {
    return this.extraData.getUploadId();
  }
  getUploadType() {
    return this.extraData.getType();
  }
  getCharting() {
    return this.extraData.getCharting() || {};
  }
  isUploadId(uploadId) {
    return this.extraData.isUploadId(uploadId);
  }
  setCharting(charting) {
    this.extraData.setCharting(charting);
  }
  getRawChart() {
    this.extraData.getRawChart();
  }
  convertChartingToExtraData(updatedRegions) {
    let region1Values = ['t18', 't17', 't16', 't15', 't14'];
    let region2Values = ['t13', 't12', 't11', 't21', 't22', 't23'];
    let region3Values = ['t24', 't25', 't26', 't27', 't28'];
    let region4Values = ['t38', 't37', 't36', 't35', 't34'];
    let region5Values = ['t33', 't32', 't31', 't41', 't42', 't43'];
    let region6Values = ['t44', 't45', 't46', 't47', 't48'];
    const regionChart = {};
    const toothRegionKeys = Object.keys(updatedRegions);
    regionChart['region1'] = intersection(region1Values, toothRegionKeys).length > 0;
    regionChart['region2'] = intersection(region2Values, toothRegionKeys).length > 0;
    regionChart['region3'] = intersection(region3Values, toothRegionKeys).length > 0;
    regionChart['region4'] = intersection(region4Values, toothRegionKeys).length > 0;
    regionChart['region5'] = intersection(region5Values, toothRegionKeys).length > 0;
    regionChart['region6'] = intersection(region6Values, toothRegionKeys).length > 0;
    const generalRegions = omitBy(regionChart, (val) => val == false);
    //this.extraData.setRegions(Object.keys(generalRegions), toothRegionKeys);
    this.extraData = Object.assign({}, this.extraData, {
      regions: Object.keys(generalRegions),
      all_regions: toothRegionKeys,
    });
  }
}
