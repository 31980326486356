import React from 'react';
import { FormattedMessage } from 'react-intl';

export const CategoryTitle = ({category=""}) => {
    let categoryTitle = category.toUpperCase();
    if (categoryTitle === 'CARIES') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.TOOTH.label`}
                defaultMessage='Tooth'
            />
        )
    } else if (categoryTitle === 'TOOTH') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.TOOTH.label`}
                defaultMessage='Tooth'
            />
        )
    } else if (categoryTitle === 'GUMS') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.GUMS.label`}
                defaultMessage='Gums'
            />
        )
    } else if (categoryTitle === 'HABITS') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.HABITS.label`}
                defaultMessage='Habits'
            />
        )
    } else {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.OTHERS.label`}
                defaultMessage='Other'
            />
        )
    } /*else if (categoryTitle === 'COLOR') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.COLOR.label`}
                defaultMessage='Color'
            />
        )
    } else if (categoryTitle === 'ORTHO') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.ORTHO.label`}
                defaultMessage='Ortho'
            />
        )
    } else if (categoryTitle === 'MULTIPLE') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.MULTIPLE.label`}
                defaultMessage='Multiple'
            />
        )
    } else {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.OTHERS.label`}
                defaultMessage='General'
            />
        )
    }*/
}
