import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import {
    Box, Flex, SimpleGrid, Button, Spinner,
    FormControl, FormLabel, FormErrorMessage//, VStack, Input
} from '@chakra-ui/react';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { useSelectOptions } from '../api/getSelectOptions';
import { convertToAPIValues } from 'src/_libs/forms';
import { snakeCase, trim } from 'lodash';

import { createReportTemplate, editReportTemplate } from '../api/services';
import { useReportTemplateDetails, updateReportTemplateDetails } from '../api/getReportTemplateDetails';
import { EditReportTemplateLocaleForm } from './ReportTemplateLocaleForm';

import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';
import { CheckboxComponent } from 'src/_components/Forms/CheckBoxComponent';

const CategorySelectOption = ({
    input, meta, report_type
}) => {
    const formatMessage = useIntl().formatMessage;
    const defaultData = React.useMemo(() => [], []);
    const { data , isLoading , isFetching  } = useSelectOptions({option: 'report_categories', report_type});
    return (
        <SelectOptionField 
            isLoading={isFetching || isLoading}
            isMultiple={false}
            isDisabled={report_type ? false : true}
            label={formatMessage({id: 'adminPage.form.field.template.category.label', defaultMessage: 'Category'})}
            placeholder={formatMessage({id: 'adminPage.form.field.template.category.placeholder', defaultMessage: 'Category'})}
            input={input}
            selectOptions={data?.options || defaultData}
           isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
            allowUnfoundOption={true}
            error={getFinalFormMetaError(meta)}
        />
    )
}

const OtherLocales = ({
    input, meta
}) => {
    // other_locales: [{'locale': 'en', 'title': 'Title', 'message': 'Message'}]
    // locale, title, message
    return (
        <FormControl id={'other_locales'} >
            <FormLabel>
                <FormattedMessage
                    id="adminPage.form.field.template.other_locales.label"
                    defaultMessage="Other Locales"
                />
            </FormLabel>
        
            <Box columns={[1]} spacing={4}>
                <EditReportTemplateLocaleForm 
                    inputValue={input?.value}
                    onChange={input?.onChange}
                    meta={meta}
                />
            </Box>
            <FormErrorMessage>{meta?.error ? meta?.error  : ""}</FormErrorMessage>
        </FormControl>
    )
}

// Edit user is much different from create so new form is made
export const EditReportTemplateForm = ({
    onSuccess,
    onClose,
    id
}) => {
    const { data, isLoading, isFetching, isError, error } = useReportTemplateDetails({id})
    const { data: reportTypesTypes, isLoading: reportTypesIsLoading, isFetching: reportTypesIsFetching } = useSelectOptions({option: 'report_category_types'});
    const { formatMessage } = useIntl();
    const defaultData = React.useMemo(() => [], []);
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const { other_locales , ...rest } = valuesCopy;
        const convertedValues = convertToAPIValues(rest);
        convertedValues.subtype = snakeCase(trim(convertedValues.subtype));
        convertedValues.other_locales = other_locales;

        try{
            const result = await editReportTemplate(id, convertedValues);
            console.log("result ", result)
            updateReportTemplateDetails(result?.id, result)
            onSuccess && onSuccess(result);
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    if (isError){
        return (
            <Box>
                <ErrorNotFound error={error} size={"md"} buttonType={'refresh'} />
            </Box>
        )
    }

    if (isLoading || isFetching){
        return ( 
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        )
    } else {
        
        return (
            <>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={data}
                    mutators={{ 
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    render = {
                        ({
                            handleSubmit,
                            submitting,
                            values,
                            pristine
                        }) => {
                            return (
                                <Box as={'form'} onSubmit={handleSubmit}>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="is_active">
                                        {({input, meta}) => (
                                            <CheckboxComponent
                                                label={formatMessage({id: 'adminPage.form.field.patient.is_active.label', defaultMessage: 'Is Active?'})}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="report_type" validate={required}>
                                        {({input, meta}) => (
                                            <SelectOptionField 
                                                isLoading={reportTypesIsFetching || reportTypesIsLoading}
                                                label={formatMessage({id: 'adminPage.form.field.report_types.label', defaultMessage: 'Report Type'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.report_types.label', defaultMessage: 'Report Type'})}
                                                input={input}
                                                selectOptions={reportTypesTypes?.options || defaultData}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                    <Field name="locale" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent
                                                isDisabled={true}
                                                label={formatMessage({id: 'adminPage.form.field.template.locale.label', defaultMessage: 'Default Locale'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.template.locale.placeholder', defaultMessage: 'Locale'})}
                                                {...input}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="category">
                                        {({input, meta}) => (
                                            <CategorySelectOption 
                                                input={input}
                                                meta={meta}
                                                report_type={values?.report_type?.value || values?.report_type}
                                            />
                                        )}
                                    </Field>
                                    <Field name="subtype" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.template.subtype.label', defaultMessage: 'Subcategory'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.template.subtype.placeholder', defaultMessage: 'Subcategory - use _ instead of spaces'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="title" validate={required}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'adminPage.form.field.template.title.label', defaultMessage: 'Title'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.template.title.placeholder', defaultMessage: 'Title'})}
                                                    {...input}
                                                   isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="message" validate={required}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    type='textarea'
                                                    label={formatMessage({id: 'adminPage.form.field.template.message.label', defaultMessage: 'Message'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.template.message.placeholder', defaultMessage: "Message - '{{teeth}}' will get replaced with the selected teeth"})}
                                                    {...input}
                                                   isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="other_locales">
                                            {({input, meta}) => (
                                                <OtherLocales 
                                                    input={input}
                                                    meta={meta}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                        <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.cancel.button.label"
                                                defaultMessage="Cancel"
                                            />
                                        </Button>
                                        <Button type="submit" isLoading={submitting} isDisabled={submitting || pristine}>
                                            <FormattedMessage 
                                                id="adminPage.form.submit.button.label"
                                                defaultMessage="Submit"
                                            />
                                        </Button>
                                    </SimpleGrid>
                                </Box>
                            )
                        }
                    }
                />
            </>
        )
    }   
}

export const ReportTemplateForm = ({
    onSuccess,
    onClose,
    initValues={'locale': 'en', 'other_locales': []}
}) => {

    const { data: reportTypesTypes, isLoading: reportTypesIsLoading, isFetching: reportTypesIsFetching } = useSelectOptions({option: 'report_category_types'});
    const { formatMessage } = useIntl();
    const defaultData = React.useMemo(() => [], []);
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        convertedValues.subtype = snakeCase(trim(convertedValues.subtype));
        try{
            const result = await createReportTemplate(convertedValues);
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                initialValues={initValues}
                mutators={{ 
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                    }
                }}
                render = {
                    ({
                        handleSubmit,
                        submitting,
                        values,
                        pristine
                    }) => {
                        return (
                            <Box as={'form'} onSubmit={handleSubmit}>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="report_type" validate={required}>
                                        {({input, meta}) => (
                                            <SelectOptionField 
                                                isLoading={reportTypesIsFetching || reportTypesIsLoading}
                                                label={formatMessage({id: 'adminPage.form.field.report_types.label', defaultMessage: 'Report Type'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.report_types.label', defaultMessage: 'Report Type'})}
                                                input={input}
                                                selectOptions={reportTypesTypes?.options || defaultData}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                    <Field name="locale" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent
                                                isDisabled={true}
                                                label={formatMessage({id: 'adminPage.form.field.template.locale.label', defaultMessage: 'Default Locale'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.template.locale.placeholder', defaultMessage: 'Locale'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="category">
                                        {({input, meta}) => (
                                            <CategorySelectOption 
                                                input={input}
                                                meta={meta}
                                                report_type={values?.report_type?.value}
                                            />
                                        )}
                                    </Field>
                                    <Field name="subtype" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.template.subtype.label', defaultMessage: 'Subcategory'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.template.subtype.placeholder', defaultMessage: 'Subcategory'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="title" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.template.title.label', defaultMessage: 'Title'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.template.title.placeholder', defaultMessage: 'Title'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="message" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                type='textarea'
                                                label={formatMessage({id: 'adminPage.form.field.template.message.label', defaultMessage: 'Message'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.template.message.placeholder', defaultMessage: "Message - '{{teeth}}' will get replaced with the selected teeth"})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="other_locales">
                                        {({input, meta}) => (
                                            <OtherLocales 
                                                input={input}
                                                meta={meta}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                    <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                        <FormattedMessage 
                                            id="adminPage.form.cancel.button.label"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button type="submit" isLoading={submitting} isDisabled={submitting || pristine}>
                                        <FormattedMessage 
                                            id="adminPage.form.submit.button.label"
                                            defaultMessage="Submit"
                                        />
                                    </Button>
                                </SimpleGrid>
                            </Box>
                        )
                    }
                }
            />
        </>
    ) 
}