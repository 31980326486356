import React, { useEffect, useState } from 'react';
import { useSelector} from 'react-redux';
import { 
    StyledPage, 
    StyledPageTitle, 
} from './styles';
import { lowerCase, upperFirst } from 'lodash';
import styled from 'styled-components';
import { 
    Box, Heading, Center, Flex, HStack, VStack, Button, Spinner, IconButton, Select as ChakraSelect,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td, Text,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure   
} from '@chakra-ui/react'

import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { FormattedMessage, useIntl } from 'react-intl';
// redux state
import { StyledInput } from 'src/_css/styles';
import { localeFormatDate } from 'src/_helpers'; 
import { getIntlLocale } from 'src/App/_redux/selectors';
import { useAppConfig } from 'src/App/state/appConfig';
import { PlusIcon } from 'src/_images/icons/PlusIcon';
import SearchIcon from 'src/_images/icons/SearchIcon';
import { usePatientList } from './api/getPatientList';
import { useSearchParamsObject } from 'src/_hooks/useSearchParams';
import { useHistory } from 'react-router';
import { CaretDownIcon } from 'src/_images/icons/CaretDownIcon';
import { CaretUpIcon } from 'src/_images/icons/CaretUpIcon';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';

import PaginationNext from 'src/_images/icons/PaginationNext';
import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import { PatientForm } from './PatientForm';
import { NoPatientsFoundIcon } from 'src/_images/icons/NoPatientsFoundIcon';


const StyledListItemRelationship = styled.div`
    display: flex;

    a {
        margin-top: 0;
    }
`;

const ListItemRelationship = ({ relationship, parent }) => {
    // TODO format first and last name
    const { formatMessage } = useIntl();
    
    const related_to = parent 
        ? ' ' + formatMessage({
                id: 'format.fullName', 
                defaultMessage: '{givenName} {familyName}'
            },
            {
                givenName: parent.first_name, familyName: parent.last_name
            })
        : ''
        

    if (parent == null){
        return <div></div>
    }
    return (
        <StyledListItemRelationship>
            <div>{
                related_to 
                ? (<FormattedMessage
                    id='format.relationshipTo'
                    defaultMessage='{relationship} of {relatedTo}'
                    values={{
                        relationship: upperFirst(lowerCase(relationship)), 
                        relatedTo: related_to
                    }}
                /> )
                : '' }
            </div>
        </StyledListItemRelationship>
    )
}

const GenderText = ({gender=''}) => {
    const genderUpper = (gender || "").toUpperCase();
    if (genderUpper == 'MALE'){
        return <FormattedMessage id="settings.gender.MALE" defaultMessage='male' />
    } else if (genderUpper == 'FEMALE'){

        return <FormattedMessage id="settings.gender.FEMALE" defaultMessage='female' />
    } else {
        return <FormattedMessage id="settings.gender.OTHER" defaultMessage='other' />
    }
}


const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, onScrollToTop}) => {
    const handlePreviousPageClick = () => {
        previousPage();
        onScrollToTop();
    }
  
    const handleNextPageClick = () => {
        nextPage();
        onScrollToTop();
    }
  
    const handlePageChange = (e) => {
        const page = e.target.value ? Number(e.target.value) : 0
        setPageIndex(page)
        onScrollToTop();
    }

    if (total == 0){
        return (
          <></>
        )
    }

    return (
        <HStack spacing={3} >
            <IconButton 
                icon={<PaginationPrevious  />} 
                isDisabled={!canPreviousPage}
                onClick={handlePreviousPageClick}
                borderRadius={'5px'}
            />
            <ChakraSelect 
                onChange={handlePageChange}
                isDisabled={total < 2}
            >
                {Array.from({length: total}, (_, i) => (
                    <option key={i} value={i} selected={pageIndex == i}>
                        {i + 1}
                    </option>
                ))}
            </ChakraSelect>
            <IconButton 
                icon={<PaginationNext />} 
                isDisabled={!canNextPage}
                onClick={handleNextPageClick}
                borderRadius={'5px'}
            />
        </HStack>
    )
  }

const NewPatientFormModal = ({
    initValues,
    isOpen,
    onClose,
    onSuccess
  }) => {
  
    const handleOnSuccess = (data) => {
        onSuccess(data);
        onClose();
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
          <ModalOverlay bg="blackAlpha.300" />
          <ModalContent minW="700px" bg="#f7f9fa">
            <ModalHeader>
              <Center>
                    <FormattedMessage 
                        id={'adminPage.createNewPatientModal.title'}
                        defaultMessage={'Add New Patient'}
                    />
              </Center>
            </ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <Heading as={'h2'}>
                    <FormattedMessage 
                        id={'adminPage.createNewPatientForm.title'}
                        defaultMessage={'Patient'}
                    />
                </Heading>
              <Box>
                <PatientForm
                  initValues={initValues}
                  onSuccess={handleOnSuccess}
                  onClose={onClose}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
    )
  }

const PageTable = ({data, error, isError, searchParams, isLoading, pagination, setPagination, onScrollToTop}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const intlLocale = useSelector(getIntlLocale);
  
    const handleSort = (key) => {
      let params = searchParams;
      if (params.sort_by === key && params.sort_direction === 'desc') {
        // update API query parameters here for descending sort
        //setSortConfig({ key, direction: 'descending' });
        if (params.sort_direction === 'asc'){
          params.sort_direction = 'desc'; 
        } else {
          params.sort_direction = 'asc'; 
        }
        const newParams = new URLSearchParams(params)
        history.replace({search: newParams.toString()})
      } else {
        //setSortConfig({ key, direction: 'ascending' });
        params.sort_by = key;
        params.sort_direction = 'desc';
        const newParams = new URLSearchParams(params)
        history.replace({search: newParams.toString()})
      }
    };
  
    const getSortIcon = (key) => {
      let isAsc = false;
      let isDesc = false;
      if (searchParams.sort_by == key){
        isAsc = searchParams.sort_direction === 'asc';
        isDesc = searchParams.sort_direction === 'desc';
      };
      // #A0B1BA
      // #EFEFEF
      return (
        <VStack spacing={'3px'} ml={'8px'}>
          <CaretUpIcon color={isAsc ? '#405159' : '#C7D3D9' } width={14} />
          <CaretDownIcon color={isDesc ? '#405159' : '#C7D3D9' }  width={14}/>
        </VStack>
      )
    };
  
    const columns = React.useMemo(
        () => [
            {
                header: formatMessage({id: 'patientsListPage.patientlist.header.name', defaultMessage: 'Patient Name'}),
                id: 'name',
                accessorFn: row => formatMessage(
                    {id: "format.fullName", defaultMessage: "{givenName} {familyName}" },
                    {givenName: row?.first_name, familyName: row?.last_name}
                ),
                sortable: true,
            },
            {
                header: formatMessage({id: 'patientsListPage.patientlist.header.parent', defaultMessage: 'Related To'}),
                id: 'parent',
                cell: ({row}) => {
                    const patient = row.original;
                    return (<ListItemRelationship relationship={patient?.relationship} parent={patient?.parent} />)
                },
                sortable: false,
            },
            {
              header: formatMessage({id: 'requestListPage.requestList.header.birth_date', defaultMessage: 'Birthday'}),
                id: 'date_of_birth',
                accessorFn: row => localeFormatDate(row.date_of_birth, intlLocale),
                sortable: true,
            },
            {
              header: formatMessage({id: 'patientsListPage.patientlist.header.gender', defaultMessage: 'Gender'}),
                id: 'gender',
                accessorFn: row => <GenderText gender={row?.gender} /> ,
                cell: ({row}) => {
                    const patient = row.original;
                    return (<GenderText gender={patient?.gender} />)
                },
                sortable: false,
            },
            {
              header: formatMessage({id: 'patientsListPage.patientlist.header.external_id', defaultMessage: 'External ID'}),
                id: 'external_id',
                accessorFn: row => row?.external_id,
                sortable: true,
            }
        ],
        []
    )
  
    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data?.patient_list || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })
  
    const handleRowClick = (row) => {
        history.push(`/patients/${row?.unique_id}`)
    }
  
    if (isError){
      return (
        <Box ml={['90px']} pt={['54px']} height={'70%'}>
            <ErrorNotFound error={error} />
        </Box>
      )
    }
  
    return (
        <>
          <TableContainer>
            {isLoading ? (
              <Flex pos={'absolute'} w={'full'} h={'full'} py={'50px'} align={'flex-start'} justify={'center'} bg={'rgba(255, 255, 255, 0.6)'}>
                <Spinner 
                  size='xl'
                  thickness='4px'
                  speed='0.65s' 
                  color='#44C5A6' 
                />
              </Flex>
            ) : (
                <>
                    {
                        table.getRowModel().rows.length === 0 ? (
                            <Box ml={'5px'} pr={'5px'}>
                            <Flex pt={'150px'} flexGrow={1} direction={'column'} align={'center'} justify={'center'}>
                                <NoPatientsFoundIcon />
                                <Text mt={'10px'} fontsize={'18px'} color={'none.500'}>
                                <FormattedMessage 
                                    id={'patientsListPage.patientSearch.noPatientsFound'}
                                    defaultMessage={'No Patients Found'}
                                />
                                </Text>
                            </Flex>
                            </Box>
                        ) : (
                            <Table>
                                <Thead>
                                    {(table.getHeaderGroups() || []).map(headerGroup => (
                                        <Tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <Th 
                                                key={header.id} 
                                                colSpan={header.colSpan} 
                                                fontSize={'14px'}
                                                >
                                                    <Flex display={'flex'} align={'center'}>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    <Box
                                                        _hover={{cursor: header.column.columnDef.sortable ? 'pointer' : 'default'}}
                                                        onClick={() => header.column.columnDef.sortable && handleSort(header.id)}
                                                    >
                                                        {header.column.columnDef.sortable && getSortIcon(header.id)}
                                                    </Box>
                                                    </Flex>
                                                </Th>
                                            ))}
                                        </Tr>
                                    ))}
                                </Thead>
                                <Tbody>
                                    {table.getRowModel().rows.map(row => {
                                    return (
                                        <Tr 
                                        key={row.id} 
                                        onClick={() => handleRowClick(row.original)} 
                                        _hover={{cursor: 'pointer', bg: '#F7F7F7'}}
                                        >
                                            {row.getVisibleCells().map(cell => {
                                            return (
                                                <Td 
                                                key={cell.id}
                                                {...cell}
                                                py={'30px'}
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            )
                                            })}
                                        </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        )
                    }
                </>
            )}
          </TableContainer>
          <Flex w="full" mt={'20px'} justify={'flex-end'}>
              <Pagination 
                  onScrollToTop={onScrollToTop}
                  isLoading={isLoading}
                  canPreviousPage={table.getCanPreviousPage()}
                  canNextPage={table.getCanNextPage()}
                  pageIndex={table.getState().pagination.pageIndex}
                  total={table.getPageCount()}
                  nextPage={table.nextPage}
                  previousPage={table.previousPage}
                  setPageIndex={table.setPageIndex}
              />
          </Flex>
        </>
    )
  }

export const PatientSearchSection = ({searchInput, setSearchInput, handleSearch}) => {
    const { formatMessage } = useIntl();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    return (
        <Box>     
            <Flex mt={'30px'}>
                <StyledInput type="text" 
                    placeholder={formatMessage({id: "patientsListPage.patientSearch.button.search", defaultMessage: "Search"})}
                    value={searchInput} 
                    onKeyUp={handleKeyPress} 
                    onChange={(e) => setSearchInput(e.target.value)} 
                />
                <Button
                    ml={14}
                    onClick={handleSearch} 
                    leftIcon={<SearchIcon />}
                    px={'20px'}
                    flexGrow={1}
                >
                    <FormattedMessage 
                        id="patientsListPage.patientSearch.button.search"
                        defaultMessage="Search"
                    />
                </Button>
            </Flex>
        </Box>
    )
} 

const PageHeader = ({onCreated}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const openModal = () => {
        onOpen();
    }

    return (
        <>
            <NewPatientFormModal 
                isOpen={isOpen}
                onClose={onClose}
                onSuccess={onCreated} 
            />
            <Flex justify={'space-between'} align={'end'}>
                <StyledPageTitle>
                    <FormattedMessage
                        id="patientsListPage.title"
                        defaultMessage="Patients"
                    />    
                </StyledPageTitle>
                <Flex w={'full'} justify={'flex-end'}>

                    <Button
                        variant={'outline'} 
                        m={0}
                        ml={'14px'}
                        onClick={openModal} 
                        leftIcon={<PlusIcon />}
                    >
                        <FormattedMessage
                            id="patientsListPage.patientSearch.button.newPatient"
                            defaultMessage="Add New Patient"
                        />
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}

export const PatientListPage = () => {
    const searchParams = useSearchParamsObject();
    const history = useHistory();
    const clinic = useAppConfig(state => state.clinic?.id); // current selected clinic from app config
    const PAGE_SIZE = 20;
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    })
    const scrollRef = React.useRef(null);
    let [ searchInput, setSearchInput ] = useState('')
    const { data, isLoading, isFetching, isError, error, refetch } = usePatientList({
        ...searchParams, clinic, offset: pagination.pageIndex, pg_sz: pagination.pageSize 
    })

    useEffect(()=>{
        return () => {};
    }, [])

    const handleSearch = () => {
        let params = searchParams;
        params.q = searchInput;
        const newParams = new URLSearchParams(params)
        history.push({search: newParams.toString()})
    }

    const handleScrollToTop = () => {
        if (scrollRef.current){
        scrollRef.current.scrollTop = 0;
        }
    }

    const handlePageChange = (val) => {
        setPagination(val)
        handleScrollToTop();
    }

    const onCreated = () => {
        // set patient?
        refetch()
    }


    return (
        <StyledPage>
            <PageHeader onCreated={onCreated} />
            <PatientSearchSection 
                searchInput={searchInput} 
                setSearchInput={setSearchInput} 
                handleSearch={handleSearch} 
            />
            <PageTable data={data} error={error} isError={isError} searchParams={searchParams} isLoading={isLoading || isFetching} pagination={pagination} setPagination={handlePageChange} onScrollToTop={handleScrollToTop} />
            </StyledPage>
    )
}