import React from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'react-final-form';
import { motion } from 'framer-motion';
import { Flex, Tooltip, Text } from '@chakra-ui/react';

export const StyledBox = styled.div`
  ${(props) => props.addCss}
`;

export const generalModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    width: 'fit-content',
    height: 'fit-content',
    outline: 'none',
    padding: '0',
    position: 'initial',
    zIndex: 10,
  },
};
export const ModalCloseIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  padding-right: 15px;
  z-index: 3;

  svg {
    width: 12px;
    height: 12px;

    rect {
      fill: #405159;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

export const ModalStyles = styled.div`
  position: relative;
  height: 100%;
  padding: 0px;
  padding-bottom: 30px;
  border-radius: 10px;
  overflow:hidden;

  ${ModalCloseIcon} {
    margin-top: 28px;
    margin-right: 30px;
  }
`;

export const InitialsAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #405159;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScrollView = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-left: 50px;
  ${(props) => props.addCss}
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-bottom: 30px;
  ${(props) => props.addCss}
`;
export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
  ${(props) => props.addCss}
`;

export const StyledField = styled(Field)``;

export const StyledForm = styled.form`
  background: #f7f9fa;
  border: 1px solid #ededed;
  box-shadow: 1px 3px 20px rgba(239, 239, 239, 0.5);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px 50px 40px;
`;

export const StyledButton = styled(motion.button)`
  border-radius: 28px;
  font-size: ${({ size }) => (size == 'large' ? '16px' : size == 'small' ? '12px' : ' 16px')};
  padding: ${({ size }) => (size == 'large' ? '10px 15px' : size == 'small' ? '5px' : ' 8px 10px')};
  font-family: 'Proxima Nova Semibold';
  width: fit-content;
  min-width: ${({ size }) => (size == 'large' ? '200px' : size == 'small' ? '150px' : '180px')};
  margin: 0 auto;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '20px')};

  background: #44c5a6;
  border-color: #44c5a6;
  box-shadow: 0px 2px 8px rgba(39, 50, 56, 0.3);
  color: #405159;

  &.cancel {
    background: #7a8f99;
    border-color: #7a8f99;

    &.outlined {
      color: #7a8f99;
    }
  }

  &.delete {
    background: #dc3545;
    border-color: #dc3545;
    &.outlined {
      color: #dc3545;
    }
  }

  &.outlined {
    background: transparent;
    border-width: 2px;
    border: 1px solid #405159;
    box-shadow: 0px 2px 8px rgba(39, 50, 56, 0.2);
  }

  ${(props) =>
    props.variant == 'outlined'
      ? 'background:transparent;border-width:1px;box-shadow: 0px 2px 8px rgba(39, 50, 56, 0.2);'
      : 'box-shadow: 0px 2px 8px rgba(39, 50, 56, 0.3);color:#405159;'}

  &:active {
  }

  &:not(:disabled):hover {
    background-color: #1ea584;
    box-shadow: none;

    &.outlined {
      background-color: transparent;
    }
  }

  &:disabled {
    cursor: default;
    box-shadow: none;
    color: #a0b1ba;
    background: #c7d3d9;
  }
`;

export const ErrorLabel = styled.div`
  visibility: hidden;
  width: 100%;
  height: 15px;
  margin-top: 3px;
  font-size: 12px;
  text-align: left;
  ${(props) => props.addCSS}
  border:none;
`;

export const StyledSelectedFields = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  color: #566973;
  span:not(:first-child)::before {
    content: ', ';
  }
`;

export const StyledInputLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0;
`;

export const StyledFormHeader = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  margin: 20px 0 30px 0;
`;

export const StyledDescription = styled.div`
  width: 100%;
  height: 15px;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
`;

export const StyledLoadingDiv = styled.div`
  font-size: 48px;
  padding: 40px;
`;

export const StyledErrorDiv = styled.div`
  font-size: 48px;
  padding: 40px;
`;

export const StyledInput = styled.input`
  border-radius: 30px;
  border: 1px solid #c7d3d9;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 15px;
  width: 100%;

  &:disabled {
    color: lightgray;

    &::placeholder {
      color: #7a8f99;
    }
  }

  ${(props) => props.addCSS}
`;

export const StyledTextArea = styled.textarea`
  border-radius: 30px;
  border: 1px solid #c7d3d9;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;
  padding: 10px 15px;
  width: 100%;
  resize: ${({ resize }) => (resize ? '' : 'none')};
  ${(props) => props.addCSS}

  &:disabled {
    background: hsl(0, 0%, 95%);
    color: lightgray;
    border-color: hsl(0, 0%, 90%);
  }
`;

export const StyledLabel = styled.label`
  margin: 5px 0;
  font-size: 16px;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
`;

export const errorCss = css`
  border-color: red !important;
  border-width: 2px;
  visibility: visible;
`;

export const StyledOverflowTextContainer = styled.div`
  display: flex;
  overflow: hidden;
  margin-right: 5px;
  align-items: center;
  justify-content: flex-start;

  span {
    font-size: 14px;
    white-space: nowrap;
    align-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const OverflowTextContainer = ({ children, textProps }) => {
  return (
    <Flex overflow={'hidden'} mr={5} align="center" justify="start">
      <Tooltip label={children}>
        <Text fontSize={14} isTruncated whiteSpace={'nowrap'} alignSelf="center" {...textProps}>
          {children}
        </Text>
      </Tooltip>
    </Flex>
  );
};
