import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const RedoIcon = ({width=16, height=17, color="#273238",  ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 16 17" fill="none" color={color} {...props}>
        <path 
            d="M13.5689 4.70196L12.9503 4.08511C11.7786 2.91658 10.2292 2.20041 8.57777 2.06402C6.92629 1.92764 5.27985 2.37989 3.93138 3.3403C2.58291 4.30071 1.61987 5.707 1.21362 7.30895C0.807366 8.9109 0.984245 10.6046 1.71278 12.0887C2.44132 13.5729 3.67427 14.7511 5.19226 15.4139C6.71024 16.0767 8.41481 16.181 10.0027 15.7082C11.5905 15.2355 12.9587 14.2164 13.8638 12.8322C14.7688 11.4481 15.1521 9.78862 14.9453 8.14916M13.5689 4.70196L9.85629 4.70283M13.5689 4.70196V1" 
            stroke="currentColor" 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </Icon>
  )
}