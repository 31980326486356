/* eslint-disable no-unused-vars */
import React from 'react';
import {
    Canvg,
    presets
  } from 'canvg'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { 
    HStack, Box, Text, Button, Modal, ModalOverlay, ModalCloseButton, ModalContent, ModalHeader, ModalBody,
    Editable, EditableInput, EditablePreview, EditableTextarea
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { font as DMSans } from '../../_fonts/DMSans';
import { font as DMSansBold } from '../../_fonts/DMSans-Bold';
import { font as RobotoCondensed } from '../../_fonts/RobotoCondensed';
import { font as RobotoCondensedBold } from '../../_fonts/RobotoCondensed-Bold';
import { ToothSetBottom, ToothSetTop } from 'src/_components/ToothSet/ToothSetSplit';
import reportLogo from 'src/_images/reportLogo.png';
import { forEach, set } from 'lodash';
import { getCategoryColor } from './CategoryDot';
import { CategoryTitle } from './CategoryTitle';
import { useReportDetails } from '../api/getReport';
import { ExtractedImagesCategory, FrameExtractor } from './FrameExtractor';
import { useMediaStore } from '../state/media';
import { localeFormatDate } from 'src/_helpers';
import { useSelector } from 'react-redux';
import { getIntlLocale } from 'src/App/_redux/selectors';

import { useSendReport } from '../api/sendReport';
import { uploadReportPdf } from '../api/services';
import { QUERY_KEY_NAME as REQUEST_QUERY_KEY_NAME } from 'src/RequestListPage/api/getRequests';
import { queryClient } from 'src/_libs/react-query';
import { useAppConfig } from 'src/App/state/appConfig';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';
import { CenteredSpinner } from 'src/_components/Loaders/CenteredSpinner';


//
// A4: 210 x 297 mm
//
// jsPDF: in pt. 1 inch = 72 pts

// A4 size in points: 595.276 x 841.890
// 1 mm = 2.83465 pts
// width: 210mm = 595.276 pt
// height: 297mm = 841.890 pt
// default a4 print marings
// top: 2.5cm
// top 2.5 cm to pts
// 2.5cm = 70.8661 pt
// top: 14.1732
// bottom: 14.1732
// left: 14.1732
// right: 14.1732

// letter sizing
// 215.9 x 279.4
// width: 215.9mm = 612 pt
// height: 279.4mm = 792 pt
// default letter print margins
// top: 12.7
// bottom: 12.7
// left: 12.7
// right: 12.7

const preset = presets.offscreen()
const MM_TO_PT = 2.83465;

// eslint-disable-next-line no-unused-vars
const convertMMToPt = (mm) => mm * MM_TO_PT;
const convertCMToPt = (cm) => cm * 10 * MM_TO_PT;
// eslint-disable-next-line no-unused-vars
const PtToMM = (pt) => pt / MM_TO_PT;

const PAPER_SIZES_TO_POINTS = {
    'a4': {
        width: 595.276,
        height: 841.890,
        margin: {
            top: 27, //convertCMToPt(2),
            left: 40, //convertCMToPt(2),
            right: 40, //convertCMToPt(1.3),
            bottom: 30, //convertCMToPt(2)
        }
    },
    'letter': {
        width: 612,
        height: 792,
        margin: {
            top: convertCMToPt(2),
            left: convertCMToPt(2),
            right: convertCMToPt(2),
            bottom: convertCMToPt(2)
        }
    }
}

const reportStyles = {
    container: {
        position: 'relative'
    },
    reportLogoContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    reportLogoImg: {
        width: '44px',
        height: '42px',
    },
    reportLeadIn: {
        color: '#4EB1C4',
        textAlign: 'center',
        fontFamily: "Roboto Condensed",
        fontSize: '9.2px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
    },
    reportHeader: {
        color: '#0E7386',
        textAlign: "center",
        fontFamily: "DM Sans",
        fontSize: "17.5px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        height: '23pt',
        marginTop: '3pt'
    },
    nameLine: {
        display: 'grid',
        width: '100%',
        height: '13pt',
        gap: '5pt',
        gridTemplateColumns: '1fr',
        marginTop: '10pt',
    },
    nameLineContainer: {
        color: '#32818E',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        borderBottom: '1pt solid #E6E6E6',
        paddingBottom: '7pt'
    },
    nameLineLabel: {
        color: '#32818E',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    nameLineValue: {
        display: 'inline-block',
        color: '#32818E',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginLeft: '5pt',
        paddingBottom: '0',
        paddingTop: '0'
    },
    idLine:{
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1fr',
        height: '13pt',
        gap: '5pt',
        marginTop: '10pt',
    },
    idLineContainer: {
        color: '#32818E',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        borderBottom: '1pt solid #E6E6E6',
        paddingBottom: '7pt'
    },
    idLineLabel: {
        color: '#32818E',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    idLineValue: {
        color: '#32818E',
        display: 'inline-block',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginLeft: '5pt'
    },
    dateOfScanContainer: {
        color: '#32818E',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        borderBottom: '1pt solid #E6E6E6',
        paddingBottom: '7pt'
    },
    dateOfScanLabel: {
        color: '#32818E',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    dateOfScanValue: {
        color: '#32818E',
        display: 'inline-block',
        fontFamily: 'Roboto Condensed',
        fontSize: '7.3pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginLeft: '5pt'
    },
    toothChartContainer: {
        display: 'flex',
        width: '100%',
        padding: '15pt 0 15pt 0',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15pt',
        gap: '20pt',
        alignSelf: 'stretch',
        borderRadius: '20pt',
        border: '1pt solid #C5E7ED'
    },
    toothChartLine: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1pt 1fr',
    },
    toothChartLineUpper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#615B5B',
        textAlign: 'center',
        fontFamily: 'DM Sans',
        fontSize: '9pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    toothChartLineUpperTitle: {
        paddingBottom: '10pt',
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '9pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    toothChartLineChart: {
        display: 'flex'
    },
    toothChartLineUpperChart: {
        width: '125.001pt',
        height: '105.466pt'
    },
    toothChartUpperDirectionText: {
        display: 'flex',
        alignItems: 'flex-end',
        margin: '0 10pt',
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '8.5pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    toothChartLineBottom: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#615B5B',
        textAlign: 'center',
        fontFamily: 'DM Sans',
        fontSize: '9pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    toothChartLineBottomTitle: {
        paddingBottom: '10pt',
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '9pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    toothChartLineBottomChart: {
        width: '125.001pt',
        height: '105.466pt'
    },
    toothChartBottomDirectionText: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '0 10pt',
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '8.5pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    toothChartLineBorder: {
        padding: '11pt 0',
        backgroundColor: '#C5E7ED'
    },
    toothChartCategoryLine: {
        paddingTop: '5pt',
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    toothChartCategoryItem: {
        display: 'flex',
        position: 'relative',
        height: '13pt',
        alignItems: 'center',
        color: '#273238',
        fontFamily: 'DM Sans',
        fontSize: '6.9pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginRight: '5pt',
        borderRadius: '10pt',
        paddingTop: '1pt',
        paddingBottom: '1pt',
        paddingLeft: '5pt',
        paddingRight: '5pt',
        border: '1px solid #A9A7A7'
    },
    toothChartCategoryItemDot: {
        width: '6pt',
        height: '6pt',
        borderRadius: '50pt',
        //height: '12pt',
        //fontSize: '24pt',
        //lineHeight: '6.9pt',
        letterSpacing: '0.01px'
    },
    toothChartCategoryItemText: {
        marginLeft: '3pt',
        paddingLeft: '3pt',
        verticalAlign: 'top',
        fontSize: '6.9pt',
        //lineHeight: '8pt',
        letterSpacing: '0.01px'
    },
    reportImagesToothLabel: {
        color: '#FFF',
        fontFamily: 'DM Sans',
        fontSize: '7.9px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    reportNotesTitleLine: {
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '15pt',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        marginTop: '10pt'
    },
    reportNotesCategorySection: {
        marginTop: '10pt',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '10pt',
        paddingBottom: '15pt'
    },
    reportNotesCategorySectionCommentsTitle: {
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '12pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        paddingBottom: '5pt',
    },
    reportNotesCategorySectionCommentsTitleNumber: {
        color: '#C4C4C4',
    },
    reportNotesCategorySectionCommentsDescription: {
        display: 'flex',
        //gridTemplateColumns: '4pt 1fr',
        //gridGap: '8pt'
    },
    reportNotesCategorySectionCommentsDescriptionBorderContainer: {
        paddingTop: '5pt'
    },
    reportNotesCategorySectionCommentsDescriptionBorder: {
        //borderRadius: '3pt',
        //marginTop: '7pt',
        //height: '8pt',
        //borderLeft: '4px solid'
    },
    reportNotesCategorySectionCommentsDescriptionText: {
        display: 'flex',
        alignItems: 'center',
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '8.6pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '10pt'
    },
    reportNotesCategorySectionToothImage: {
       // display: 'flex',
        //flexWrap: 'wrap'
    },
    reportEvaluationSectionTitle: {
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '15pt',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        paddingTop: '10pt',
        paddingBottom: '5pt',
        borderTop: '1pt solid #C5E7ED'
    },
    reportEvaluationSectionDescription: {
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '8.6pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '12pt',
        marginTop: '5pt'
    },
    signatureBlocksignatureName: {
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '8.6pt',
        fontStyle: 'normal',
        fontWeight: 400,
        paddingTop: '5pt',
        paddingBottom: '5pt',
    },
    pdfCommentCommentDescriptionText: {
        paddingTop: '1pt',
        color: '#615B5B',
        fontFamily: 'DM Sans',
        fontSize: '8.6pt',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '10.5pt'
    }
}

const StyledToothChart = styled.div`
    width: 125pt;
    height: 105pt;
    background: transparent;
    font-family: 'DM Sans';
`;

const ToothChartMaxilla = ({reportData}) => {    
    const toothClasses = {};
  
    // go through the block data and convert it to tooth charting object
    // object with key of tooth number "txx" and value of category in all caps for class
    (reportData?.blocks || []).map((block) => {
        const data = block?.data;
        data.regions.map((region) => {
            if (region.length > 0){
                // will overwrite any previous block styles
                if (toothClasses[`t${region}`]){
                    toothClasses[`t${region}`].push(block.data.category.toUpperCase())
                } else {
                    toothClasses[`t${region}`] = [block.data.category.toUpperCase()]
                }
            }
        })
    })

    return (
        <StyledToothChart>
            <ToothSetTop 
                cssId={'toothChartMaxilla'}
                toothClasses={toothClasses}
            />
        </StyledToothChart>
    )
}

const ToothChartMandible = ({reportData}) => {
    const toothClasses = {};
  
    // go through the block data and convert it to tooth charting object
    // object with key of tooth number "txx" and value of category in all caps for class
    (reportData?.blocks || []).map((block) => {
        const data = block?.data;
        data.regions.map((region) => {
            if (region.length > 0){
                // will overwrite any previous block styles
                if (toothClasses[`t${region}`]){
                    toothClasses[`t${region}`].push(block.data.category.toUpperCase())
                } else {
                    toothClasses[`t${region}`] = [block.data.category.toUpperCase()]
                }
            }
        })
    })

    return (
        <StyledToothChart>
            <ToothSetBottom
                cssId={'toothChartMandible'}
                toothClasses={toothClasses}
            />
        </StyledToothChart>
    )
}

const CategoryItems = ({reportData}) => {
    // blocks[1].data?.category

    const groupedCategories = [];

    forEach((reportData?.blocks || []), (block) => {
        const category = block.data?.category;
        if (!groupedCategories.includes(category)) {
            groupedCategories.push(category);
        }
    })

    return (
        <div style={reportStyles.toothChartCategoryLine}>
            {(groupedCategories || []).map((category, index) => {
                return (
                    <CategoryItem key={index} category={category} />
                )
            })}
        </div>
    )
}

const CategoryItem = ({category}) => {
    const backgroundColor = getCategoryColor(category)
    return (
      <div style={reportStyles.toothChartCategoryItem} className={'categoryItem'}>
        <div style={{...reportStyles.toothChartCategoryItemDot, backgroundColor}}>
            
        </div>
        <div style={reportStyles.toothChartCategoryItemText} className={'categoryItemText'}>
            <CategoryTitle  category={category} />
        </div>
      </div>
    )
  }

const CategoryCommentDiv = ({color, comment, onPdfChange}) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '5pt',
        }}>
            <div style={{
                padding: 0,
                paddingTop: '5pt',
                paddingRight: '6pt',
            }}>
                <div style={{
                    borderRadius: '3pt',
                    height: '10pt',
                    width: '3pt',
                    backgroundColor: color
                }}>

                </div>
            </div>
            <div style={reportStyles.reportNotesCategorySectionCommentsDescriptionText}>
                <Editable 
                    defaultValue={comment}
                    onSubmit={onPdfChange}
                >
                    <EditableTextarea 
                        variant={'unstyled'}
                        {...reportStyles.reportNotesCategorySectionCommentsDescriptionText}
                        marginTop={'0'}
                        rows={2}
                    />
                    <EditablePreview 
                        {...reportStyles.reportNotesCategorySectionCommentsDescriptionText}
                        marginTop={'0'}
                    />
                </Editable>
            </div>
        </div>
    )
}

const StyledCommentList = styled.div`
    .pdfCategoryCommentLine{
        display:flex;
        padding-top: 2pt;
        padding-bottom: 1pt;
        margin-top: 1.4pt;
    }
    .pdfCommentColorBarContainer{
        margin-top: 0pt;
    }
    .pdfCommentColorBar:before {
        background-color:#367FFF;
        content: '';
        display:inline-block;
        width:3pt;
        height:10pt;
        border-radius:4pt;
        /*vertical-align:middle;*/
        margin-right:5pt;
    }
    .pdfCommentColorBar.CARIES:before{
        background-color: #DC4E41;
    }
    .pdfCommentColorBar.GUMS:before{
        background-color: #FFA500;
    }
    .pdfCommentColorBar.OTHERS:before{
        background-color: #367FFF;
    }
    .pdfCommentCommentDescriptionText {
        padding-top: 1pt;
        color: #615B5B;
        font-family: 'DM Sans';
        font-size: 8.6pt;
        font-style: 'normal';
        font-weight: 400;
        line-height: 10.5pt;
        width: 100%;
    }
`;

const pdfMarginAdjustmentsOriginal = {
    'categoryItemText': 0,
    'pdfCommentColorBarContainer': 0,
    'toothImageLabel': 0,
}
const pdfMarginAdjustments = {
    'categoryItemText': -11,
    'pdfCommentColorBarContainer': 5,
    'toothImageLabel': -8
}

function removeXmlAndEntities(str) {
    // Remove XML tags
    return str.replace(/<toothnumbers[^>]*>(.*?)<\/toothnumbers>/gis, function(match, p1) {
        return p1.replace(/<tooth[^>]*>[\s\S]*?<\/tooth>/gi, '') // Remove all <tooth> tags and their contents
                .replace(/&nbsp;|,/g, ''); // Remove all &nbsp; entities and commas
    }).replace(/^[\s.]+/, '');
}

function removeXmlAndKeepTeeth(str) {
    // Removing HTML tags
    let output = str.replace(/<\/?[^>]+(>|$)/g, "");
    // Replacing HTML entities
    output = output.replace(/&nbsp;/gi, " "); // Replace all occurrences of &nbsp; with a space
    
    return output;
}

const CategoryCommentGroup = ({category, onPdfChange, data=[]}) => {
    return (
        <StyledCommentList>
            {data.map((comment) => {
                return (
                    <CategoryComment 
                        key={`${category}_${comment?.id}`} 
                        category={category} 
                        onPdfChange={onPdfChange}
                        comment={removeXmlAndKeepTeeth(comment?.data?.text)} 
                    />
                )
            })}
        </StyledCommentList>
    )
}

const CategoryComment = ({category, comment, onPdfChange}) => {
    return (
        <div className={'pdfCategoryCommentLine'}>
            <div className={"pdfCommentColorBarContainer"}>
                <div className={`pdfCommentColorBar ${(category || "").toUpperCase()}`}></div>
            </div>
            <div className={"pdfCommentCommentDescriptionText"}>
                    <Editable 
                        onSubmit={onPdfChange} 
                        defaultValue={comment}
                        borderColor={"red"}
                        pos={'relative'}
                    >
                        {/*<Box 
                            pos={'absolute'} 
                            width={'full'} 
                            height={'full'} 
                            outline={'1px dashed #A0B1BA'}
                            borderRadius={'10px'}
                            pointerEvents={'none'}
                            className={'pdf-displayNone'} 
                        />*/}
                        <EditableTextarea 
                            variant={'unstyled'}
                            {...reportStyles.pdfCommentCommentDescriptionText}
                            marginTop={'0'}
                            width={'100%'}
                            rows={3}
                        />
                        <EditablePreview 
                            {...reportStyles.pdfCommentCommentDescriptionText}
                            marginTop={'0'}
                        />
                    </Editable>
            </div>
        </div>
    )
}

const StyledSignatureBlock = styled.div`
    display: block;
    width: 120pt;
    padding-top: 45pt;

    .signatureName {
        width: 100%;
        padding-top: 5pt;
        padding-bottom: 0;
        display:flex;
        justify-content: center;
        color: #615B5B;
        text-align: center;
        font-family: "DM Sans";
        font-size: 8.6pt;
        font-style: normal;
        font-weight: 400;
    }

    .signatureLine {
        width: 100%;
        margin-top: 3pt;
        height: 1pt;
        background-color: #E6E6E6;
    }

    .signatureLabel {
        width: 100%;
        margin-top: 1pt
        display:flex;
        justify-content: center;
        color: #32818E;
        text-align: center;
        font-family: "Roboto Condensed";
        font-size: 7.3pt;
        font-style: normal;
        font-weight: 400;
    }
`;

const SignatureBlock = ({name, onPdfChange}) => {
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'pdfreport.signatureBlock.nameplaceholder',
        defaultMessage: '[Dentist Name]'
    });
    const formattedName = name.trim() 
        ? name 
        : placeholder
    return (
        <StyledSignatureBlock>
            <div className={'signatureName'}>
                <Editable 
                    onSubmit={onPdfChange}
                    defaultValue={formattedName}
                    pb={'2pt'}
                >
                    <EditableInput 
                        variant='unstyled'
                        {...reportStyles.signatureBlocksignatureName}
                    />
                    <EditablePreview 
                        variant='unstyled'
                        {...reportStyles.signatureBlocksignatureName}
                    />
                </Editable>
            </div>
            <div className={'signatureLine'}>
            </div>
            <div className={'signatureLabel'}>
                <FormattedMessage 
                    id={'pdfreport.signatureBlock.label'}
                    defaultMessage='DENTIST'
                />
            </div>
        </StyledSignatureBlock>
)}

const convertSvgToPng = (svgElement, callback) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const serializer = new XMLSerializer();
    const svgStr = serializer.serializeToString(svgElement);

    canvas.width = svgElement.clientWidth;
    canvas.height = svgElement.clientHeight;

    const v = Canvg.fromString(ctx, svgStr, {
        ignoreDimensions: true,
        scaleWidth: canvas.width,
        scaleHeight: canvas.height
    });

    // Start rendering the SVG onto the canvas
    v.start();

    canvas.toBlob(blob => {
        const url = URL.createObjectURL(blob);
        callback(url, canvas);
    }, 'image/png');
};

async function toPngUrl(svgElement, height='105pt', width='125pt') {
    //console.log(svgElement.innerHTML)
    //const serializer = new XMLSerializer();
    //const svgStr = serializer.serializeToString(svgElement);
    const scale = 1.5

    const svgStr = svgElement.innerHTML

    const canvas = new OffscreenCanvas(svgElement.clientWidth, svgElement.clientHeight)
    const ctx = canvas.getContext('2d')
    const v = await Canvg.fromString(ctx, svgStr, preset)

    v.resize(svgElement.clientWidth * scale, svgElement.clientHeight * scale)
  
    // Render only first frame, ignoring animations and mouse.
    await v.render()
  
    const blob = await canvas.convertToBlob()
    const pngUrl = URL.createObjectURL(blob)
  
    return pngUrl
  }

async function tagToPngUrl(categoryItem) {
    const scale = 1.5
    const svgStr = categoryItem.innerHTML

    const canvas = new OffscreenCanvas(categoryItem.clientWidth, categoryItem.clientHeight)
    const ctx = canvas.getContext('2d')
    const v = await Canvg.fromString(ctx, svgStr, preset)

    v.resize(categoryItem.clientWidth * scale, categoryItem.clientHeight * scale)
  
    // Render only first frame, ignoring animations and mouse.
    await v.render()
  
    const blob = await canvas.convertToBlob()
    const categoryTagUrl = URL.createObjectURL(blob)
  
    return categoryTagUrl
}

const convertSvgToPngAndReplace = (svgElement) => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const serializer = new XMLSerializer();
        const svgStr = serializer.serializeToString(svgElement);

        canvas.width = svgElement.clientWidth;
        canvas.height = svgElement.clientHeight;

        const v = Canvg.fromString(ctx, svgStr, {
            ignoreDimensions: true,
            scaleWidth: canvas.width,
            scaleHeight: canvas.height
        });

        // Start rendering the SVG onto the canvas
        v.start();

        canvas.toBlob(blob => {
            const url = URL.createObjectURL(blob);
            const img = document.createElement('img');
            img.src = url;
            img.style.width = `${canvas.width}px`;
            img.style.height = `${canvas.height}px`;

            // Replace SVG with PNG image in the DOM
            svgElement.parentNode.replaceChild(img, svgElement);

            resolve({ img, svgElement });
        }, 'image/png');
    });
};

const createUrlFromSvg = (svgElement) => {
    const canvas = document.createElement("canvas");
    return new Promise((resolve, reject) => {
        html2canvas(svgElement, {canvas}).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            resolve(imgData);
        }).catch(error => {
            reject(error);
        });
    });
};

const PDFReportWrapper = ({report, requestData, onGenerate, onCancel}) => {
    const contentRef = React.useRef(null);
    const clinic = useAppConfig(state => state.clinic?.id);
    const [ pdfUrl, setPdfUrl ] = React.useState(null);
    const pdfDataRef = React.useRef(null);
    const { formatMessage } = useIntl();
    // eslint-disable-next-line no-unused-vars
    const [paperSize, setPaperSize] = React.useState('a4');
    // eslint-disable-next-line no-unused-vars
    const [docName, setDocName] = React.useState(requestData?.patient?.external_id);
    const locale = useSelector(getIntlLocale);

    const extractedImages = useMediaStore((state) => state.extractedImages);

    const [ isGenerating, setIsGenerating ] = React.useState(false);
    //const [ isReplacing, setIsReplacing ] = React.useState(false);

    const defaultEvaluation = formatMessage({
        id: 'pdfreport.defaultEvaluation',
        defaultMessage: 'We recommend scheduling appointments for tooth decay evaluation, professional cleaning, and gum recession prevention. we also recommend consulting an orthodontist for potential crowding treatments. In the meantime, please use anti-inflammatory mouthwash, brush and floss daily to improve your oral health.'
    });

    
    React.useEffect(() => {
        return () => {
            // cleanup
            for (const [_category, images] of Object.entries(extractedImages)){
                (images || []).forEach((image) => {
                    URL.revokeObjectURL(image?.url);
                })
            }
            pdfUrl && URL.revokeObjectURL(pdfUrl);
        }
    }, [])

    const replaceSvgsForPDF = async () => {        
        //setIsReplacing(true);
        
        // convert the svg charts to images
        const upperSvg = document.getElementById('toothChartMaxilla');
        const lowerSvg = document.getElementById('toothChartMandible');

        // Using CANVG
        // Convert SVGs to PNGs and replace them in the DOM
        //const imagePromises = [upperSvg, lowerSvg].map(svg => createUrlFromSvg(svg));

        if (upperSvg.getElementsByTagName('img').length == 0){
            const upperUrl = await toPngUrl(upperSvg);
            // Replace SVGs with IMG tags in the DOM
            const upperImg = document.createElement('img');
            upperImg.crossOrigin = 'anonymous';
            upperImg.src = upperUrl;
            forEach(upperSvg.childNodes, (node) => {
                node.style.display = 'none';
            })
            upperSvg.appendChild(upperImg)
        }

        if (lowerSvg.getElementsByTagName('img').length == 0){
            const lowerUrl = await toPngUrl(lowerSvg);
            const lowerImg = document.createElement('img');
            lowerImg.crossOrigin = 'anonymous';
            lowerImg.src = lowerUrl;
            forEach(lowerSvg.childNodes, (node) => {
                node.style.display = 'none';
            })
            lowerSvg.appendChild(lowerImg)
        }

        //setIsReplacing(false);
    }

    const repositionCategoryItemText = () => {
        for (const [className, margin] of Object.entries(pdfMarginAdjustments)){
            const elements = document.getElementsByClassName(className);
            forEach(elements, (element) => {
                element.style.marginTop = `${margin}pt`;
            })
        }
    }

    const removeDisplayNone = () => {
        const elements = document.getElementsByClassName('pdf-displayNone');
        for (const element of elements){
            element.style.display = 'none';
        }
    }

    const onPdfChange = (_event) => {
        pdfDataRef.current = null;
    }

    const onUploadProgress = (_progressEvent) => {
        // TODO: handle upload progress
    }

    const onDownload = (inputBlob=null) => {
        const inputUrl = URL.createObjectURL(inputBlob);
        const link = document.createElement("a");
        link.download = `${report?.created_at && report?.created_at.substring(0, 10)} ${report?.patient_name}.pdf`;
        link.href = inputUrl ? inputUrl : pdfUrl;
        document.body.appendChild(link);
        link.click();
        // cleanup the url
        setTimeout(() => {
            URL.revokeObjectURL(inputUrl);
            try {
                document.body.removeChild(link);
            } catch (err){
                console.error(err);
            }
          }, 100);
    }

    const resetPdfMargins = () => {
        for (const [className, margin] of Object.entries(pdfMarginAdjustmentsOriginal)){
            const elements = document.getElementsByClassName(className);
            forEach(elements, (element) => {
                element.style.marginTop = `${margin}pt`;
            })
        }
    }

    const uploadPdf = (blobPDF) => {
        uploadReportPdf(blobPDF, report.id, clinic, onUploadProgress).then(
            (response) => {
                setIsGenerating(false);
                onGenerate(URL.createObjectURL(blobPDF));
                resetPdfMargins();
            }
        ).catch(
            err => {
                console.error(err);
                setIsGenerating(false);
                resetPdfMargins();
                alert("error generating pdf")
        })
    }

    // isDownloadOnly is used to generate the pdf and return the url without uploading
    // if isDownloadOnly is false, then the pdf is uploaded to the server
    const generatePdf = async (isDownloadOnly=true) => {
        setIsGenerating(true);

        // if pdfUrl is already set, then just upload the pdf and send.
        if (pdfDataRef.current != null && !isDownloadOnly){
            uploadPdf(pdfDataRef.current)
            return
        }

        await replaceSvgsForPDF();
        repositionCategoryItemText();
        removeDisplayNone();

        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: paperSize
        });

        // set up paper dimensions
        const paperDimensions = PAPER_SIZES_TO_POINTS[paperSize];
        const marginLeft = paperDimensions.margin.left;
        const marginRight = paperDimensions.margin.right;
        const marginTop = paperDimensions.margin.top;
        const marginBottom = paperDimensions.margin.bottom;
        
        const contentWidth = paperDimensions.width - marginLeft - marginRight;  // Adjust content width for margins
        const contentHeight = paperDimensions.height - marginTop - marginBottom;  // Adjust content height for margins

        // add font
        doc.addFileToVFS('DMSans.ttf', DMSans);
        doc.addFont('DMSans.ttf', 'DM Sans', 'normal');
        doc.addFileToVFS('DMSans-Bold.ttf', DMSansBold);
        doc.addFont('DMSans-Bold.ttf', 'DM Sans', 'bold');

        doc.addFileToVFS('RobotoCondensed.ttf', RobotoCondensed);
        doc.addFont('RobotoCondensed.ttf', 'Roboto Condensed', 'normal');
        doc.addFileToVFS('RobotoCondensed-Bold.ttf', RobotoCondensedBold);
        doc.addFont('RobotoCondensed-Bold.ttf', 'Roboto Condensed', 'bold');

        doc.setFont('DM Sans');

        doc.html(contentRef.current, {
            callback: function (doc) {
                const pageCount = doc.internal.getNumberOfPages();
                const docId = requestData?.patient?.external_id ? requestData.patient.external_id : requestData?.patient?.first_name;
                const dateString = requestData?.created_at ? localeFormatDate(new Date(requestData.created_at), locale, 'll') : ''
                doc.setTextColor('#32818E')
                for (var i = 1; i <= pageCount; i++) {
                    doc.setPage(i)
                    doc.text(`p ${i} / ${pageCount}`, doc.internal.pageSize.width - (marginRight), doc.internal.pageSize.height - (marginBottom/2), {
                      align: 'right'
                    })
                    doc.text(`PDH Protocols - ${docId} ${dateString}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - (marginBottom/2), {
                      align: 'center'
                    })
                  }
                //doc.save(`${docId} ${dateString}.pdf`);
                pdfDataRef.current = new Blob([doc.output('blob')], {type: 'application/pdf'})
                // upload blob to server
                var pdfUrl = URL.createObjectURL(pdfDataRef.current);
                if (!isDownloadOnly){
                    uploadPdf(pdfDataRef.current);
                } else {
                    setIsGenerating(false);
                    onDownload(pdfDataRef.current);
                    resetPdfMargins();
                    setPdfUrl(pdfUrl);
                }
                
                // TODO: add back elements that have class pdf-displayNone

            },
            margin: [marginTop, marginRight, marginBottom, marginLeft],
            width: contentWidth,
            height: contentHeight,
            autoPaging: 'text'
         });
         //doc.save('report.pdf');
    }

    // report?.report_data?.blocks
    // group by category 

    let groupedBlocks = {};
    let groupedImages = {};
    let categoryCount = {};

    forEach((report?.report_data?.blocks || []), (block) => {
        const category = (block?.data?.category || "").toLowerCase();
        if (!groupedBlocks[category]) {
            groupedBlocks[category] = [];
            categoryCount[category] = 0;
        } 
        if (block?.data?.regions && block?.data?.regions.length > 0){
            categoryCount[category] = categoryCount[category] + block?.data?.regions.length
        }
        groupedBlocks[category].push(block);
    })

    for (const [category, images] of Object.entries(extractedImages || {})){
        const categoryLower = category.toLowerCase();
        if (!groupedImages[categoryLower]){
            groupedImages[categoryLower] = {};
        }
        forEach(images, (image) => {
            // image = {region: "tooth number(s) string", url: "local url"}
            if (!image?.region || !image?.url){
                return
            }
            // overwrite previous urls for now
            groupedImages[categoryLower][image?.region] = image?.url;
        })
    }


    const keyOrder = Object.keys(groupedBlocks).sort((a, b) => {
        return a.localeCompare(b);
    });

    return (
        <div>
            <div ref={contentRef} style={reportStyles.container}>
                <div style={reportStyles.reportLogoContainer} >
                    <img className="reportLogo" src={reportLogo} alt="logo" style={reportStyles.reportLogoImg} />
                </div>
                <div style={reportStyles.reportLeadIn}>
                    <FormattedMessage 
                        id={'pdfreport.reportLeadIn'}
                        defaultMessage={'PDH PROTOCOLS'}
                    />
                </div>
                <div style={reportStyles.reportHeader}>
                    <FormattedMessage 
                        id={'pdfreport.reportHeader'}
                        defaultMessage={'Dental Health Report'}
                    />
                </div>
                <div style={reportStyles.nameLine}>{/* name line */}
                    <div style={reportStyles.nameLineContainer}>
                        <span style={reportStyles.nameLineLabel}>
                            <FormattedMessage 
                                id={'pdfreport.nameLineLabel'}
                                defaultMessage={'NAME:'}
                            />
                        </span>
                        <span style={reportStyles.nameLineValue}>
                            <Editable 
                                onSubmit={onPdfChange} 
                                defaultValue={requestData?.patient?.first_name}
                            >
                                <EditableInput
                                    variant={'unstyled'}
                                    {...reportStyles.nameLineValue}
                                    marginLeft={'0'}
                                />
                                <EditablePreview 
                                    variant={'unstyled'}
                                    {...reportStyles.nameLineValue}
                                    marginLeft={'0'}
                                />
                            </Editable>
                        </span>
                    </div>
                </div>
                <div style={reportStyles.idLine}>{/* name line */}
                    <div style={reportStyles.idLineContainer}> 
                        <span style={reportStyles.idLineLabel}>
                            <FormattedMessage 
                                id={'pdfreport.idLineLabel'}
                                defaultMessage={'ID:'}
                            />
                        </span>
                        <span style={reportStyles.idLineValue}>
                            <Editable 
                                onSubmit={onPdfChange} 
                                defaultValue={requestData?.patient?.external_id ? requestData.patient.external_id : requestData?.patient?.first_name}
                            >
                                <EditableInput
                                    variant={'unstyled'}
                                    {...reportStyles.idLineValue}
                                    marginLeft={'0'}
                                />
                                <EditablePreview 
                                    variant={'unstyled'}
                                    {...reportStyles.idLineValue}
                                    marginLeft={'0'}
                                />
                            </Editable>
                            
                        </span>
                    </div>
                    <div style={reportStyles.dateOfScanContainer}>
                        <span style={reportStyles.dateOfScanLabel}>
                            <FormattedMessage 
                                id={'pdfreport.dateOfScanLabel'}
                                defaultMessage={'DATE OF SCAN:'}
                            />
                        </span>
                        <span style={reportStyles.dateOfScanValue}>
                            <Editable 
                                onSubmit={onPdfChange} 
                                defaultValue={requestData?.created_at ? localeFormatDate(new Date(requestData.created_at), locale, 'll') : ''}
                            >
                                <EditableInput
                                    variant={'unstyled'}
                                    {...reportStyles.dateOfScanValue}
                                    marginLeft={'0'}
                                />
                                <EditablePreview 
                                    variant={'unstyled'}
                                    {...reportStyles.dateOfScanValue}
                                    marginLeft={'0'}
                                />
                            </Editable>
                        </span>
                    </div>
                </div>
                <div style={reportStyles.toothChartContainer}>
                    <div style={reportStyles.toothChartLine}>
                        <div style={reportStyles.toothChartLineUpper}>
                            <div style={reportStyles.toothChartLineUpperTitle}>
                                <FormattedMessage 
                                    id={'pdfreport.toothChartLineUpper.title'}
                                    defaultMessage={'Upper Jaw'}
                                />
                            </div>
                            <div style={reportStyles.toothChartLineChart}>
                                <div style={reportStyles.toothChartUpperDirectionText}>
                                    {'L'}
                                </div>
                                <ToothChartMaxilla 
                                    reportData={report?.report_data}
                                />
                                <div style={reportStyles.toothChartUpperDirectionText}>
                                    {'R'}
                                </div>
                            </div>
                        </div>
                        <div style={reportStyles.toothChartLineBorder}>{/* border line */}</div>
                        <div style={reportStyles.toothChartLineBottom}>
                            <div style={reportStyles.toothChartLineBottomTitle}>
                                <FormattedMessage 
                                    id={'pdfreport.toothChartLineBottom.title'}
                                    defaultMessage={'Lower Jaw'}
                                />
                            </div>
                            <div style={reportStyles.toothChartLineChart}>
                                <div style={reportStyles.toothChartBottomDirectionText}>
                                    {'L'}
                                </div>
                                <ToothChartMandible 
                                    reportData={report?.report_data}
                                />
                                <div style={reportStyles.toothChartBottomDirectionText}>
                                    {'R'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <CategoryItems reportData={report?.report_data} />
                </div>
                <div style={reportStyles.reportNotesTitleLine}>
                    <FormattedMessage 
                        id={'pdfreport.reportNotesTitleLine'}
                        defaultMessage={'Report Notes'}
                    />
                </div>
                {
                    // for each category
                    (keyOrder || []).map((category, index) => {
                        return (
                            <div key={`${category}_${index}`}>
                                <div style={reportStyles.reportNotesCategorySection}>
                                    <div>
                                        <div style={reportStyles.reportNotesCategorySectionCommentsTitle}>
                                            <CategoryTitle category={category} /> 
                                            <span style={reportStyles.reportNotesCategorySectionCommentsTitleNumber}>
                                                {` ( ${categoryCount[category]} )`}
                                            </span>
                                        </div>
                                        <CategoryCommentGroup
                                            onPdfChange={onPdfChange}
                                            category={category}
                                            data={groupedBlocks[category]}
                                        />
                                    </div>
                                    <div style={reportStyles.reportNotesCategorySectionToothImage}>
                                        <ExtractedImagesCategory 
                                            category={category}
                                            extractedImages={groupedImages[category]}
                                            innerBoxSize='52px'
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div style={reportStyles.reportEvaluationSectionTitle}>
                    <FormattedMessage
                        id={'pdfreport.reportEvaluationSectionTitle'}
                        defaultMessage={'Evaluation'}
                    />
                </div>
                <div style={reportStyles.reportEvaluationSectionDescription}>
                    <Editable
                        onSubmit={onPdfChange} 
                        defaultValue={defaultEvaluation}
                        variant={'pdfReport'}
                    >
                        <EditableTextarea 
                            variant={'unstyled'}
                            {...reportStyles.reportEvaluationSectionDescription}
                            marginTop={'0'}
                            rows={5}
                        />
                        <EditablePreview
                            {...reportStyles.reportEvaluationSectionDescription}
                            marginTop={'0'}
                        />
                    </Editable>
                </div>
                <SignatureBlock 
                    onPdfChange={onPdfChange}
                    name={formatMessage({id: 'format.fullName', message: '{givenName} {familyName}'}, {givenName: report.dentist.first_name, familyName: report.dentist.last_name})} 
                />
            </div>
            <HStack pt={'20px'} w={'full'} justify={'space-between'}>
                <Button variant={'outline'} onClick={() => generatePdf(true)} isLoading={isGenerating}>
                    <FormattedMessage
                        id={'pdfreport.downloadOnlyButton'}
                        defaultMessage={'Download Only'}
                    />
                </Button>
                <HStack justify={'flex-end'}>
                    <Button variant={'outline'} onClick={onCancel} isDisabled={isGenerating}>
                        <FormattedMessage 
                            id={'pdfreport.cancelButton'}
                            defaultMessage={'Cancel'}
                        />  
                    </Button>
                    <Button onClick={() => generatePdf(false)} isLoading={isGenerating}>
                        <FormattedMessage 
                            id={'pdfreport.submitButton'}
                            defaultMessage={'Save and Complete'}
                        />
                    </Button>
                </HStack>
            </HStack>
        </div>
    )
}

export const PDFReportModal = ({onClose, reportUid, requestData, onReportGenerated}) => {
    const [ isExtractingImages, setIsExtractingImages ] = React.useState(true);
    const clinic = useAppConfig(state => state.clinic?.id);
    const { data, isLoading, isFetching, isError, error } = useReportDetails({ reportId: reportUid, clinic });
    const sendReportMutation = useSendReport({ reportUid: reportUid, clinic });

    const sendReport = async () => {
        try {
          await sendReportMutation.mutateAsync(data)
          queryClient.refetchQueries([REQUEST_QUERY_KEY_NAME])
          //onSuccess(report);
        // eslint-disable-next-line no-unreachable
        } catch (err) {
          console.error(err);
          return null;
        }
      }

    const onGenerate = async (pdfUrl) => {
        const report = await sendReport();
        onReportGenerated(pdfUrl)
    }

    const handleNextPage = () => {
        setIsExtractingImages(false)
    }

    if (isError){
        return (
            <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} >
                <ModalOverlay />
                <ModalContent justify={'center'} minW={'600px'}>
                    <ModalHeader textAlign={'center'}>
                        {
                            isExtractingImages ? (
                                <FormattedMessage 
                                    id={'pdfreport.extractFrames.title'}
                                    defaultMessage={'Extract Frames'}
                                />) :  (
                                <FormattedMessage
                                    id={'pdfreport.report.title'}
                                    defaultMessage={'Dental Health Report'}
                                />
                            )
                        }
                    </ModalHeader>
                    <ModalBody
                        paddingBottom={'30px'}
                    >
                        <ErrorNotFound 
                            error={error}
                            size={"sm"} buttonType={'refresh'} 
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }

    if (isLoading || isFetching){

        return (
            <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} >
                <ModalOverlay />
                <ModalContent justify={'center'} minW={'600px'}>
                    <ModalHeader textAlign={'center'}>
                        {
                            isExtractingImages ? (
                                <FormattedMessage 
                                    id={'pdfreport.extractFrames.title'}
                                    defaultMessage={'Extract Frames'}
                                />) :  (
                                <FormattedMessage
                                    id={'pdfreport.report.title'}
                                    defaultMessage={'Dental Health Report'}
                                />
                            )
                        }
                    </ModalHeader>
                    <ModalBody
                        paddingBottom={'30px'}
                    >
                        <CenteredSpinner />
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }


    const reportData = data?.report?.report_data?.blocks || []

    // blocks?.data?.regions
    // for each frame in blocks?.data?.videoFrames 
    //   region = frame[0] 
    //   frameData = frame[1]
    //   timestamp = frameData?.palyedSeconds
    //   videoId = id 
    const videoData = {}
    reportData.forEach((block) => {
        (block?.data?.videoFrames || []).forEach((frame) => {
            const region = frame[0]
            const frameData = frame[1]
            const timestamp = frameData?.playedSeconds
            const videoId = frameData?.id
            if (!videoData[videoId]){
                videoData[videoId] = [{
                    frameData: frameData,
                    timestamp: timestamp,
                    region: region,
                    category: (block?.data?.category || "").toUpperCase()
                }]
            } else {
                videoData[videoId].push({
                    frameData: frameData,
                    timestamp: timestamp,
                    region: region,
                    category: (block?.data?.category || "").toUpperCase()
                })
            }
        })
    })

    return (
        <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent justify={'center'} minW={'600px'}>
                <ModalHeader textAlign={'center'}>
                    {
                        isExtractingImages ? (
                            <FormattedMessage 
                                id={'pdfreport.extractFrames.title'}
                                defaultMessage={'Extract Frames'}
                            />) :  (
                            <FormattedMessage
                                id={'pdfreport.report.title'}
                                defaultMessage={'Dental Health Report'}
                            />
                        )
                    }
                </ModalHeader>
                <ModalBody
                    paddingBottom={'30px'}
                >
                    { isExtractingImages ? (   
                        <FrameExtractor 
                            videoData={videoData}
                            nextPage={handleNextPage}
                            onClose={onClose}
                        /> 
                    ) : (
                        <PDFReportWrapper 
                            report={data?.report}
                            requestData={requestData.request}
                            onGenerate={onGenerate}
                            onCancel={onClose}
                        />
                    )
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}