import { QueryClient } from 'react-query';

export const queryConfig = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
  }
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
