import { authHeader, handleResponse } from '../../_helpers';
import { API_URLS } from '../../_config';

export const services = {
  searchDentists,
  fetchDentistDetails,
};

function fetchDentistDetails(id, clinic) {
  const requestUrl = API_URLS.getDentistProfile;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
  };
  let url = requestUrl.url.replace('{dentist_uid}', id); //+'&status='+statusQuery;
  if (clinic){
    url = url + '?clinic=' + clinic;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function searchDentists(query, country) {
  const requestUrl = API_URLS.searchDentistUsers;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
  };
  let url = requestUrl.url + '?q=' + query + '&country=' + country; //+'&status='+statusQuery;
  return fetch(url, requestOptions).then(handleResponse);
}
