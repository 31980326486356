import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchSelectOptions } from './services';

export const QUERY_KEY_NAME = 'Admin_SelectOptions';

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async ({option, queryParams}) => {
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    const searchParams = new URLSearchParams(paramsObj)
    return fetchSelectOptions(option, searchParams)
}

export const useSelectOptions = ({ option="", isDisabled=false, ...queryParams }) => {
    let queryName = `${QUERY_KEY_NAME}_${option}`
    /*if( option == "account_roles"){
        queryName = `${QUERY_KEY_NAME}_clinicRoles`
    } else if( option == "user_roles"){
        queryName = `${QUERY_KEY_NAME}_userRoles`
    } else if (option == "countries"){
        queryName = `${QUERY_KEY_NAME}_countries`
    } else if(option == "genders"){
        queryName = `${QUERY_KEY_NAME}_genders`
    } else {
        queryName = `${QUERY_KEY_NAME}_${option}`
    }*/

    return useQuery({
        ...newQueryConfig,
        enabled: !isDisabled,
        queryKey: [queryName, queryParams],
        queryFn: () => queryRequest({option, queryParams}), // in brackets because im parsing the object in queryRequest
      });
}