

import { useQuery } from 'react-query';
import { queryConfig, queryClient } from 'src/_libs/react-query';
import { toString } from 'lodash';

import { fetchPatientUserDetails } from './services';

export const QUERY_KEY_NAME = 'Admin_PatientUserDetails';

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (userId) => {
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    //const searchParams = new URLSearchParams(paramsObj)
    return fetchPatientUserDetails(userId)
}

export const updatePatientUserDetails = (userId, newData) => {
    queryClient.setQueryData([QUERY_KEY_NAME, {id: toString(userId)}], (oldData) => {
        return {
            ...oldData,
            ...newData
        }
    })
}

export const usePatientUserDetails = ({ userId }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!userId,
        queryKey: [QUERY_KEY_NAME, {id: toString(userId)}],
        queryFn: () => queryRequest(userId), // in brackets because im parsing the object in queryRequest
      });
}