import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { startCase  } from 'lodash';
import cn from 'classnames';

import { REQUEST_TYPES } from '../_config';

import { createChannelId, localeFormatDate } from '../_helpers';

// component redux
import { actions } from './_redux/actions';
import {
  getCurrentRequestId,
  getDetailsPatientId,
  getRequestDetails,
  getLoadedRequestId,
  getDetailsReportId,
  getPatientDetails,
  getDetailsRecordId,
  getDetailsUid,
  getDetailsRequestType,
  getCurrentPatientNotes
} from './_redux/selectors';

// components
import { LoadingEllipsis } from '../_components/Loaders';
import { ReportChat } from './ReportChat';
import { ReportVideoChat } from './ReportVideoChat';
import { UpcomingSection } from './UpcomingSection';
import { PatientSection } from './PatientSection';
import { PatientRecords, PatientRecordDetailModal } from '../PatientRecords';
import { AppointmentCompleteModal } from './AppointmentComplete';

// actions
import { actions as chatActions } from '../ChatPage/_redux/actions';
import { actions as patientActions } from '../Patients/_redux/actions';
import { actions as reportActions } from '../PatientReports/_redux/actions';
import { actions as recordActions } from '../PatientRecords/_redux/actions';
import { actions as requestActions } from '../PatientRequests/_redux/actions';
import { actions as noteActions } from '../PatientNotes/_redux/actions';

// selectors
import { getIntlMessages, getIntlLocale, getUUID } from '../App/_redux/selectors';
import {
  getCurrentPatient,
  getPatientRequests,
  getPatientRequestsLoading,
  getPatientsById,
  getPatientsListLoading
} from '../Patients/_redux/selectors';
import { getPatientReportById } from '../PatientReports/_redux/selectors';
import { getPatientRecordsById } from '../PatientRecords/_redux/selectors';
import { getPatientRequestsById } from '../PatientRequests/_redux/selectors';

  // styles
import {
  StyledDetailsSection,
  StyledRequestDetailsContainer,
  StyledTodoList,
  StyledTasksSection,
  StyledTask,
  WrappedPage
} from './_common/styles';
import { consoleLog } from '../_helpers/consoleLog';

const RequestTodoSection = ({currentRequestType}) => {
  const intlLocale = useSelector(getIntlLocale);
  const requestDetails = useSelector(getRequestDetails);

  let videoChatDate = requestDetails && requestDetails.extra_data &&
     requestDetails.extra_data.video_chat  && requestDetails.extra_data.video_chat.chat_date

  if (currentRequestType == REQUEST_TYPES.REPORT){
    return (
      <StyledTodoList>
        <div>{'REPORT STATUS HERE'}</div>
      </StyledTodoList>
    )
  } else if (currentRequestType == REQUEST_TYPES.VIDEOCHAT){
    return (
      <StyledTodoList>
        <div>{'CONFIRM APPOINTMENT TIME: ' + localeFormatDate(videoChatDate, intlLocale, "MMM DD hh:mm A")}</div>
      </StyledTodoList>
    )
  } else {
    return (
      <StyledTodoList>
      </StyledTodoList>
    )
  }

}

const TaskContainer = ({task}) => {
  return(
    <StyledTask>
      {task.id}
    </StyledTask>
  )
}

const TasksSection = () => {
  const intlMessages = useSelector(getIntlMessages)
  const dispatch = useDispatch();
  const currentPatient = useSelector(getCurrentPatient);
  const patientRequests = useSelector(getPatientRequests);
  const patientRequestsLoading = useSelector(getPatientRequestsLoading);

  useEffect(() => {
    if (currentPatient){
      dispatch(patientActions.getCurrentPatientRequestsByUUid(currentPatient))
    }
  }, [currentPatient])

  return (
    <StyledTasksSection>

        <div className={cn('sectionTitle', 'tasksTitle')}>
          {startCase(intlMessages['requestDetailPage.details.tasks.title'])}
        </div>
        <div className={'sectionContent'}>
          {
            patientRequestsLoading
            ? <div className={'loadingSection'}><LoadingEllipsis /></div>
            : (patientRequests.length == 0)
                ? <div>{'Requests...'}</div>
                : patientRequests.map( (elem, ind) => (
                    <TaskContainer
                      key={ind}
                      task={elem}
                      />
                  ))
          }
        </div>
    </StyledTasksSection>
  )
}

 const DetailsSection = ({currentRequestUid}) => {
  return (
    <StyledDetailsSection>

      <div className={'sectionColumn'}>
        <TasksSection />
        {/*<PatientReportPreview currentRequestId={currentRequestId}  />*/}
        <PatientRecords />
      </div>
      <div className={'sectionColumn'}>
        <div className={'patientChat'}>
          <ReportVideoChat currentRequestUid={currentRequestUid}/>
          <ReportChat />
        </div>
      </div>
    </StyledDetailsSection>
  )
 }

/**
 * Detail Page
 */
const RequestDetailPage = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const urlRequestId = params.request_id;
  const currentRequestId = useSelector(getCurrentRequestId);
  const currentRequestUid = useSelector(getDetailsUid);
  const currentRequestType = useSelector(getDetailsRequestType);
  const currentReportId = useSelector(getDetailsReportId);
  const currentRecordId = useSelector(getDetailsRecordId);
  const loadedRequestId = useSelector(getLoadedRequestId);
  const currentPatientId = useSelector(getDetailsPatientId);
  const patientDetails = useSelector(getPatientDetails);
  const patientsList = useSelector(getPatientsById);
  const patientsListLoading = useSelector(getPatientsListLoading);
  const patientReports = useSelector(getPatientReportById);
  const patientRecords = useSelector(getPatientRecordsById);
  const patientRequests = useSelector(getPatientRequestsById);
  const currentPatientNotes = useSelector(getCurrentPatientNotes);
  const myUuid = useSelector(getUUID);

  useEffect(()=>{
    return () => {};
  })

  useEffect(()=>{
    // fetch request when id changes in url
    //
    if (urlRequestId != null){
      dispatch(actions.update({currentRequestId: urlRequestId }))
    }
  }, [urlRequestId])

  useEffect(()=>{
    if (currentRequestId && patientRequests[currentRequestId] == null){
      dispatch(requestActions.fetchRequest(currentRequestId, "id"))
    }
  }, [currentRequestId])

  useEffect(()=>{
    // if request is loaded and reportid is found, load report
    if (currentReportId && patientReports[currentReportId] == null){
      dispatch(reportActions.fetchReport(currentReportId, currentRequestId))
    }
  }, [currentReportId])

  useEffect(()=>{
    // if request is loaded and reportid is found, load report
    if (currentRecordId && patientRecords[currentRecordId] == null){
      dispatch(recordActions.fetchRecord(currentRecordId, currentRequestId))
    }
  }, [currentRecordId])

  useEffect(()=>{
    // when patient is loaded, update chat/user redux
    if (currentPatientId){
      if (!patientsListLoading && patientsList[patientDetails.unique_id] == null){
        dispatch(patientActions.loadPatientDetailsByUUid())
      }
      consoleLog(" uuid, unique_id " + myUuid +  "  " + patientDetails.unique_id)
      consoleLog(" createChannelId is " + createChannelId(myUuid, patientDetails.unique_id))
      dispatch(noteActions.fetchPatientNotes(patientDetails.unique_id))
      dispatch(actions.updateCurrentPatientUuid(patientDetails.unique_id))
      dispatch(chatActions.selectConversation(createChannelId(myUuid, patientDetails.unique_id)))
    }
  }, [currentPatientId])

  useEffect(()=>{
    // if request is loaded (id from request.id in reducer, not url) and no report id, create report
    if (loadedRequestId && !currentReportId && currentRequestType == REQUEST_TYPES.REPORT){
      dispatch(reportActions.createReport(currentRequestId))
    }
  }, [loadedRequestId])

  consoleLog("patient notes: ", currentPatientNotes)

  return (
    <WrappedPage>
      <StyledRequestDetailsContainer key={currentRequestUid}>
        <PatientRecordDetailModal />
        <AppointmentCompleteModal />
        <UpcomingSection />
        <PatientSection />
        <RequestTodoSection currentRequestType={currentRequestType}/>
        <DetailsSection
          currentRequestType={currentRequestType}
          currentRequestId={currentRequestId}
          currentRequestUid={currentRequestUid}
        />
      </StyledRequestDetailsContainer>
    </WrappedPage>
  )
}

export { RequestDetailPage };
